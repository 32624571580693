/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../google/protobuf/timestamp";
import { ApprovalType } from "../../../../micashared/common/enums/approvaltype/v1/approval_type";
import { Currency } from "../../../../micashared/common/enums/currency/v1/currency";
import { DiscountType } from "../../../../micashared/common/enums/discounttype/v1/discount_type";
import { Unit } from "../../../../micashared/common/enums/unit/v1/unit";
import { Address } from "../../../../micashared/common/v1/address";
import { Error } from "../../../../micashared/common/v1/error";
import { LineItem } from "../../../../micashared/common/v1/line_item";

export const protobufPackage = "mica.discount.discount.v1";

export enum DiscountStatus {
  DISCOUNT_STATUS_UNSPECIFIED = 0,
  DISCOUNT_STATUS_AVAILABLE = 1,
  /** DISCOUNT_STATUS_HOLD - transitory status set by Mica when a discount would apply during transaction processing. */
  DISCOUNT_STATUS_HOLD = 2,
  /** DISCOUNT_STATUS_SUSPENDED - set by the discount provider */
  DISCOUNT_STATUS_SUSPENDED = 3,
  DISCOUNT_STATUS_USED = 4,
  DISCOUNT_STATUS_CLOSED = 5,
}

export interface TransactionDetails {
  approvalType: ApprovalType;
  /** Mica's transaction record primary key. */
  transactionKey: string;
  partnerKey: string;
  partnerName: string;
  organizationKey: string;
  organizationName: string;
  organizationAddress: Address | undefined;
  storeKey: string;
  storeNumber: string;
  storeAddress: Address | undefined;
  lineItems: LineItem[];
}

export interface Discount {
  discountDefinitionKey: string;
  discountDefinitionRef: string;
  discountKey: string;
  version: number;
  created: Date | undefined;
  updated: Date | undefined;
  discountRef: string;
  /** human readable description for a given discount that can be shown to the end user */
  headline: string;
  /** human readable description for a given discount that can be shown to the end user */
  summary: string;
  /** address to the image for this discount definition (note that this image is expected to be square) */
  thumbnailUrl: string;
  receiptDescription: string;
  status: DiscountStatus;
  type: DiscountType;
  validFrom: Date | undefined;
  validTo:
    | Date
    | undefined;
  /**
   * the basket must include items that match each of the criteria (i.e. it's an and operation) in order for this
   * discount to apply
   */
  discountCriteria: DiscountCriteria[];
  currency: Currency;
  userKey: string;
  userRef: string;
  monetaryAmount?: MonetaryAmount | undefined;
  percentageAmount?: PercentageAmount | undefined;
  transactionDetails: TransactionDetails | undefined;
}

export interface Quantity {
  /** an integer or decimal number */
  quantity: string;
  unit: Unit;
}

export interface DiscountCriteria {
  quantity: Quantity | undefined;
  monetaryAmount:
    | MonetaryAmount
    | undefined;
  /** indicates that this criteria is used to determine how much value to apply to these items */
  discountAmountApplicable: boolean;
  productGroupRefs: string[];
  productMatchFilters: string[];
  /** list of product codes of which the basket must include at least one in this list (i.e. it's an or operation) */
  productCodes: string[];
}

export interface MonetaryAmount {
  /** must be a monetary amount (e.g. 0.34) */
  amount: string;
  taxAmount: string;
}

export interface PercentageAmount {
  /** must be a percentage (50.3%) */
  percentageAmount: string;
  percentageTaxAmount: string;
  /** must be a monetary amount (e.g. 0.34) */
  maxAmount: MonetaryAmount | undefined;
}

export interface CreateDiscountRequest {
  discountDefinitionRef: string;
  discountRef: string;
  receiptDescription: string;
  /** the intial status of the discount has to be set and it cannot be unspecified */
  status: DiscountStatus;
  validFrom: Date | undefined;
  validTo:
    | Date
    | undefined;
  /**
   * the basket must include items that match each of the criteria (i.e. it's an and operation) in order for this
   * discount to apply
   */
  discountCriteria: DiscountCriteria[];
  userKey: string;
  userRef: string;
  monetaryAmount?: MonetaryAmount | undefined;
  percentageAmount?: PercentageAmount | undefined;
}

export interface CreateDiscountResponse {
  status: CreateDiscountResponse_Status;
  error: Error | undefined;
  discountKey: string;
  version: number;
}

export enum CreateDiscountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface GetDiscountRequest {
  discountRef?: string | undefined;
  discountKey?: string | undefined;
}

export interface GetDiscountResponse {
  status: GetDiscountResponse_Status;
  error: Error | undefined;
  discount: Discount | undefined;
}

export enum GetDiscountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface UpdateDiscountRequest {
  discountRef?: string | undefined;
  discountKey?: string | undefined;
  version: number;
  receiptDescription: string;
  /** the update status of the discount has to be set and it cannot be unspecified */
  status: DiscountStatus;
  validFrom: Date | undefined;
  validTo:
    | Date
    | undefined;
  /**
   * the basket must include items that match each of the criteria (i.e. it's an and operation) in order for this
   * discount to apply
   */
  discountCriteria: DiscountCriteria[];
  userKey: string;
  userRef: string;
  monetaryAmount?: MonetaryAmount | undefined;
  percentageAmount?: PercentageAmount | undefined;
}

export interface UpdateDiscountResponse {
  status: UpdateDiscountResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum UpdateDiscountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface UpdateDiscountStatusRequest {
  discountRef?: string | undefined;
  discountKey?: string | undefined;
  discountStatus: DiscountStatus;
}

export interface UpdateDiscountStatusResponse {
  status: UpdateDiscountStatusResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum UpdateDiscountStatusResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
  STATUS_INVALID_STATE_TRANSITION = 4,
}

export interface RemoveDiscountRequest {
  discountRef?: string | undefined;
  discountKey?: string | undefined;
}

export interface RemoveDiscountResponse {
  status: RemoveDiscountResponse_Status;
  error: Error | undefined;
}

export enum RemoveDiscountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface ApplyDiscountNotificationRequest {
  /** discount details */
  discountKey: string;
  discountRef: string;
  /** only USED supported initially */
  status: DiscountStatus;
  currency: Currency;
  monetaryAmount:
    | MonetaryAmount
    | undefined;
  /** transaction details */
  transactionKey: string;
  /** Timestamp when discount was applied */
  transactionCreated: Date | undefined;
  partnerKey: string;
  partnerName: string;
  organizationKey: string;
  organizationName: string;
  organizationAddress: Address | undefined;
  storeKey: string;
  storeNumber: string;
  storeAddress: Address | undefined;
  lineItems: LineItem[];
}

export interface ApplyDiscountNotificationResponse {
  status: ApplyDiscountNotificationResponse_Status;
  error:
    | Error
    | undefined;
  /** TODO(kuchlein): determine if we want to have an identifier passed back. I'm leaning towards not */
  externalRef: string;
}

export enum ApplyDiscountNotificationResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
  /** STATUS_INVALID_STATUS - unexpected status */
  STATUS_INVALID_STATUS = 4,
}

export interface SearchDiscountRequest {
  discountDefinitionRef: string;
  receiptDescription: string;
  status: DiscountStatus;
  type: DiscountType;
  validFrom: Date | undefined;
  validTo: Date | undefined;
  currency: Currency;
  userRef: string;
}

export interface SearchDiscountResponse {
  status: SearchDiscountResponse_Status;
  error: Error | undefined;
  discounts: Discount[];
}

export enum SearchDiscountResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

function createBaseTransactionDetails(): TransactionDetails {
  return {
    approvalType: 0,
    transactionKey: "",
    partnerKey: "",
    partnerName: "",
    organizationKey: "",
    organizationName: "",
    organizationAddress: undefined,
    storeKey: "",
    storeNumber: "",
    storeAddress: undefined,
    lineItems: [],
  };
}

export const TransactionDetails = {
  encode(message: TransactionDetails, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.approvalType !== 0) {
      writer.uint32(8).int32(message.approvalType);
    }
    if (message.transactionKey !== "") {
      writer.uint32(18).string(message.transactionKey);
    }
    if (message.partnerKey !== "") {
      writer.uint32(26).string(message.partnerKey);
    }
    if (message.partnerName !== "") {
      writer.uint32(34).string(message.partnerName);
    }
    if (message.organizationKey !== "") {
      writer.uint32(42).string(message.organizationKey);
    }
    if (message.organizationName !== "") {
      writer.uint32(50).string(message.organizationName);
    }
    if (message.organizationAddress !== undefined) {
      Address.encode(message.organizationAddress, writer.uint32(58).fork()).ldelim();
    }
    if (message.storeKey !== "") {
      writer.uint32(74).string(message.storeKey);
    }
    if (message.storeNumber !== "") {
      writer.uint32(82).string(message.storeNumber);
    }
    if (message.storeAddress !== undefined) {
      Address.encode(message.storeAddress, writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.lineItems) {
      LineItem.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): TransactionDetails {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseTransactionDetails();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.approvalType = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.transactionKey = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.partnerKey = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.partnerName = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.organizationKey = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.organizationName = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.organizationAddress = Address.decode(reader, reader.uint32());
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.storeKey = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.storeNumber = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.storeAddress = Address.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.lineItems.push(LineItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<TransactionDetails>, I>>(base?: I): TransactionDetails {
    return TransactionDetails.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<TransactionDetails>, I>>(object: I): TransactionDetails {
    const message = createBaseTransactionDetails();
    message.approvalType = object.approvalType ?? 0;
    message.transactionKey = object.transactionKey ?? "";
    message.partnerKey = object.partnerKey ?? "";
    message.partnerName = object.partnerName ?? "";
    message.organizationKey = object.organizationKey ?? "";
    message.organizationName = object.organizationName ?? "";
    message.organizationAddress = (object.organizationAddress !== undefined && object.organizationAddress !== null)
      ? Address.fromPartial(object.organizationAddress)
      : undefined;
    message.storeKey = object.storeKey ?? "";
    message.storeNumber = object.storeNumber ?? "";
    message.storeAddress = (object.storeAddress !== undefined && object.storeAddress !== null)
      ? Address.fromPartial(object.storeAddress)
      : undefined;
    message.lineItems = object.lineItems?.map((e) => LineItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseDiscount(): Discount {
  return {
    discountDefinitionKey: "",
    discountDefinitionRef: "",
    discountKey: "",
    version: 0,
    created: undefined,
    updated: undefined,
    discountRef: "",
    headline: "",
    summary: "",
    thumbnailUrl: "",
    receiptDescription: "",
    status: 0,
    type: 0,
    validFrom: undefined,
    validTo: undefined,
    discountCriteria: [],
    currency: 0,
    userKey: "",
    userRef: "",
    monetaryAmount: undefined,
    percentageAmount: undefined,
    transactionDetails: undefined,
  };
}

export const Discount = {
  encode(message: Discount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountDefinitionKey !== "") {
      writer.uint32(10).string(message.discountDefinitionKey);
    }
    if (message.discountDefinitionRef !== "") {
      writer.uint32(18).string(message.discountDefinitionRef);
    }
    if (message.discountKey !== "") {
      writer.uint32(26).string(message.discountKey);
    }
    if (message.version !== 0) {
      writer.uint32(32).int64(message.version);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(42).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(50).fork()).ldelim();
    }
    if (message.discountRef !== "") {
      writer.uint32(58).string(message.discountRef);
    }
    if (message.headline !== "") {
      writer.uint32(162).string(message.headline);
    }
    if (message.summary !== "") {
      writer.uint32(170).string(message.summary);
    }
    if (message.thumbnailUrl !== "") {
      writer.uint32(178).string(message.thumbnailUrl);
    }
    if (message.receiptDescription !== "") {
      writer.uint32(66).string(message.receiptDescription);
    }
    if (message.status !== 0) {
      writer.uint32(72).int32(message.status);
    }
    if (message.type !== 0) {
      writer.uint32(80).int32(message.type);
    }
    if (message.validFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.validFrom), writer.uint32(90).fork()).ldelim();
    }
    if (message.validTo !== undefined) {
      Timestamp.encode(toTimestamp(message.validTo), writer.uint32(98).fork()).ldelim();
    }
    for (const v of message.discountCriteria) {
      DiscountCriteria.encode(v!, writer.uint32(106).fork()).ldelim();
    }
    if (message.currency !== 0) {
      writer.uint32(112).int32(message.currency);
    }
    if (message.userKey !== "") {
      writer.uint32(122).string(message.userKey);
    }
    if (message.userRef !== "") {
      writer.uint32(130).string(message.userRef);
    }
    if (message.monetaryAmount !== undefined) {
      MonetaryAmount.encode(message.monetaryAmount, writer.uint32(138).fork()).ldelim();
    }
    if (message.percentageAmount !== undefined) {
      PercentageAmount.encode(message.percentageAmount, writer.uint32(146).fork()).ldelim();
    }
    if (message.transactionDetails !== undefined) {
      TransactionDetails.encode(message.transactionDetails, writer.uint32(154).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Discount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiscount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountDefinitionKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.discountDefinitionRef = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountKey = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.discountRef = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.headline = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.summary = reader.string();
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.thumbnailUrl = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.receiptDescription = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.validFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.validTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.discountCriteria.push(DiscountCriteria.decode(reader, reader.uint32()));
          continue;
        case 14:
          if (tag !== 112) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.userKey = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.userRef = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.monetaryAmount = MonetaryAmount.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.percentageAmount = PercentageAmount.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.transactionDetails = TransactionDetails.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Discount>, I>>(base?: I): Discount {
    return Discount.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Discount>, I>>(object: I): Discount {
    const message = createBaseDiscount();
    message.discountDefinitionKey = object.discountDefinitionKey ?? "";
    message.discountDefinitionRef = object.discountDefinitionRef ?? "";
    message.discountKey = object.discountKey ?? "";
    message.version = object.version ?? 0;
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.discountRef = object.discountRef ?? "";
    message.headline = object.headline ?? "";
    message.summary = object.summary ?? "";
    message.thumbnailUrl = object.thumbnailUrl ?? "";
    message.receiptDescription = object.receiptDescription ?? "";
    message.status = object.status ?? 0;
    message.type = object.type ?? 0;
    message.validFrom = object.validFrom ?? undefined;
    message.validTo = object.validTo ?? undefined;
    message.discountCriteria = object.discountCriteria?.map((e) => DiscountCriteria.fromPartial(e)) || [];
    message.currency = object.currency ?? 0;
    message.userKey = object.userKey ?? "";
    message.userRef = object.userRef ?? "";
    message.monetaryAmount = (object.monetaryAmount !== undefined && object.monetaryAmount !== null)
      ? MonetaryAmount.fromPartial(object.monetaryAmount)
      : undefined;
    message.percentageAmount = (object.percentageAmount !== undefined && object.percentageAmount !== null)
      ? PercentageAmount.fromPartial(object.percentageAmount)
      : undefined;
    message.transactionDetails = (object.transactionDetails !== undefined && object.transactionDetails !== null)
      ? TransactionDetails.fromPartial(object.transactionDetails)
      : undefined;
    return message;
  },
};

function createBaseQuantity(): Quantity {
  return { quantity: "", unit: 0 };
}

export const Quantity = {
  encode(message: Quantity, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.quantity !== "") {
      writer.uint32(10).string(message.quantity);
    }
    if (message.unit !== 0) {
      writer.uint32(16).int32(message.unit);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Quantity {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseQuantity();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quantity = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.unit = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Quantity>, I>>(base?: I): Quantity {
    return Quantity.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Quantity>, I>>(object: I): Quantity {
    const message = createBaseQuantity();
    message.quantity = object.quantity ?? "";
    message.unit = object.unit ?? 0;
    return message;
  },
};

function createBaseDiscountCriteria(): DiscountCriteria {
  return {
    quantity: undefined,
    monetaryAmount: undefined,
    discountAmountApplicable: false,
    productGroupRefs: [],
    productMatchFilters: [],
    productCodes: [],
  };
}

export const DiscountCriteria = {
  encode(message: DiscountCriteria, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.quantity !== undefined) {
      Quantity.encode(message.quantity, writer.uint32(10).fork()).ldelim();
    }
    if (message.monetaryAmount !== undefined) {
      MonetaryAmount.encode(message.monetaryAmount, writer.uint32(18).fork()).ldelim();
    }
    if (message.discountAmountApplicable === true) {
      writer.uint32(24).bool(message.discountAmountApplicable);
    }
    for (const v of message.productGroupRefs) {
      writer.uint32(34).string(v!);
    }
    for (const v of message.productMatchFilters) {
      writer.uint32(42).string(v!);
    }
    for (const v of message.productCodes) {
      writer.uint32(50).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiscountCriteria {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiscountCriteria();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.quantity = Quantity.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.monetaryAmount = MonetaryAmount.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.discountAmountApplicable = reader.bool();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.productGroupRefs.push(reader.string());
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.productMatchFilters.push(reader.string());
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.productCodes.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DiscountCriteria>, I>>(base?: I): DiscountCriteria {
    return DiscountCriteria.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiscountCriteria>, I>>(object: I): DiscountCriteria {
    const message = createBaseDiscountCriteria();
    message.quantity = (object.quantity !== undefined && object.quantity !== null)
      ? Quantity.fromPartial(object.quantity)
      : undefined;
    message.monetaryAmount = (object.monetaryAmount !== undefined && object.monetaryAmount !== null)
      ? MonetaryAmount.fromPartial(object.monetaryAmount)
      : undefined;
    message.discountAmountApplicable = object.discountAmountApplicable ?? false;
    message.productGroupRefs = object.productGroupRefs?.map((e) => e) || [];
    message.productMatchFilters = object.productMatchFilters?.map((e) => e) || [];
    message.productCodes = object.productCodes?.map((e) => e) || [];
    return message;
  },
};

function createBaseMonetaryAmount(): MonetaryAmount {
  return { amount: "", taxAmount: "" };
}

export const MonetaryAmount = {
  encode(message: MonetaryAmount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.amount !== "") {
      writer.uint32(10).string(message.amount);
    }
    if (message.taxAmount !== "") {
      writer.uint32(18).string(message.taxAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MonetaryAmount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMonetaryAmount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.amount = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.taxAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<MonetaryAmount>, I>>(base?: I): MonetaryAmount {
    return MonetaryAmount.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MonetaryAmount>, I>>(object: I): MonetaryAmount {
    const message = createBaseMonetaryAmount();
    message.amount = object.amount ?? "";
    message.taxAmount = object.taxAmount ?? "";
    return message;
  },
};

function createBasePercentageAmount(): PercentageAmount {
  return { percentageAmount: "", percentageTaxAmount: "", maxAmount: undefined };
}

export const PercentageAmount = {
  encode(message: PercentageAmount, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.percentageAmount !== "") {
      writer.uint32(10).string(message.percentageAmount);
    }
    if (message.percentageTaxAmount !== "") {
      writer.uint32(18).string(message.percentageTaxAmount);
    }
    if (message.maxAmount !== undefined) {
      MonetaryAmount.encode(message.maxAmount, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): PercentageAmount {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBasePercentageAmount();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.percentageAmount = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.percentageTaxAmount = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.maxAmount = MonetaryAmount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<PercentageAmount>, I>>(base?: I): PercentageAmount {
    return PercentageAmount.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<PercentageAmount>, I>>(object: I): PercentageAmount {
    const message = createBasePercentageAmount();
    message.percentageAmount = object.percentageAmount ?? "";
    message.percentageTaxAmount = object.percentageTaxAmount ?? "";
    message.maxAmount = (object.maxAmount !== undefined && object.maxAmount !== null)
      ? MonetaryAmount.fromPartial(object.maxAmount)
      : undefined;
    return message;
  },
};

function createBaseCreateDiscountRequest(): CreateDiscountRequest {
  return {
    discountDefinitionRef: "",
    discountRef: "",
    receiptDescription: "",
    status: 0,
    validFrom: undefined,
    validTo: undefined,
    discountCriteria: [],
    userKey: "",
    userRef: "",
    monetaryAmount: undefined,
    percentageAmount: undefined,
  };
}

export const CreateDiscountRequest = {
  encode(message: CreateDiscountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountDefinitionRef !== "") {
      writer.uint32(10).string(message.discountDefinitionRef);
    }
    if (message.discountRef !== "") {
      writer.uint32(18).string(message.discountRef);
    }
    if (message.receiptDescription !== "") {
      writer.uint32(26).string(message.receiptDescription);
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.validFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.validFrom), writer.uint32(42).fork()).ldelim();
    }
    if (message.validTo !== undefined) {
      Timestamp.encode(toTimestamp(message.validTo), writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.discountCriteria) {
      DiscountCriteria.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.userKey !== "") {
      writer.uint32(66).string(message.userKey);
    }
    if (message.userRef !== "") {
      writer.uint32(74).string(message.userRef);
    }
    if (message.monetaryAmount !== undefined) {
      MonetaryAmount.encode(message.monetaryAmount, writer.uint32(82).fork()).ldelim();
    }
    if (message.percentageAmount !== undefined) {
      PercentageAmount.encode(message.percentageAmount, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateDiscountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateDiscountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountDefinitionRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.discountRef = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.receiptDescription = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.validFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.validTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.discountCriteria.push(DiscountCriteria.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.userKey = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.userRef = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.monetaryAmount = MonetaryAmount.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.percentageAmount = PercentageAmount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateDiscountRequest>, I>>(base?: I): CreateDiscountRequest {
    return CreateDiscountRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateDiscountRequest>, I>>(object: I): CreateDiscountRequest {
    const message = createBaseCreateDiscountRequest();
    message.discountDefinitionRef = object.discountDefinitionRef ?? "";
    message.discountRef = object.discountRef ?? "";
    message.receiptDescription = object.receiptDescription ?? "";
    message.status = object.status ?? 0;
    message.validFrom = object.validFrom ?? undefined;
    message.validTo = object.validTo ?? undefined;
    message.discountCriteria = object.discountCriteria?.map((e) => DiscountCriteria.fromPartial(e)) || [];
    message.userKey = object.userKey ?? "";
    message.userRef = object.userRef ?? "";
    message.monetaryAmount = (object.monetaryAmount !== undefined && object.monetaryAmount !== null)
      ? MonetaryAmount.fromPartial(object.monetaryAmount)
      : undefined;
    message.percentageAmount = (object.percentageAmount !== undefined && object.percentageAmount !== null)
      ? PercentageAmount.fromPartial(object.percentageAmount)
      : undefined;
    return message;
  },
};

function createBaseCreateDiscountResponse(): CreateDiscountResponse {
  return { status: 0, error: undefined, discountKey: "", version: 0 };
}

export const CreateDiscountResponse = {
  encode(message: CreateDiscountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.discountKey !== "") {
      writer.uint32(26).string(message.discountKey);
    }
    if (message.version !== 0) {
      writer.uint32(32).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateDiscountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateDiscountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountKey = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateDiscountResponse>, I>>(base?: I): CreateDiscountResponse {
    return CreateDiscountResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateDiscountResponse>, I>>(object: I): CreateDiscountResponse {
    const message = createBaseCreateDiscountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.discountKey = object.discountKey ?? "";
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseGetDiscountRequest(): GetDiscountRequest {
  return { discountRef: undefined, discountKey: undefined };
}

export const GetDiscountRequest = {
  encode(message: GetDiscountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountRef !== undefined) {
      writer.uint32(10).string(message.discountRef);
    }
    if (message.discountKey !== undefined) {
      writer.uint32(18).string(message.discountKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDiscountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDiscountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.discountKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetDiscountRequest>, I>>(base?: I): GetDiscountRequest {
    return GetDiscountRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetDiscountRequest>, I>>(object: I): GetDiscountRequest {
    const message = createBaseGetDiscountRequest();
    message.discountRef = object.discountRef ?? undefined;
    message.discountKey = object.discountKey ?? undefined;
    return message;
  },
};

function createBaseGetDiscountResponse(): GetDiscountResponse {
  return { status: 0, error: undefined, discount: undefined };
}

export const GetDiscountResponse = {
  encode(message: GetDiscountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.discount !== undefined) {
      Discount.encode(message.discount, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDiscountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDiscountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discount = Discount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetDiscountResponse>, I>>(base?: I): GetDiscountResponse {
    return GetDiscountResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetDiscountResponse>, I>>(object: I): GetDiscountResponse {
    const message = createBaseGetDiscountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.discount = (object.discount !== undefined && object.discount !== null)
      ? Discount.fromPartial(object.discount)
      : undefined;
    return message;
  },
};

function createBaseUpdateDiscountRequest(): UpdateDiscountRequest {
  return {
    discountRef: undefined,
    discountKey: undefined,
    version: 0,
    receiptDescription: "",
    status: 0,
    validFrom: undefined,
    validTo: undefined,
    discountCriteria: [],
    userKey: "",
    userRef: "",
    monetaryAmount: undefined,
    percentageAmount: undefined,
  };
}

export const UpdateDiscountRequest = {
  encode(message: UpdateDiscountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountRef !== undefined) {
      writer.uint32(10).string(message.discountRef);
    }
    if (message.discountKey !== undefined) {
      writer.uint32(98).string(message.discountKey);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.receiptDescription !== "") {
      writer.uint32(26).string(message.receiptDescription);
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.validFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.validFrom), writer.uint32(42).fork()).ldelim();
    }
    if (message.validTo !== undefined) {
      Timestamp.encode(toTimestamp(message.validTo), writer.uint32(50).fork()).ldelim();
    }
    for (const v of message.discountCriteria) {
      DiscountCriteria.encode(v!, writer.uint32(58).fork()).ldelim();
    }
    if (message.userKey !== "") {
      writer.uint32(66).string(message.userKey);
    }
    if (message.userRef !== "") {
      writer.uint32(74).string(message.userRef);
    }
    if (message.monetaryAmount !== undefined) {
      MonetaryAmount.encode(message.monetaryAmount, writer.uint32(82).fork()).ldelim();
    }
    if (message.percentageAmount !== undefined) {
      PercentageAmount.encode(message.percentageAmount, writer.uint32(90).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountRef = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.discountKey = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.receiptDescription = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.validFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.validTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.discountCriteria.push(DiscountCriteria.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.userKey = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.userRef = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.monetaryAmount = MonetaryAmount.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.percentageAmount = PercentageAmount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountRequest>, I>>(base?: I): UpdateDiscountRequest {
    return UpdateDiscountRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDiscountRequest>, I>>(object: I): UpdateDiscountRequest {
    const message = createBaseUpdateDiscountRequest();
    message.discountRef = object.discountRef ?? undefined;
    message.discountKey = object.discountKey ?? undefined;
    message.version = object.version ?? 0;
    message.receiptDescription = object.receiptDescription ?? "";
    message.status = object.status ?? 0;
    message.validFrom = object.validFrom ?? undefined;
    message.validTo = object.validTo ?? undefined;
    message.discountCriteria = object.discountCriteria?.map((e) => DiscountCriteria.fromPartial(e)) || [];
    message.userKey = object.userKey ?? "";
    message.userRef = object.userRef ?? "";
    message.monetaryAmount = (object.monetaryAmount !== undefined && object.monetaryAmount !== null)
      ? MonetaryAmount.fromPartial(object.monetaryAmount)
      : undefined;
    message.percentageAmount = (object.percentageAmount !== undefined && object.percentageAmount !== null)
      ? PercentageAmount.fromPartial(object.percentageAmount)
      : undefined;
    return message;
  },
};

function createBaseUpdateDiscountResponse(): UpdateDiscountResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const UpdateDiscountResponse = {
  encode(message: UpdateDiscountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountResponse>, I>>(base?: I): UpdateDiscountResponse {
    return UpdateDiscountResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDiscountResponse>, I>>(object: I): UpdateDiscountResponse {
    const message = createBaseUpdateDiscountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseUpdateDiscountStatusRequest(): UpdateDiscountStatusRequest {
  return { discountRef: undefined, discountKey: undefined, discountStatus: 0 };
}

export const UpdateDiscountStatusRequest = {
  encode(message: UpdateDiscountStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountRef !== undefined) {
      writer.uint32(10).string(message.discountRef);
    }
    if (message.discountKey !== undefined) {
      writer.uint32(26).string(message.discountKey);
    }
    if (message.discountStatus !== 0) {
      writer.uint32(16).int32(message.discountStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountRef = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountKey = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.discountStatus = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountStatusRequest>, I>>(base?: I): UpdateDiscountStatusRequest {
    return UpdateDiscountStatusRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDiscountStatusRequest>, I>>(object: I): UpdateDiscountStatusRequest {
    const message = createBaseUpdateDiscountStatusRequest();
    message.discountRef = object.discountRef ?? undefined;
    message.discountKey = object.discountKey ?? undefined;
    message.discountStatus = object.discountStatus ?? 0;
    return message;
  },
};

function createBaseUpdateDiscountStatusResponse(): UpdateDiscountStatusResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const UpdateDiscountStatusResponse = {
  encode(message: UpdateDiscountStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountStatusResponse>, I>>(base?: I): UpdateDiscountStatusResponse {
    return UpdateDiscountStatusResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDiscountStatusResponse>, I>>(object: I): UpdateDiscountStatusResponse {
    const message = createBaseUpdateDiscountStatusResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseRemoveDiscountRequest(): RemoveDiscountRequest {
  return { discountRef: undefined, discountKey: undefined };
}

export const RemoveDiscountRequest = {
  encode(message: RemoveDiscountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountRef !== undefined) {
      writer.uint32(10).string(message.discountRef);
    }
    if (message.discountKey !== undefined) {
      writer.uint32(18).string(message.discountKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveDiscountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveDiscountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.discountKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveDiscountRequest>, I>>(base?: I): RemoveDiscountRequest {
    return RemoveDiscountRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RemoveDiscountRequest>, I>>(object: I): RemoveDiscountRequest {
    const message = createBaseRemoveDiscountRequest();
    message.discountRef = object.discountRef ?? undefined;
    message.discountKey = object.discountKey ?? undefined;
    return message;
  },
};

function createBaseRemoveDiscountResponse(): RemoveDiscountResponse {
  return { status: 0, error: undefined };
}

export const RemoveDiscountResponse = {
  encode(message: RemoveDiscountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveDiscountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveDiscountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveDiscountResponse>, I>>(base?: I): RemoveDiscountResponse {
    return RemoveDiscountResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RemoveDiscountResponse>, I>>(object: I): RemoveDiscountResponse {
    const message = createBaseRemoveDiscountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    return message;
  },
};

function createBaseApplyDiscountNotificationRequest(): ApplyDiscountNotificationRequest {
  return {
    discountKey: "",
    discountRef: "",
    status: 0,
    currency: 0,
    monetaryAmount: undefined,
    transactionKey: "",
    transactionCreated: undefined,
    partnerKey: "",
    partnerName: "",
    organizationKey: "",
    organizationName: "",
    organizationAddress: undefined,
    storeKey: "",
    storeNumber: "",
    storeAddress: undefined,
    lineItems: [],
  };
}

export const ApplyDiscountNotificationRequest = {
  encode(message: ApplyDiscountNotificationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountKey !== "") {
      writer.uint32(10).string(message.discountKey);
    }
    if (message.discountRef !== "") {
      writer.uint32(18).string(message.discountRef);
    }
    if (message.status !== 0) {
      writer.uint32(24).int32(message.status);
    }
    if (message.currency !== 0) {
      writer.uint32(32).int32(message.currency);
    }
    if (message.monetaryAmount !== undefined) {
      MonetaryAmount.encode(message.monetaryAmount, writer.uint32(42).fork()).ldelim();
    }
    if (message.transactionKey !== "") {
      writer.uint32(82).string(message.transactionKey);
    }
    if (message.transactionCreated !== undefined) {
      Timestamp.encode(toTimestamp(message.transactionCreated), writer.uint32(90).fork()).ldelim();
    }
    if (message.partnerKey !== "") {
      writer.uint32(106).string(message.partnerKey);
    }
    if (message.partnerName !== "") {
      writer.uint32(114).string(message.partnerName);
    }
    if (message.organizationKey !== "") {
      writer.uint32(122).string(message.organizationKey);
    }
    if (message.organizationName !== "") {
      writer.uint32(130).string(message.organizationName);
    }
    if (message.organizationAddress !== undefined) {
      Address.encode(message.organizationAddress, writer.uint32(138).fork()).ldelim();
    }
    if (message.storeKey !== "") {
      writer.uint32(154).string(message.storeKey);
    }
    if (message.storeNumber !== "") {
      writer.uint32(162).string(message.storeNumber);
    }
    if (message.storeAddress !== undefined) {
      Address.encode(message.storeAddress, writer.uint32(170).fork()).ldelim();
    }
    for (const v of message.lineItems) {
      LineItem.encode(v!, writer.uint32(178).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApplyDiscountNotificationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplyDiscountNotificationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.discountRef = reader.string();
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.monetaryAmount = MonetaryAmount.decode(reader, reader.uint32());
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.transactionKey = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.transactionCreated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.partnerKey = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.partnerName = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.organizationKey = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.organizationName = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.organizationAddress = Address.decode(reader, reader.uint32());
          continue;
        case 19:
          if (tag !== 154) {
            break;
          }

          message.storeKey = reader.string();
          continue;
        case 20:
          if (tag !== 162) {
            break;
          }

          message.storeNumber = reader.string();
          continue;
        case 21:
          if (tag !== 170) {
            break;
          }

          message.storeAddress = Address.decode(reader, reader.uint32());
          continue;
        case 22:
          if (tag !== 178) {
            break;
          }

          message.lineItems.push(LineItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ApplyDiscountNotificationRequest>, I>>(
    base?: I,
  ): ApplyDiscountNotificationRequest {
    return ApplyDiscountNotificationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ApplyDiscountNotificationRequest>, I>>(
    object: I,
  ): ApplyDiscountNotificationRequest {
    const message = createBaseApplyDiscountNotificationRequest();
    message.discountKey = object.discountKey ?? "";
    message.discountRef = object.discountRef ?? "";
    message.status = object.status ?? 0;
    message.currency = object.currency ?? 0;
    message.monetaryAmount = (object.monetaryAmount !== undefined && object.monetaryAmount !== null)
      ? MonetaryAmount.fromPartial(object.monetaryAmount)
      : undefined;
    message.transactionKey = object.transactionKey ?? "";
    message.transactionCreated = object.transactionCreated ?? undefined;
    message.partnerKey = object.partnerKey ?? "";
    message.partnerName = object.partnerName ?? "";
    message.organizationKey = object.organizationKey ?? "";
    message.organizationName = object.organizationName ?? "";
    message.organizationAddress = (object.organizationAddress !== undefined && object.organizationAddress !== null)
      ? Address.fromPartial(object.organizationAddress)
      : undefined;
    message.storeKey = object.storeKey ?? "";
    message.storeNumber = object.storeNumber ?? "";
    message.storeAddress = (object.storeAddress !== undefined && object.storeAddress !== null)
      ? Address.fromPartial(object.storeAddress)
      : undefined;
    message.lineItems = object.lineItems?.map((e) => LineItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseApplyDiscountNotificationResponse(): ApplyDiscountNotificationResponse {
  return { status: 0, error: undefined, externalRef: "" };
}

export const ApplyDiscountNotificationResponse = {
  encode(message: ApplyDiscountNotificationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.externalRef !== "") {
      writer.uint32(26).string(message.externalRef);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApplyDiscountNotificationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApplyDiscountNotificationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.externalRef = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ApplyDiscountNotificationResponse>, I>>(
    base?: I,
  ): ApplyDiscountNotificationResponse {
    return ApplyDiscountNotificationResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ApplyDiscountNotificationResponse>, I>>(
    object: I,
  ): ApplyDiscountNotificationResponse {
    const message = createBaseApplyDiscountNotificationResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.externalRef = object.externalRef ?? "";
    return message;
  },
};

function createBaseSearchDiscountRequest(): SearchDiscountRequest {
  return {
    discountDefinitionRef: "",
    receiptDescription: "",
    status: 0,
    type: 0,
    validFrom: undefined,
    validTo: undefined,
    currency: 0,
    userRef: "",
  };
}

export const SearchDiscountRequest = {
  encode(message: SearchDiscountRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountDefinitionRef !== "") {
      writer.uint32(18).string(message.discountDefinitionRef);
    }
    if (message.receiptDescription !== "") {
      writer.uint32(26).string(message.receiptDescription);
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.type !== 0) {
      writer.uint32(40).int32(message.type);
    }
    if (message.validFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.validFrom), writer.uint32(50).fork()).ldelim();
    }
    if (message.validTo !== undefined) {
      Timestamp.encode(toTimestamp(message.validTo), writer.uint32(58).fork()).ldelim();
    }
    if (message.currency !== 0) {
      writer.uint32(64).int32(message.currency);
    }
    if (message.userRef !== "") {
      writer.uint32(74).string(message.userRef);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchDiscountRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchDiscountRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.discountDefinitionRef = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.receiptDescription = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.validFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.validTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.userRef = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchDiscountRequest>, I>>(base?: I): SearchDiscountRequest {
    return SearchDiscountRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchDiscountRequest>, I>>(object: I): SearchDiscountRequest {
    const message = createBaseSearchDiscountRequest();
    message.discountDefinitionRef = object.discountDefinitionRef ?? "";
    message.receiptDescription = object.receiptDescription ?? "";
    message.status = object.status ?? 0;
    message.type = object.type ?? 0;
    message.validFrom = object.validFrom ?? undefined;
    message.validTo = object.validTo ?? undefined;
    message.currency = object.currency ?? 0;
    message.userRef = object.userRef ?? "";
    return message;
  },
};

function createBaseSearchDiscountResponse(): SearchDiscountResponse {
  return { status: 0, error: undefined, discounts: [] };
}

export const SearchDiscountResponse = {
  encode(message: SearchDiscountResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.discounts) {
      Discount.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchDiscountResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchDiscountResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discounts.push(Discount.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchDiscountResponse>, I>>(base?: I): SearchDiscountResponse {
    return SearchDiscountResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchDiscountResponse>, I>>(object: I): SearchDiscountResponse {
    const message = createBaseSearchDiscountResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.discounts = object.discounts?.map((e) => Discount.fromPartial(e)) || [];
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
