import { useDiscountClient } from '../../useDiscountClient/useDiscountClient';
import { useMutation, useQueryClient } from 'react-query';

export const useUpdateProductGroup = () => {
  const client = useDiscountClient();
  const queryClient = useQueryClient();

  const { mutate: updateProductGroup, ...rest } = useMutation(
    client.updateProductGroup,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('searchProductGroup');
      },
    }
  );

  return {
    updateProductGroup,
    ...rest,
  };
};
