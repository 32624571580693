import styles from './DiscountCriteriaTiles.module.css';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { Unit } from 'mica-proto/src/micashared/common/enums/unit/v1/unit';
import { DiscountCriteria } from 'mica-proto/src/mica/discount/discount/v1/discount';
import { readableValueForEnum } from 'mica-ui-kit/src/utils/formatters/enums';
import React, { SyntheticEvent } from 'react';

type DiscountCriteriaTilesProps = {
  criteria: DiscountCriteria;
  onClick: () => void;
  onRemove: () => void;
  viewOnly?: boolean;
};

/**
 A single tile in our row of DiscountCriteria tiles
 */
const DiscountCriteriaTile = ({
  criteria,
  onClick,
  onRemove,
  viewOnly,
}: DiscountCriteriaTilesProps) => {
  const handeRemove = (e: SyntheticEvent<SVGElement>) => {
    e.stopPropagation();
    if (window.confirm('Are you sure you want to remove this criteria')) {
      onRemove();
    }
  };

  return (
    <div className={styles.tile} onClick={onClick}>
      <div className={styles.tileTopLine}>
        {criteria.quantity && (
          <div className={'mr-10'}>
            {criteria.quantity.quantity}{' '}
            {readableValueForEnum(Unit, 'UNIT_')(criteria.quantity.unit)}
          </div>
        )}
        {criteria.monetaryAmount?.amount && (
          <div>
            ${criteria.monetaryAmount.amount} | $
            {criteria.monetaryAmount.taxAmount}
          </div>
        )}
        {!viewOnly && (
          <FaTimes
            className={styles.removeButton}
            onClick={handeRemove}
            size={14}
          />
        )}
      </div>
      <div>{criteria.productGroupRefs.length} Product Groups</div>
      <div>{criteria.productMatchFilters.length} Product Filters</div>
      <div>{criteria.productCodes.length} Product Codes</div>
    </div>
  );
};

export default DiscountCriteriaTile;
