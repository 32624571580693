import {
  StyleProps,
  StyledElement,
} from '../../layout/StyledElement/StyledElement';
import Checkbox from '../Checkbox/Checkbox';
import styles from './CheckboxList.module.css';
import React from 'react';

type Option = {
  label: string;
  value: string;
};

type CheckboxListProps = StyleProps & {
  label: string;
  onChange: (value: string[]) => void;
  options?: Option[];
  optionStrings?: string[];
  value: string[];
};

const makeOptionsFromStrings = (strings: string[]) =>
  strings.map((str) => ({
    label: str,
    value: str,
  }));

/**
 A List of checkboxes or radio buttons
 */
export const CheckboxList = ({
  label,
  onChange,
  optionStrings,
  options = makeOptionsFromStrings(optionStrings!),
  value,
  ...style
}: CheckboxListProps) => (
  <StyledElement constantClassName={styles.container} {...style}>
    <div className={styles.label}>{label}</div>
    <div className={styles.options}>
      {options.map((option) => (
        <Checkbox
          key={option.label}
          label={option.label}
          checked={value.includes(option.value)}
          onChange={(checked) =>
            onChange(
              checked
                ? [...value, option.value]
                : value.filter((v) => v !== option.value)
            )
          }
          style={{ marginTop: 8 }}
        />
      ))}
    </div>
  </StyledElement>
);

export default CheckboxList;
