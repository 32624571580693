import { useSearchDiscountProviderAccount } from 'discount-client-react/src/client/discountProviderAccount/useSearchDiscountProviderAccount/useSearchDiscountProviderAccount';
import {
  DiscountProviderAccount,
  DiscountProviderAccountStatus,
} from 'mica-proto/src/mica/discount/discountprovideraccount/v1/discount_provider_account';
import { TextInput } from 'mica-ui-kit/src/components';
import { AddIconButton } from 'mica-ui-kit/src/components/consoles/inputs/buttons/AddIconButton/AddIconButton';
import { Card } from 'mica-ui-kit/src/components/consoles/layout/Card/Card';
import { readableValueForEnum } from 'mica-ui-kit/src/utils/formatters/enums';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const readableStatus = readableValueForEnum(
  DiscountProviderAccountStatus,
  'DISCOUNT_PROVIDER_ACCOUNT_STATUS_'
);

type SearchDiscountProviderAccountProps = {
  onAccountSelected: (account: DiscountProviderAccount) => void;
};

/**
 Search for a DiscountProviderAccount
 */
const SearchDiscountProviderAccount = ({
  onAccountSelected,
}: SearchDiscountProviderAccountProps) => {
  const [name, setName] = useState('');

  const navigate = useNavigate();

  const { data, isLoading } = useSearchDiscountProviderAccount(
    {
      name,
    },
    {
      retry: false,
    }
  );

  return (
    <Card
      title={'Search Accounts'}
      titleAccessories={
        <AddIconButton
          style={{ position: 'absolute', right: 20 }}
          onClick={() => navigate('create')}
        />
      }>
      <TextInput
        onChange={setName}
        value={name}
        label={'Name'}
        data-testid={'AccountNameInput'}
      />

      <table className={'table mt-20'} style={{ opacity: isLoading ? 0.6 : 1 }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Reference</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {(data?.discountProviderAccounts || []).map((account) => (
            <tr
              className={'pointer'}
              key={account.discountProviderAccountKey}
              onClick={() => onAccountSelected(account)}>
              <td>{account.name}</td>
              <td>{account.discountProviderAccountRef}</td>
              <td>{readableStatus(account.discountProviderAccountStatus)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default SearchDiscountProviderAccount;
