import { TextArea } from '../../../../../../inputs/text/TextArea/TextArea';
import { TextInput } from '../../../../../../inputs/text/TextInput/TextInput';
import { Button } from '../../../../../inputs/buttons/Button/Button';
import { Page } from '../../../../../layout/Page/Page';
import Wizard from '../../../../../layout/Wizard/Wizard';
import { InfoBox } from '../../../../../layout/text/InfoBox/InfoBox';
import styles from './CreateClientMtlsCert.module.css';
import {
  ClientMTLSCertificate,
  UpdateExternalClientMTLSCertificateRequest,
  UpdateExternalClientMTLSCertificateResponse,
  UpdateExternalClientMTLSCertificateResponse_Status,
} from 'mica-proto/src/micashared/common/v1/mtls_certificate';
import { useBoolean } from 'mica-shared-react/src/hooks/useBoolean';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const wizardSteps = [
  {
    title: 'Generate Certificate Signing Request (CSR)',
  },
  {
    title: 'Sign Generated CSR',
  },
  {
    title: 'Provide Signed Certificate',
  },
];

type UseGenerateExternalClientMTLSCertificate = () => {
  generateExternalClientMTLSCertificate: (args: {
    displayName: string;
  }) => void;
  isLoading: boolean;
  data?: {
    certificateToSign?: ClientMTLSCertificate;
  };
};

type UseUpdateExternalClientMTLSCertificate = () => {
  updateExternalClientMTLSCertificate: (
    args: UpdateExternalClientMTLSCertificateRequest
  ) => void;
  isLoading: boolean;
  data?: UpdateExternalClientMTLSCertificateResponse;
};

type CreateClientMtlsCertProps = {
  useGenerateExternalClientMTLSCertificate: UseGenerateExternalClientMTLSCertificate;
  useUpdateExternalClientMTLSCertificate: UseUpdateExternalClientMTLSCertificate;
};

/**
 Our screen/wizard for creating a client MTLS cert - common to a handful of our consoles
 */
const CreateClientMtlsCert = ({
  useGenerateExternalClientMTLSCertificate,
  useUpdateExternalClientMTLSCertificate,
}: CreateClientMtlsCertProps) => {
  const [displayName, setDisplayName] = useState('');
  const [certPem, setCertPem] = useState('');
  const [rootCaBundlePem, setRootCaBundlePem] = useState('');

  const showStep3 = useBoolean();
  const navigate = useNavigate();

  const { generateExternalClientMTLSCertificate, isLoading, data } =
    useGenerateExternalClientMTLSCertificate();

  const {
    updateExternalClientMTLSCertificate,
    isLoading: updateLoading,
    data: updateData,
  } = useUpdateExternalClientMTLSCertificate();

  const handleCopy = async () => {
    await navigator.clipboard.writeText(data!.certificateToSign!.csrPem);
    toast.success('PEM bundle copied to clipboard');
  };

  const stepIndex = useMemo(() => {
    if (showStep3.value) {
      return 2;
    }
    return data?.certificateToSign ? 1 : 0;
  }, [showStep3, data]);

  useEffect(() => {
    if (
      updateData?.status ===
      UpdateExternalClientMTLSCertificateResponse_Status.STATUS_SUCCESS
    ) {
      setTimeout(() => navigate('/app/settings/network'), 500);
    }
  }, [updateData]);

  return (
    <Page title={'Create Certificate'}>
      <Wizard
        isComplete={updateData?.certificate?.enabled}
        stepIndex={stepIndex}
        stepTitles={wizardSteps}>
        <>
          <InfoBox
            className={'mb-10'}
            message={
              "Once generated, you'll sign the CSR and provide it back so Mica can use it to authenticate with your system"
            }
          />
          <TextInput
            autoFocus
            style={{ width: 260 }}
            onChange={setDisplayName}
            onEnterPressed={() =>
              generateExternalClientMTLSCertificate({ displayName })
            }
            value={displayName}
            label={'Certificate Name'}
            placeholder={'eg: My Mica Certificate'}
          />
          <div className={'flex w-full justify-ctr mt-20'}>
            <Button
              className={'button'}
              loading={isLoading}
              onClick={() =>
                generateExternalClientMTLSCertificate({ displayName })
              }
              label={'GENERATE CSR'}
            />
          </div>
        </>
        <>
          <InfoBox
            className={'mt-10'}
            message={
              "Copy the CSR below and sign it with your private key. In the next step, you'll paste the signed credentials"
            }
          />
          <TextArea
            style={{
              height: 250,
              maxWidth: 500,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
            disabled
            // eslint-disable-next-line
            onChange={() => {}}
            value={data?.certificateToSign?.csrPem ?? ''}
            useMonospaceFont
          />
          <button className={styles.copyButton} onClick={handleCopy}>
            COPY TO CLIPBOARD
          </button>
          <div className={'flex w-full justify-ctr mt-20'}>
            <Button
              className={'button'}
              onClick={showStep3.setTrue}
              label={'NEXT'}
            />
          </div>
        </>
        <>
          <InfoBox
            message={
              'The Certificate PEM and Root Certificate Authority Bundle Pem come from signing the CSR request provided in the previous step'
            }
          />
          <TextArea
            style={{
              height: 250,
              maxWidth: 500,
              borderRadius: 5,
            }}
            onChange={setCertPem}
            label={'Certificate PEM'}
            value={certPem}
            useMonospaceFont
          />
          <TextArea
            style={{
              height: 250,
              maxWidth: 500,
              borderRadius: 5,
            }}
            onChange={setRootCaBundlePem}
            label={'Root Certificate Authority Bundle PEM'}
            value={rootCaBundlePem}
            useMonospaceFont
          />
          <div className={'flex w-full justify-ctr mt-20'}>
            <Button
              className={'button'}
              onClick={() =>
                updateExternalClientMTLSCertificate({
                  certificateRefKey: data!.certificateToSign!.certificateRefKey,
                  displayName,
                  signedCertPemFromCsr: certPem,
                  rootcaBundlePem: rootCaBundlePem,
                  enabled: data?.certificateToSign?.enabled || false,
                })
              }
              label={'Submit Certificate'}
              loading={updateLoading}
            />
          </div>
        </>
      </Wizard>
    </Page>
  );
};

export default CreateClientMtlsCert;
