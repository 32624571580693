import { Region } from 'mica-proto/src/micashared/common/enums/region/v1/region';
import { invertObj } from 'ramda';

const cleanEnumLabel = (label: string, prefix: string) =>
  label.replace(prefix, '').replace(/_/g, '-');

export const readableValueForEnum =
  (enumMap: any, prefix: string) => (value: number) =>
    cleanEnumLabel(invertObj(enumMap)[value], prefix);

export const readableRegion = readableValueForEnum(Region, 'REGION_US_');

export const enumAsSelectOptions = (
  enumObj: Record<string, any>,
  prefix = ''
) =>
  Object.entries(enumObj)
    .filter(([, value]) => typeof value === 'number')
    .map(([key, value]) => ({
      label: cleanEnumLabel(key, prefix),
      value: value,
    }));
