/* eslint-disable */

export const protobufPackage = "micashared.common.enums.unit.v1";

export enum Unit {
  UNIT_UNSPECIFIED = 0,
  UNIT_ITEM = 1,
  /** UNIT_GRAM - weight */
  UNIT_GRAM = 2,
  UNIT_KILOGRAM = 3,
  UNIT_POUND = 4,
  UNIT_OUNCE = 5,
  /** UNIT_LITRE - liquid */
  UNIT_LITRE = 6,
  UNIT_MILLILITRE = 7,
  UNIT_GALLON = 8,
  UNIT_QUART = 9,
  UNIT_PINT = 10,
  UNIT_FLUID_OUNCE = 11,
  /** UNIT_METRE - length */
  UNIT_METRE = 12,
  UNIT_CENTIMETRE = 13,
  UNIT_MILLIMETRE = 14,
  UNIT_YARD = 15,
  UNIT_FOOT = 16,
  UNIT_INCH = 17,
}
