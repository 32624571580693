import { TextArea } from '../../../../../../inputs';
import { LabelDetail } from '../../../../../../layout';
import Checkbox from '../../../../../inputs/Checkbox/Checkbox';
import { Button } from '../../../../../inputs/buttons/Button/Button';
import { Card } from '../../../../../layout/Card/Card';
import { Page } from '../../../../../layout/Page/Page';
import { InfoBox } from '../../../../../layout/text/InfoBox/InfoBox';
import {
  ClientMTLSCertificate,
  UpdateExternalClientMTLSCertificateRequest,
} from 'mica-proto/src/micashared/common/v1/mtls_certificate';
import { useCreateUpdateForm } from 'mica-shared-react/src/hooks/useCreateUpdateForm';
import React from 'react';

type ClientMtlsCertProps = {
  clientMtlsCertificate: ClientMTLSCertificate;
  loading: boolean;
  onUpdateCertificate: (
    args: UpdateExternalClientMTLSCertificateRequest
  ) => void;
};

/**
 A Component
 */
const ClientMtlsCert = ({
  clientMtlsCertificate,
  loading,
  onUpdateCertificate,
}: ClientMtlsCertProps) => {
  const { displayData, updateProperty } = useCreateUpdateForm(
    clientMtlsCertificate
  );

  return (
    <Page title={'Mica Client Certificate Details'}>
      <Card>
        <LabelDetail
          label={'Display Name'}
          value={clientMtlsCertificate.displayName}
        />
        <TextArea
          style={{
            height: 250,
            maxWidth: 500,
            borderRadius: 5,
          }}
          disabled
          // eslint-disable-next-line
          onChange={() => {}}
          label={'CSR PEM'}
          value={clientMtlsCertificate.csrPem}
          useMonospaceFont
        />

        <TextArea
          style={{
            height: 250,
            maxWidth: 500,
            borderRadius: 5,
          }}
          onChange={updateProperty('crtPem')}
          label={'Certificate PEM'}
          value={displayData.crtPem}
          useMonospaceFont
        />
        <TextArea
          style={{
            height: 250,
            maxWidth: 500,
            borderRadius: 5,
          }}
          onChange={updateProperty('rootcaBundlePem')}
          label={'Root Certificate Authority Bundle PEM'}
          value={displayData.rootcaBundlePem}
          useMonospaceFont
        />
        <InfoBox
          message={
            'The Certificate PEM and Root Certificate Authority Bundle Pem come from signing the CSR request provided in the previous step'
          }
        />
        <Checkbox
          checked={displayData.enabled}
          className={'my-10'}
          onChange={updateProperty('enabled')}
          label={'Enabled'}
        />

        <Button
          label={'SAVE'}
          loading={loading}
          onClick={() =>
            onUpdateCertificate({
              ...displayData,
              signedCertPemFromCsr: displayData.crtPem,
            })
          }
        />
      </Card>
    </Page>
  );
};

export default ClientMtlsCert;
