import ProductGroupForm from '../../../shared/products/ProductGroupForm/ProductGroupForm';
import { useCreateProductGroup } from 'discount-client-react/src/client/productGroup/useCreateProductGroup/useCreateProductGroup';
import { CreateProductGroupRequest } from 'mica-proto/src/mica/discount/productgroup/v1/product_group';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 A modal form to create a new product group
 */
const CreateProductGroup = () => {
  const { createProductGroup, isLoading } = useCreateProductGroup();

  const navigate = useNavigate();

  const handleCreateProductGroup = (productGroup: CreateProductGroupRequest) =>
    createProductGroup(productGroup, {
      onSuccess: () => {
        toast.success('Product Group created');
        navigate(`/app/products/${productGroup.productGroupRef}`);
      },
    });

  return (
    <Page title={'Add Product Group'}>
      <ProductGroupForm onSave={handleCreateProductGroup} loading={isLoading} />
    </Page>
  );
};

export default CreateProductGroup;
