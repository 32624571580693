import React from 'react';
import { StyledElement, StyleProps } from '../../StyledElement/StyledElement';
import styles from './Label.module.css';

type LabelProps = StyleProps & {
  value: string;
};

/**
 A standard label to match our text input shrinking label
 */
export const Label = ({ value, ...rest }: LabelProps) => (
  <StyledElement constantClassName={styles.label} {...rest}>
    {value}
  </StyledElement>
);
