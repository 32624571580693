import { useGenerateExternalClientMTLSCertificate } from 'discount-client-react/src/client/certs/useGenerateExternalClientMTLSCertificate/useGenerateExternalClientMTLSCertificate';
import { useUpdateExternalClientMTLSCertificate } from 'discount-client-react/src/client/certs/useUpdateExternalClientMTLSCertificate/useUpdateExternalClientMTLSCertificate';
import CreateClientMtlsCert from 'mica-ui-kit/src/components/consoles/screens/settings/network/certs/CreateClientMtlsCert/CreateClientMtlsCert';
import React from 'react';

/**
 Create a Client MTLS Cert screen/wizard
 */
const CreateClientMTLSCertificate = () => (
  <CreateClientMtlsCert
    useGenerateExternalClientMTLSCertificate={
      useGenerateExternalClientMTLSCertificate
    }
    useUpdateExternalClientMTLSCertificate={
      useUpdateExternalClientMTLSCertificate
    }
  />
);

export default CreateClientMTLSCertificate;
