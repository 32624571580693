import { TextArea, TextInput } from '../../../../../inputs';
import { Button } from '../../../../inputs/buttons/Button/Button';
import {
  ApiTokenConfiguration,
  AuthenticationHeaderConfiguration,
} from 'mica-proto/src/micashared/common/v1/authentication_token';
import { useCreateUpdateForm } from 'mica-shared-react/src/hooks/useCreateUpdateForm';
import React from 'react';

export type CreateUpdateRequest = {
  headerConfiguration: AuthenticationHeaderConfiguration | undefined;
  apiTokenValue: string;
  /** The pem encoded certificate chain */
  pemCaChain: string[];
};

type ApiTokenConfigFormProps = {
  config?: ApiTokenConfiguration;
  loading?: boolean;
  onSaveChanges?: (update: CreateUpdateRequest) => void;
};

/**
 The inputs for creating/updating the api token config
 */
const ApiTokenConfigForm = ({
  config,
  loading,
  onSaveChanges,
}: ApiTokenConfigFormProps) => {
  const { updateProperty, clearUpdates, displayData } = useCreateUpdateForm(
    config || ({} as ApiTokenConfiguration)
  );

  const handleUpdateHeaderConfig =
    (property: keyof AuthenticationHeaderConfiguration) => (value: string) =>
      updateProperty('headerConfiguration')({
        ...displayData?.headerConfiguration,
        [property]: value,
      });

  const handleUpdatePem = (value: string) =>
    updateProperty('pemCaChain')([value]);

  const handleSave = () => {
    onSaveChanges?.(displayData);
    clearUpdates();
  };

  return (
    <div>
      <div>
        <TextInput
          onChange={handleUpdateHeaderConfig('headerKey')}
          value={displayData?.headerConfiguration?.headerKey}
          label={'Header Key'}
        />
        <TextInput
          onChange={handleUpdateHeaderConfig('headerValuePrefix')}
          value={displayData?.headerConfiguration?.headerValuePrefix}
          label={'Header Prefix'}
        />
        <TextInput
          onChange={updateProperty('apiTokenValue')}
          value={displayData?.apiTokenValue}
          label={'Token'}
        />
        <TextArea
          style={{
            height: 250,
            maxWidth: 500,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
          onChange={handleUpdatePem}
          value={displayData?.pemCaChain?.[0] ?? ''}
          useMonospaceFont
        />
        <Button loading={loading} label={'SAVE CHANGES'} onClick={handleSave} />
      </div>
    </div>
  );
};

export default ApiTokenConfigForm;
