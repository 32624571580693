import {
  StyleProps,
  StyledElement,
} from '../../layout/StyledElement/StyledElement';
import styles from './Checkbox.module.css';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import cx from 'classnames';
import React from 'react';

type CheckboxProps = StyleProps & {
  checked: boolean;
  isRadio?: boolean;
  label?: string;
  onChange: (checked: boolean) => void;
};

/**
 A basic Checkbox with optional label
 */
export const Checkbox = ({
  checked,
  isRadio,
  label,
  onChange,
  ...style
}: CheckboxProps) => (
  <StyledElement
    constantClassName={styles.container}
    {...style}
    onClick={() => onChange(!checked)}>
    <div className={cx(styles.iconContainer, { [styles.isChecked]: checked })}>
      {(!isRadio || checked) && <FaCheck color={'white'} size={10} />}
    </div>
    {label && <div className={styles.label}>{label}</div>}
  </StyledElement>
);

export default Checkbox;
