import React from 'react';
import styles from './ErrorBoundaryUi.module.css';
import { Button } from '../../consoles/inputs/buttons/Button/Button';

/**
 A Component that our Sentry ErrorBoundaries present when an error occurs
 */
export const ErrorBoundaryUi = () => {
  return (
    <div className={styles.container}>
      <h2>Something went wrong!</h2>
      <p>
        Our team have been alerted. You can help us identify the issue by
        completing the form presented
      </p>
      <Button
        className={styles.button}
        onClick={() => window.location.reload()}
        label={'CONTINUE'}
      />
    </div>
  );
};
