import styles from './Carousel.module.css';
import cx from 'classnames';
import React, { PropsWithChildren, useEffect, useState } from 'react';

type CarouselProps = {
  className?: string;
  hideIndicatorDots?: boolean;
  onCarouselDidChange?: (index: number) => void;
  selectedIndex?: number;
};

/**
 A Carousel with a row of page indicator dots below.
 - Each child is a page in the carousel.
 */
export const Carousel = ({
  children,
  className,
  hideIndicatorDots,
  onCarouselDidChange,
  selectedIndex = 0,
}: PropsWithChildren<CarouselProps>) => {
  const [selectedCarouselItem, setSelectedCarouselItem] =
    useState(selectedIndex);

  const childCount = React.Children.count(children);

  const handleSetCarouselIndex = (index: number) => {
    setSelectedCarouselItem(index);
    onCarouselDidChange?.(index);
  };

  useEffect(() => {
    setSelectedCarouselItem(selectedIndex);
  }, [selectedIndex]);

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.carouselContainer}>
        <div
          className={styles.carouselContent}
          style={{
            width: `${100 * childCount}%`,
            transform: `translateX(${
              -selectedCarouselItem * (100 / childCount)
            }%)`,
          }}>
          {React.Children.map(children, (child) => (
            <div style={{ width: 100 / childCount + '%' }}>{child}</div>
          ))}
        </div>
      </div>
      {!hideIndicatorDots && (
        <div className={styles.carouselIndicatorContainer}>
          {React.Children.map(children, (child, index) => (
            <div
              className={cx(styles.carouselIndicator, {
                [styles.isSelected]: index === selectedCarouselItem,
              })}
              onClick={() => handleSetCarouselIndex(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
