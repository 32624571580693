import {
  DiscountAdministrationService,
  DiscountAdministrationServiceClientImpl,
} from 'mica-proto/src/mica/discount/administration/v1/admin_service';
import {
  DiscountToMicaService,
  DiscountToMicaServiceClientImpl,
} from 'mica-proto/src/mica/discount/service/v1/discount_to_mica_service';
import {
  makeGrpcClient,
  MakeGrpcClientArgs,
} from 'mica-utilities/src/grpc/makeGrpcClient';

export type DiscountClient = DiscountToMicaService &
  DiscountAdministrationService;

const createDiscountClient = makeGrpcClient(DiscountToMicaServiceClientImpl);

const createDiscountAdminClient = makeGrpcClient(
  DiscountAdministrationServiceClientImpl
);

export const createMicaDiscountClient = (
  args: MakeGrpcClientArgs
): DiscountClient => {
  const discountClient = createDiscountClient(args);
  const adminClient = createDiscountAdminClient(args);

  return {
    ...discountClient,
    ...adminClient,
  } as DiscountClient;
};
