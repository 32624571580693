import { StyleProps, StyledElement } from '../StyledElement/StyledElement';
import styles from './Page.module.css';
import React, { PropsWithChildren } from 'react';

type PageProps = StyleProps & {
  title?: string;
  titleAccessory?: JSX.Element;
};

/**
 Our basic Page layout
 */
export const Page = ({
  children,
  title,
  titleAccessory,
  ...style
}: PropsWithChildren<PageProps>) => (
  <StyledElement constantClassName={styles.container} {...style}>
    {title && (
      <div className={styles.title}>
        {title}
        {'  '}
        {titleAccessory}
      </div>
    )}
    {children}
  </StyledElement>
);
