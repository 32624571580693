import { Modal } from '../../../../layout/Modal/Modal';
import styles from './HelpPopover.module.css';
import { IoMdHelpCircle } from '@react-icons/all-files/io/IoMdHelpCircle';
import { useBoolean } from 'mica-shared-react/src/hooks/useBoolean';
import React, { PropsWithChildren } from 'react';

type HelpPopoverProps = {
  label: string;
};

/**
 A Help icon that opens a modal displaying the rendered children
 */
export const HelpPopover = ({
  children,
  label,
}: PropsWithChildren<HelpPopoverProps>) => {
  const showModal = useBoolean();

  return (
    <>
      <IoMdHelpCircle className={styles.icon} onClick={showModal.setTrue} />
      <Modal
        active={showModal.value}
        title={label}
        onDismiss={showModal.setFalse}>
        <div className={styles.modal}>{children}</div>
      </Modal>
    </>
  );
};
