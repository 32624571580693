import RadioList from '../../../../inputs/RadioList/RadioList';
import { InfoBox } from '../../../../layout/text/InfoBox/InfoBox';
import { ClientAuthenticationType } from 'mica-proto/src/micashared/common/v1/external_client';
import React, { useMemo } from 'react';

type ClientAuthenticationTypeSelectorProps = {
  selectedAuthenticationType?: ClientAuthenticationType;
  onAuthenticationTypeChanged: (type: ClientAuthenticationType) => void;
  suportedClientAuthenticationTypes: ClientAuthenticationType[];
};

const authenticationOptions = [
  {
    label: 'MTLS Certificate',
    value:
      ClientAuthenticationType.CLIENT_AUTHENTICATION_TYPE_CLIENT_CERTIFICATE,
  },
  {
    label: 'API Token',
    value: ClientAuthenticationType.CLIENT_AUTHENTICATION_TYPE_API_TOKEN,
  },
];

/**
 A Component
 */
const ClientAuthenticationTypeSelector = ({
  selectedAuthenticationType,
  onAuthenticationTypeChanged,
  suportedClientAuthenticationTypes,
}: ClientAuthenticationTypeSelectorProps) => {
  const supportedAuthenticationOptions = useMemo(
    () =>
      authenticationOptions.filter((option) =>
        suportedClientAuthenticationTypes.includes(option.value)
      ),
    [suportedClientAuthenticationTypes]
  );

  const selectedType =
    selectedAuthenticationType === 0
      ? ClientAuthenticationType.CLIENT_AUTHENTICATION_TYPE_CLIENT_CERTIFICATE
      : selectedAuthenticationType;

  return (
    <>
      {suportedClientAuthenticationTypes.length > 1 && (
        <>
          <RadioList
            label={'Authentication Type'}
            onChange={onAuthenticationTypeChanged}
            options={supportedAuthenticationOptions}
            value={selectedType}
          />
          <InfoBox className={'my-10'}>
            Requests from the Mica network to you can be identified using either
            certificates (MTLS) or API tokens in the request header
          </InfoBox>
        </>
      )}
      {suportedClientAuthenticationTypes.length === 1 &&
        suportedClientAuthenticationTypes[0] ===
          ClientAuthenticationType.CLIENT_AUTHENTICATION_TYPE_CLIENT_CERTIFICATE && (
          <InfoBox className={'my-10'}>
            Certificates are used to identify requests from the Mica network to
            you
          </InfoBox>
        )}
      {suportedClientAuthenticationTypes.length === 1 &&
        suportedClientAuthenticationTypes[0] ===
          ClientAuthenticationType.CLIENT_AUTHENTICATION_TYPE_API_TOKEN && (
          <InfoBox className={'my-10'}>
            Tokens are used in each request header to identify requests from the
            Mica network to you
          </InfoBox>
        )}
    </>
  );
};

export default ClientAuthenticationTypeSelector;
