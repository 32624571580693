import { HelpPopover } from '../../../../../../layout/text/HelpPopover/HelpPopover';
import styles from './CsrHelpPopover.module.css';
import React from 'react';

/**
 Our help popover to explain how to sign the csr
 */
const CsrHelpPopover = () => {
  return (
    <HelpPopover label={'How to generate a CSR'}>
      <div className={styles.info}>
        The code example below demonstrates how to generate a Certificate
        Signing Request (CSR):
      </div>
      <pre className={styles.codeBlock}>{`
cat <<EOF > san.cnf
[ req ]
default_bits       = 2048
distinguished_name = req_distinguished_name
req_extensions     = req_ext
prompt             = no
[ req_distinguished_name ]
countryName                = US
stateOrProvinceName        = Texas
localityName               = Dallas
organizationName           = My Organization
commonName                 = server0.[partition].members.mica.io
[ req_ext ]
subjectAltName = @alt_names
[alt_names]
DNS.1   = server0.[partition].members.mica.io
EOF
openssl req -out server0.csr -newkey rsa:2048 -nodes -keyout server0.key -config san.cnf
cat server0.csr`}</pre>
    </HelpPopover>
  );
};

export default CsrHelpPopover;
