import SearchDiscountProviderAccount from '../../accounts/SearchDiscountProviderAccount/SearchDiscountProviderAccount';
import DiscountCriteriaTiles from '../DiscountCriteriaTiles/DiscountCriteriaTiles';
import MonetaryPercentageOptions from './MonetaryPercentageOptions/MonetaryPercentageOptions';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import dayjs from 'dayjs';
import { Currency } from 'mica-proto/src/micashared/common/enums/currency/v1/currency';
import { DiscountType } from 'mica-proto/src/micashared/common/enums/discounttype/v1/discount_type';
import { DiscountCriteria } from 'mica-proto/src/mica/discount/discount/v1/discount';
import {
  CreateDiscountDefinitionRequest,
  DiscountDefinitionStatus,
} from 'mica-proto/src/mica/discount/discountdefinition/v1/discount_definition';
import { useBoolean } from 'mica-shared-react/src/hooks/useBoolean';
import { useCreateUpdateForm } from 'mica-shared-react/src/hooks/useCreateUpdateForm';
import { LoadingButton, Select, TextInput } from 'mica-ui-kit/src/components';
import { enumAsSelectOptions } from 'mica-ui-kit/src/utils/formatters/enums';
import { Card } from 'mica-ui-kit/src/components/consoles/layout/Card/Card';
import { DatePicker } from 'mica-ui-kit/src/components/inputs/DatePicker/DatePicker';
import { Modal } from 'mica-ui-kit/src/components/layout/Modal/Modal';
import React from 'react';
import styles from './DiscountDefinitionForm.module.css';

// @ts-ignore:
export const defaultDiscountDefinition: CreateDiscountDefinitionRequest = {
  currency: Currency.CURRENCY_USD,
  discountCriteria: [],
  discountDefinitionRef: '',
  discountProviderAccountRef: '',
  monetaryAmount: {
    amount: '0',
    taxAmount: '0',
  },
  receiptDescription: '',
  status: 1 as const,
  type: 1 as const,
  validFrom: new Date(),
  validTo: dayjs().add(1, 'months').toDate(),
};

type Input = typeof defaultDiscountDefinition & {
  version?: number;
};

const statusOptions = enumAsSelectOptions(
  DiscountDefinitionStatus,
  'DISCOUNT_DEFINITION_STATUS_'
);
const typeOptions = enumAsSelectOptions(DiscountType, 'DISCOUNT_TYPE_');

type DiscountDefinitionFormProps<BaseRecord extends Input> = {
  discountDefinition?: BaseRecord;
  loading?: boolean;
  onSave: (input: BaseRecord) => void;
};

/**
 The create/update form for a discount definition
 */
const DiscountDefinitionForm = <BaseRecord extends Input>({
  discountDefinition,
  loading,
  onSave,
}: DiscountDefinitionFormProps<BaseRecord>) => {
  const showSearchAccounts = useBoolean();

  const { displayData, updateProperty } = useCreateUpdateForm(
    discountDefinition || defaultDiscountDefinition
  );

  const handleSave = () => onSave(displayData as BaseRecord);

  return (
    <Card>
      <div style={{ width: 330 }}>
        <TextInput
          autoFocus
          className={'mb-20'}
          onChange={updateProperty('discountDefinitionRef')}
          value={displayData.discountDefinitionRef}
          label={'Internal Ref/ID'}
          required
        />
        <TextInput
          className={'mb-20'}
          onChange={updateProperty('discountProviderAccountRef')}
          value={displayData.discountProviderAccountRef}
          label={'Discount Provider Account Ref'}
          required>
          <FaSearch
            onClick={showSearchAccounts.setTrue}
            className={'pointer'}
          />
        </TextInput>
        <TextInput
          className={'mb-20'}
          onChange={updateProperty('headline')}
          value={displayData.headline}
          label={'Headline'}
          required
        />
        <TextInput
          className={'mb-20'}
          onChange={updateProperty('summary')}
          value={displayData.summary}
          label={'Summary'}
          required
        />
        <TextInput
          className={'mb-20'}
          onChange={updateProperty('thumbnailUrl')}
          value={displayData.thumbnailUrl}
          label={'Thumbnail Url'}
          required
        />
        <div className={styles.thumbnailPreviewContainer}>
          {displayData.thumbnailUrl ? (
            <img
              className={styles.thumbnailPreview}
              src={displayData.thumbnailUrl}
              alt={'Thumbnail Preview'}
            />
          ) : (
            <div className={styles.thumbnailPreviewPlaceholder}>
              Thumbnail Preview
            </div>
          )}
        </div>
        <TextInput
          className={'mb-20'}
          onChange={updateProperty('receiptDescription')}
          value={displayData.receiptDescription}
          label={'Receipt Description'}
          required
        />
        <Select
          className={'mb-20'}
          label={'Discount Type'}
          onChange={updateProperty('type')}
          options={typeOptions}
          value={displayData.type}
          required
        />

        <MonetaryPercentageOptions
          isUpdate={!!discountDefinition}
          onSetMonetaryAmount={updateProperty('monetaryAmount')}
          onSetPercentageAmount={updateProperty('percentageAmount')}
          monetaryAmount={displayData.monetaryAmount}
          percentageAmount={displayData.percentageAmount}
        />

        <div className={'my-20'}>
          <div className={'section-header mb-5'}>CRITERIA:</div>
          <DiscountCriteriaTiles
            criteria={displayData.discountCriteria}
            onAddCriteria={(criteria: DiscountCriteria) =>
              updateProperty('discountCriteria')([
                ...displayData.discountCriteria,
                criteria,
              ])
            }
            onUpdateCriteria={updateProperty('discountCriteria')}
          />
        </div>

        <DatePicker
          className={'mb-20'}
          displayTime
          label={'Start Date'}
          value={displayData?.validFrom}
          onChange={updateProperty('validFrom')}
          required
        />

        <DatePicker
          className={'mb-20'}
          displayTime
          label={'End Date'}
          value={displayData?.validTo}
          onChange={updateProperty('validTo')}
          required
        />

        <Select
          className={'mb-20'}
          label={'Status'}
          onChange={updateProperty('status')}
          options={statusOptions}
          value={displayData.status}
          required
        />
        <LoadingButton
          loading={loading}
          title={discountDefinition ? 'UPDATE' : 'CREATE'}
          onClick={handleSave}
        />
        <Modal
          active={showSearchAccounts.value}
          title={'SEARCH ACCOUNTS'}
          onDismiss={showSearchAccounts.setFalse}>
          <div className={'p-10'}>
            <SearchDiscountProviderAccount
              onAccountSelected={(account) => {
                updateProperty('discountProviderAccountRef')(
                  account.discountProviderAccountRef
                );
                showSearchAccounts.setFalse();
              }}
            />
          </div>
        </Modal>
      </div>
    </Card>
  );
};

export default DiscountDefinitionForm;
