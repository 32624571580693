import { useGenerateMTLSCertificate } from 'discount-client-react/src/client/certs/useGenerateMTLSCertificate/useGenerateMTLSCertificate';
import { CreateMtlsCert } from 'mica-ui-kit/src/components/consoles/screens/settings/network/certs/CreateMtlsCert/CreateMtlsCert';
import React from 'react';

export default () => (
  <CreateMtlsCert
    fixedRole={'RoleDiscountExternalServiceAccount'}
    useGenerateMTLSCertificate={useGenerateMTLSCertificate}
  />
);
