import { FaCheckCircle } from '@react-icons/all-files/fa/FaCheckCircle';
import cx from 'classnames';
import { range } from 'ramda';
import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { ToastContainer } from 'react-toastify';
import styles from './Wizard.module.css';
import { Card } from '../Card/Card';

type StepTitle = {
  title: string;
  titleAccessories?: JSX.Element;
};

type WizardProps = PropsWithChildren<{
  isComplete?: boolean;
  stepIndex: number;
  stepTitles: StepTitle[];
}>;

/**
 A stack of Cards displayed one at a time to form a 'wizard' - a flow of steps required
 to complete a task
 */
const Wizard = ({
  children,
  isComplete,
  stepIndex,
  stepTitles,
}: WizardProps) => {
  const childArray = React.Children.toArray(children);

  useEffect(() => {
    if (childArray.length !== stepTitles.length) {
      throw new Error(
        `Wizard: ${childArray.length} children but ${stepTitles.length} stepTitles.  These should match!`
      );
    }
  }, []);

  const openSteps = useMemo(() => range(0, stepIndex + 1), [stepIndex]);

  return (
    <>
      {openSteps.map((title, i) => (
        <Card
          className={cx(styles.wizardCard, {
            [styles.reduced]: stepIndex !== i || isComplete,
          })}
          key={title}
          title={`Step ${i + 1}/${stepTitles.length}: ${stepTitles[i].title}`}
          titleAccessories={
            stepIndex > i || isComplete ? (
              <div className={styles.stepCompleteIndicator}>
                <FaCheckCircle />
              </div>
            ) : (
              stepTitles[i].titleAccessories
            )
          }>
          {!isComplete && stepIndex === i && childArray[i]}
        </Card>
      ))}
      <div className={styles.progressContainer}>
        <div
          className={styles.progressBar}
          style={{
            width: isComplete
              ? '100%'
              : `${(stepIndex / stepTitles.length) * 100}%`,
          }}
        />
      </div>
      <ToastContainer hideProgressBar />
    </>
  );
};

export default Wizard;
