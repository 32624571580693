import styles from './NavigationMenuItem.module.css';
import { FaCaretRight } from '@react-icons/all-files/fa/FaCaretRight';
import cx from 'classnames';
import { motion } from 'framer-motion';
import { useBoolean } from 'mica-shared-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export type MenuItem = {
  children?: MenuItem[];
  icon: JSX.Element;
  isSelected?: boolean;
  onClick?: () => void;
  title: string;
};

type NavigationMenuItemProps = {
  isChild?: boolean;
  isFullWidth: boolean;
  item: MenuItem;
  onClick?: () => void;
};

/**
 A single menu item in our Navigation Menu
 */
export const NavigationMenuItem = ({
  isChild,
  isFullWidth,
  item,
  onClick,
}: NavigationMenuItemProps) => {
  const isExpanded = useBoolean(window.innerWidth < 600);

  const { pathname } = useLocation();

  const handleClick = () => {
    !isFullWidth && !isChild && isExpanded.value && isExpanded.setFalse();
    onClick?.();
    item.onClick?.();
  };

  const handleContract = () => {
    // delay ensures if someone clicks on the element that fires onSetTrue, that
    // event flushes before we set false
    if (!isFullWidth) {
      setTimeout(() => {
        isExpanded.setFalse();
      }, 150);
    }
    window.removeEventListener('pointerdown', handleContract);
  };

  const handleExpand = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    if (!isExpanded.value) {
      isExpanded.setTrue();
      window.addEventListener('pointerdown', handleContract);
    }
    if (isExpanded.value && isFullWidth) {
      isExpanded.setFalse();
    }
  };

  useEffect(() => {
    !isFullWidth && isExpanded.setFalse();
  }, [isFullWidth]);

  useEffect(() => {
    !isFullWidth && isExpanded.value && isExpanded.setFalse();
  }, [pathname]);

  return (
    <>
      <div
        className={cx(styles.container, {
          [styles.isFullWidth]: isFullWidth,
          [styles.isSelected]: item.isSelected,
          [styles.isChild]: isChild,
        })}>
        <div
          className={styles.element}
          {...(isFullWidth ? {} : { ['data-tip']: item.title })}
          onClick={handleClick}>
          <div className={styles.iconContainer}>{item.icon}</div>
          {isFullWidth && <div className={styles.itemTitle}>{item.title}</div>}
        </div>

        {item.children && (
          <button
            className={cx(styles.expandor, {
              [styles.isExpanded]: isExpanded.value,
            })}
            onClick={handleExpand}>
            <FaCaretRight />
          </button>
        )}
        {item.isSelected && (isFullWidth || !isChild) && (
          <motion.div
            className={styles.selectedIndicator}
            layoutId={'SelectedMenuItemIndicator'}
          />
        )}
        {isExpanded.value && !isFullWidth && item.children && (
          <div className={styles.iconOnlyChildren}>
            {item.children.map((child) => (
              <NavigationMenuItem
                key={child.title}
                isChild
                isFullWidth={isFullWidth}
                item={child}
                onClick={isExpanded.setFalse}
              />
            ))}
          </div>
        )}
      </div>
      {isExpanded.value &&
        isFullWidth &&
        item.children &&
        item.children.map((child) => (
          <NavigationMenuItem
            key={child.title}
            isChild
            isFullWidth={isFullWidth}
            item={child}
          />
        ))}
    </>
  );
};
