import DiscountCriteriaForm from './DiscountCriteriaForm/DiscountCriteriaForm';
import DiscountCriteriaTile from './DiscountCriteriaTile';
import styles from './DiscountCriteriaTiles.module.css';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import { DiscountCriteria } from 'mica-proto/src/mica/discount/discount/v1/discount';
import { useBoolean } from 'mica-shared-react/src/hooks/useBoolean';
import { Modal } from 'mica-ui-kit/src/components/layout/Modal/Modal';
import { remove } from 'ramda';
import React, { useState } from 'react';

type DiscountCriteriaTilesProps = {
  criteria: Array<DiscountCriteria>;
  onAddCriteria: (criteria: DiscountCriteria) => void;
  onUpdateCriteria: (criteria: Array<DiscountCriteria>) => void;
  viewOnly?: boolean;
};

/**
 A Component
 */
const DiscountCriteriaTiles = ({
  criteria,
  onAddCriteria,
  onUpdateCriteria,
  viewOnly,
}: DiscountCriteriaTilesProps) => {
  const [editCriteriaIndex, setEditCriteriaIndex] = useState<
    undefined | number
  >(undefined);

  const showAddCriteriaForm = useBoolean();

  const handleAddCriteria = (c: DiscountCriteria) => {
    onAddCriteria(c);
    showAddCriteriaForm.setFalse();
  };

  const handleUpdateCriteria = (c: DiscountCriteria) => {
    onUpdateCriteria(
      criteria.map((original, i) => (i === editCriteriaIndex ? c : original))
    );
    setEditCriteriaIndex(undefined);
  };

  return (
    <div className={styles.container}>
      {criteria.map((c, i) => (
        <DiscountCriteriaTile
          key={i}
          criteria={c}
          onClick={() => setEditCriteriaIndex(viewOnly ? undefined : i)}
          onRemove={() => onUpdateCriteria(remove(i, 1, criteria))}
          viewOnly={viewOnly}
        />
      ))}
      {!viewOnly && (
        <button
          className={styles.addButton}
          onClick={showAddCriteriaForm.setTrue}>
          <FaPlus />
        </button>
      )}
      <Modal
        active={showAddCriteriaForm.value}
        title={'Add Criteria'}
        onDismiss={showAddCriteriaForm.setFalse}>
        <DiscountCriteriaForm onSave={handleAddCriteria} />
      </Modal>
      <Modal
        active={editCriteriaIndex !== undefined}
        title={'Update Criteria'}
        onDismiss={() => setEditCriteriaIndex(undefined)}>
        <DiscountCriteriaForm
          criteria={criteria[editCriteriaIndex!]}
          onSave={handleUpdateCriteria}
        />
      </Modal>
    </div>
  );
};

export default DiscountCriteriaTiles;
