/* eslint-disable */

export const protobufPackage = "micashared.common.enums.currency.v1";

/** From: ISO 4217 (see: https://en.wikipedia.org/wiki/ISO_4217) */
export enum Currency {
  CURRENCY_UNSPECIFIED = 0,
  CURRENCY_ALL = 8,
  CURRENCY_DZD = 12,
  CURRENCY_ARS = 32,
  CURRENCY_AUD = 35,
  CURRENCY_BSD = 44,
  CURRENCY_BHD = 48,
  CURRENCY_BDT = 50,
  CURRENCY_AMD = 51,
  CURRENCY_BBD = 52,
  CURRENCY_BMD = 60,
  CURRENCY_BTN = 64,
  CURRENCY_BOB = 68,
  CURRENCY_BWP = 72,
  CURRENCY_BZD = 84,
  CURRENCY_SBD = 90,
  CURRENCY_BND = 96,
  CURRENCY_MMK = 104,
  CURRENCY_BIF = 108,
  CURRENCY_KHR = 116,
  CURRENCY_CAD = 124,
  CURRENCY_CVE = 132,
  CURRENCY_KYD = 136,
  CURRENCY_LKR = 144,
  CURRENCY_CLP = 152,
  CURRENCY_CNY = 156,
  CURRENCY_COP = 170,
  CURRENCY_KMF = 174,
  CURRENCY_CRC = 188,
  CURRENCY_HRK = 191,
  CURRENCY_CUP = 192,
  CURRENCY_CZK = 203,
  CURRENCY_DKK = 208,
  CURRENCY_DOP = 214,
  CURRENCY_SVC = 222,
  CURRENCY_ETB = 230,
  CURRENCY_ERN = 232,
  CURRENCY_FKP = 238,
  CURRENCY_FJD = 242,
  CURRENCY_DJF = 262,
  CURRENCY_GMD = 270,
  CURRENCY_GIP = 292,
  CURRENCY_GTQ = 320,
  CURRENCY_GNF = 324,
  CURRENCY_GYD = 328,
  CURRENCY_HTG = 332,
  CURRENCY_HNL = 340,
  CURRENCY_HKD = 344,
  CURRENCY_HUF = 348,
  CURRENCY_ISK = 352,
  CURRENCY_INR = 356,
  CURRENCY_IDR = 360,
  CURRENCY_IRR = 364,
  CURRENCY_IQD = 368,
  CURRENCY_ILS = 376,
  CURRENCY_JMD = 388,
  CURRENCY_JPY = 392,
  CURRENCY_KZT = 398,
  CURRENCY_JOD = 400,
  CURRENCY_KES = 404,
  CURRENCY_KPW = 408,
  CURRENCY_KRW = 410,
  CURRENCY_KWD = 414,
  CURRENCY_KGS = 417,
  CURRENCY_LAK = 418,
  CURRENCY_LBP = 422,
  CURRENCY_LSL = 426,
  CURRENCY_LRD = 430,
  CURRENCY_LYD = 434,
  CURRENCY_MOP = 446,
  CURRENCY_MWK = 454,
  CURRENCY_MYR = 458,
  CURRENCY_MVR = 462,
  CURRENCY_MRO = 478,
  CURRENCY_MUR = 480,
  CURRENCY_MXN = 484,
  CURRENCY_MNT = 496,
  CURRENCY_MDL = 498,
  CURRENCY_MAD = 504,
  CURRENCY_OMR = 512,
  CURRENCY_NAD = 516,
  CURRENCY_NPR = 524,
  CURRENCY_ANG = 532,
  CURRENCY_AWG = 533,
  CURRENCY_VUV = 548,
  CURRENCY_NZD = 554,
  CURRENCY_NIO = 558,
  CURRENCY_NGN = 566,
  CURRENCY_NOK = 578,
  CURRENCY_PKR = 586,
  CURRENCY_PAB = 590,
  CURRENCY_PGK = 598,
  CURRENCY_PYG = 600,
  CURRENCY_PEN = 604,
  CURRENCY_PHP = 608,
  CURRENCY_QAR = 634,
  CURRENCY_RUB = 643,
  CURRENCY_RWF = 646,
  CURRENCY_SHP = 654,
  CURRENCY_STD = 678,
  CURRENCY_SAR = 682,
  CURRENCY_SCR = 690,
  CURRENCY_SLL = 694,
  CURRENCY_SGD = 702,
  CURRENCY_VND = 704,
  CURRENCY_SOS = 706,
  CURRENCY_ZAR = 710,
  CURRENCY_SSP = 728,
  CURRENCY_SZL = 748,
  CURRENCY_SEK = 752,
  CURRENCY_CHF = 756,
  CURRENCY_SYP = 760,
  CURRENCY_THB = 764,
  CURRENCY_TOP = 776,
  CURRENCY_TTD = 780,
  CURRENCY_AED = 784,
  CURRENCY_TND = 788,
  CURRENCY_UGX = 800,
  CURRENCY_MKD = 807,
  CURRENCY_EGP = 818,
  CURRENCY_GBP = 826,
  CURRENCY_TZS = 834,
  CURRENCY_USD = 840,
  CURRENCY_UYU = 858,
  CURRENCY_UZS = 860,
  CURRENCY_WST = 882,
  CURRENCY_YER = 886,
  CURRENCY_TWD = 901,
  CURRENCY_CUC = 931,
  CURRENCY_ZWL = 932,
  CURRENCY_BYN = 933,
  CURRENCY_TMT = 934,
  CURRENCY_GHS = 936,
  CURRENCY_VEF = 937,
  CURRENCY_SDG = 938,
  CURRENCY_UYI = 940,
  CURRENCY_RSD = 941,
  CURRENCY_MZN = 943,
  CURRENCY_AZN = 944,
  CURRENCY_RON = 946,
  CURRENCY_CHE = 947,
  CURRENCY_CHW = 948,
  CURRENCY_TRY = 949,
  CURRENCY_XAF = 950,
  CURRENCY_XCD = 951,
  CURRENCY_XOF = 952,
  CURRENCY_XPF = 953,
  CURRENCY_XBA = 955,
  CURRENCY_XBB = 956,
  CURRENCY_XBC = 957,
  CURRENCY_XBD = 958,
  CURRENCY_XAU = 959,
  CURRENCY_XDR = 960,
  CURRENCY_XAG = 961,
  CURRENCY_XPT = 962,
  CURRENCY_XTS = 963,
  CURRENCY_XPD = 964,
  CURRENCY_XUA = 965,
  CURRENCY_ZMW = 967,
  CURRENCY_SRD = 968,
  CURRENCY_MGA = 969,
  CURRENCY_COU = 970,
  CURRENCY_AFN = 971,
  CURRENCY_TJS = 972,
  CURRENCY_AOA = 973,
  CURRENCY_BGN = 975,
  CURRENCY_CDF = 976,
  CURRENCY_BAM = 977,
  CURRENCY_EUR = 978,
  CURRENCY_MXV = 979,
  CURRENCY_UAH = 980,
  CURRENCY_GEL = 981,
  CURRENCY_BOV = 984,
  CURRENCY_PLN = 985,
  CURRENCY_BRL = 986,
  CURRENCY_CLF = 990,
  CURRENCY_XSU = 994,
  CURRENCY_USN = 997,
  CURRENCY_XXX = 999,
}
