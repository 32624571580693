import { StyleProps, StyledElement } from '../../StyledElement/StyledElement';
import styles from './Link.module.css';
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft';
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';
import React from 'react';

type LinkProps = StyleProps & {
  chevronSide?: 'left' | 'right';
  label: string;
  leftAccessory?: JSX.Element;
  onClick: () => void;
  rightAccessory?: JSX.Element;
};

/**
 A hyperlink looking component with optional left/right accessories for icons
 */
export const Link = ({
  chevronSide,
  label,
  leftAccessory = chevronSide === 'left' ? (
    <FaChevronLeft size={10} className={'ml-2'} />
  ) : undefined,
  rightAccessory = chevronSide === 'right' ? (
    <FaChevronRight size={10} className={'ml-2'} />
  ) : undefined,
  ...style
}: LinkProps) => (
  <StyledElement Tag={'span'} constantClassName={styles.container} {...style}>
    {leftAccessory}
    <span className={styles.label}>{label}</span>
    {rightAccessory}
  </StyledElement>
);
