import { BiReceipt } from '@react-icons/all-files/bi/BiReceipt';
import { FaChartLine } from '@react-icons/all-files/fa/FaChartLine';
import { FaNetworkWired } from '@react-icons/all-files/fa/FaNetworkWired';
import { MdAccountBalance } from '@react-icons/all-files/md/MdAccountBalance';
import { MdFormatListNumbered } from '@react-icons/all-files/md/MdFormatListNumbered';
import { MdShoppingCart } from '@react-icons/all-files/md/MdShoppingCart';
import { SearchAnything } from 'mica-ui-kit/src/components/consoles/inputs/text/SearchAnything/SearchAnything';
import { NavigationContainer } from 'mica-ui-kit/src/components/consoles/layout/navigation/NavigationContainer/NavigationContainer';
import { NavigationHeader } from 'mica-ui-kit/src/components/consoles/layout/navigation/NavigationHeader/NavigationHeader';
import { NavigationMenu } from 'mica-ui-kit/src/components/consoles/layout/navigation/NavigationMenu/NavigationMenu';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 Our Navigation menu and header
 */
const NavigationLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const menuItems = useMemo(
    () => [
      {
        icon: <FaChartLine size={16} />,
        title: 'Dashboard',
        onClick: () => navigate('/app'),
        isSelected: pathname === '/app',
      },
      {
        icon: <FaNetworkWired size={16} />,
        title: 'Network',
        onClick: () => navigate('/app/settings/network'),
        isSelected: pathname === '/app/settings/network',
      },
      {
        icon: <MdAccountBalance size={16} />,
        title: 'Accounts',
        onClick: () => navigate('/app/accounts'),
        isSelected: pathname === '/app/accounts',
      },
      {
        icon: <MdShoppingCart size={17} />,
        title: 'Products',
        onClick: () => navigate('/app/products'),
        isSelected: pathname === '/app/products',
      },
      {
        icon: <MdFormatListNumbered size={17} />,
        title: 'Definitions',
        onClick: () => navigate('/app/definitions'),
        isSelected: pathname === '/app/definitions',
      },
      {
        icon: <BiReceipt size={17} />,
        title: 'Discounts',
        onClick: () => navigate('/app/discounts'),
        isSelected: pathname === '/app/discounts',
      },
    ],
    [pathname]
  );

  return (
    <NavigationContainer>
      <NavigationMenu items={menuItems} />
      <NavigationHeader>
        <SearchAnything />
      </NavigationHeader>
    </NavigationContainer>
  );
};

export default NavigationLayout;
