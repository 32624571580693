import { Backdrop } from '../Backdrop/Backdrop';
import styles from './Modal.module.scss';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { motion } from 'framer-motion';
import React, { PropsWithChildren } from 'react';

type Props = {
  contentClassName?: string;
  onDismiss?: () => void;
  title?: string;
  active: boolean;
};

const dropIn = {
  hidden: {
    y: '-5vh',
    opacity: 0,
  },
  visible: {
    y: '0',
    opacity: 1,
  },
  exit: {
    y: '5vh',
    opacity: 0,
  },
};

/**
 Our standard modal component with optional title and dismiss button.
 */
export const Modal = ({
  children,
  contentClassName,
  onDismiss,
  title,
  active,
}: PropsWithChildren<Props>) => {
  return (
    <Backdrop onClick={onDismiss} active={active}>
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className={styles.modal}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit">
        {title && <div className={styles.modalTitle}>{title}</div>}
        {onDismiss && (
          <div className={styles.modalDismiss} onClick={onDismiss}>
            <FaTimes onClick={onDismiss} />
          </div>
        )}
        <div className={contentClassName}>{children}</div>
      </motion.div>
    </Backdrop>
  );
};
