/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import { PingRequest, PingResponse } from "../../../../micashared/common/ping/v1/ping";
import {
  CreateApiTokenConfigurationRequest,
  CreateApiTokenConfigurationResponse,
  GetApiTokenConfigurationRequest,
  GetApiTokenConfigurationResponse,
  UpdateApiTokenConfigurationRequest,
  UpdateApiTokenConfigurationResponse,
} from "../../../../micashared/common/v1/authentication_token";
import {
  GetExternalClientSettingsRequest,
  GetExternalClientSettingsResponse,
  UpdateExternalClientCallBackAddressRequest,
  UpdateExternalClientCallBackAddressResponse,
} from "../../../../micashared/common/v1/external_client";
import {
  GenerateExternalClientMTLSCertificateRequest,
  GenerateExternalClientMTLSCertificateResponse,
  GenerateMTLSCertificateRequest,
  GenerateMTLSCertificateResponse,
  GetMTLSCertificateRequest,
  GetMTLSCertificateResponse,
  SearchExternalClientMTLSCertificateRequest,
  SearchExternalClientMTLSCertificateResponse,
  SearchMTLSCertificateRequest,
  SearchMTLSCertificateResponse,
  UpdateExternalClientMTLSCertificateRequest,
  UpdateExternalClientMTLSCertificateResponse,
  UpdateMTLSCertificateRequest,
  UpdateMTLSCertificateResponse,
} from "../../../../micashared/common/v1/mtls_certificate";

export const protobufPackage = "mica.discount.administration.v1";

export interface DiscountAdministrationService {
  /** Certificate authentication to call from a provider onto mica */
  generateMtlsCertificate(
    request: DeepPartial<GenerateMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GenerateMTLSCertificateResponse>;
  /** Update the certificate with a given serial number, only supports enable/disable for now */
  updateMtlsCertificate(
    request: DeepPartial<UpdateMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateMTLSCertificateResponse>;
  /** Search for certificates and return the ones that match the criteria provided */
  searchMtlsCertificate(
    request: DeepPartial<SearchMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchMTLSCertificateResponse>;
  getMtlsCertificate(
    request: DeepPartial<GetMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetMTLSCertificateResponse>;
  /** External authentication mechanisms for Mica to call provider endpoints */
  getExternalClientSettings(
    request: DeepPartial<GetExternalClientSettingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetExternalClientSettingsResponse>;
  updateExternalClientCallbackAddress(
    request: DeepPartial<UpdateExternalClientCallBackAddressRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateExternalClientCallBackAddressResponse>;
  /** Client certificates are used when mica needs to call out to a customers environment. */
  generateExternalClientMtlsCertificate(
    request: DeepPartial<GenerateExternalClientMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GenerateExternalClientMTLSCertificateResponse>;
  updateExternalClientMtlsCertificate(
    request: DeepPartial<UpdateExternalClientMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateExternalClientMTLSCertificateResponse>;
  searchExternalClientMtlsCertificate(
    request: DeepPartial<SearchExternalClientMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchExternalClientMTLSCertificateResponse>;
  /** creates the token configuration */
  createApiTokenConfiguration(
    request: DeepPartial<CreateApiTokenConfigurationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateApiTokenConfigurationResponse>;
  /**
   * if the authentication type is api token use these methods to properly setup the api token
   * retrieves the api token configuration including the token itself
   */
  getApiTokenConfiguration(
    request: DeepPartial<GetApiTokenConfigurationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetApiTokenConfigurationResponse>;
  /** Update the configuration of the token, the set fields will be modified */
  updateApiTokenAuthenticationConfiguration(
    request: DeepPartial<UpdateApiTokenConfigurationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateApiTokenConfigurationResponse>;
  /** tests the external call to verify proper configuration and connectivity */
  pingExternal(request: DeepPartial<PingRequest>, metadata?: grpc.Metadata): Promise<PingResponse>;
}

export class DiscountAdministrationServiceClientImpl implements DiscountAdministrationService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.generateMtlsCertificate = this.generateMtlsCertificate.bind(this);
    this.updateMtlsCertificate = this.updateMtlsCertificate.bind(this);
    this.searchMtlsCertificate = this.searchMtlsCertificate.bind(this);
    this.getMtlsCertificate = this.getMtlsCertificate.bind(this);
    this.getExternalClientSettings = this.getExternalClientSettings.bind(this);
    this.updateExternalClientCallbackAddress = this.updateExternalClientCallbackAddress.bind(this);
    this.generateExternalClientMtlsCertificate = this.generateExternalClientMtlsCertificate.bind(this);
    this.updateExternalClientMtlsCertificate = this.updateExternalClientMtlsCertificate.bind(this);
    this.searchExternalClientMtlsCertificate = this.searchExternalClientMtlsCertificate.bind(this);
    this.createApiTokenConfiguration = this.createApiTokenConfiguration.bind(this);
    this.getApiTokenConfiguration = this.getApiTokenConfiguration.bind(this);
    this.updateApiTokenAuthenticationConfiguration = this.updateApiTokenAuthenticationConfiguration.bind(this);
    this.pingExternal = this.pingExternal.bind(this);
  }

  generateMtlsCertificate(
    request: DeepPartial<GenerateMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GenerateMTLSCertificateResponse> {
    return this.rpc.unary(
      DiscountAdministrationServiceGenerateMTLSCertificateDesc,
      GenerateMTLSCertificateRequest.fromPartial(request),
      metadata,
    );
  }

  updateMtlsCertificate(
    request: DeepPartial<UpdateMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateMTLSCertificateResponse> {
    return this.rpc.unary(
      DiscountAdministrationServiceUpdateMTLSCertificateDesc,
      UpdateMTLSCertificateRequest.fromPartial(request),
      metadata,
    );
  }

  searchMtlsCertificate(
    request: DeepPartial<SearchMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchMTLSCertificateResponse> {
    return this.rpc.unary(
      DiscountAdministrationServiceSearchMTLSCertificateDesc,
      SearchMTLSCertificateRequest.fromPartial(request),
      metadata,
    );
  }

  getMtlsCertificate(
    request: DeepPartial<GetMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetMTLSCertificateResponse> {
    return this.rpc.unary(
      DiscountAdministrationServiceGetMTLSCertificateDesc,
      GetMTLSCertificateRequest.fromPartial(request),
      metadata,
    );
  }

  getExternalClientSettings(
    request: DeepPartial<GetExternalClientSettingsRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetExternalClientSettingsResponse> {
    return this.rpc.unary(
      DiscountAdministrationServiceGetExternalClientSettingsDesc,
      GetExternalClientSettingsRequest.fromPartial(request),
      metadata,
    );
  }

  updateExternalClientCallbackAddress(
    request: DeepPartial<UpdateExternalClientCallBackAddressRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateExternalClientCallBackAddressResponse> {
    return this.rpc.unary(
      DiscountAdministrationServiceUpdateExternalClientCallbackAddressDesc,
      UpdateExternalClientCallBackAddressRequest.fromPartial(request),
      metadata,
    );
  }

  generateExternalClientMtlsCertificate(
    request: DeepPartial<GenerateExternalClientMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GenerateExternalClientMTLSCertificateResponse> {
    return this.rpc.unary(
      DiscountAdministrationServiceGenerateExternalClientMTLSCertificateDesc,
      GenerateExternalClientMTLSCertificateRequest.fromPartial(request),
      metadata,
    );
  }

  updateExternalClientMtlsCertificate(
    request: DeepPartial<UpdateExternalClientMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateExternalClientMTLSCertificateResponse> {
    return this.rpc.unary(
      DiscountAdministrationServiceUpdateExternalClientMTLSCertificateDesc,
      UpdateExternalClientMTLSCertificateRequest.fromPartial(request),
      metadata,
    );
  }

  searchExternalClientMtlsCertificate(
    request: DeepPartial<SearchExternalClientMTLSCertificateRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchExternalClientMTLSCertificateResponse> {
    return this.rpc.unary(
      DiscountAdministrationServiceSearchExternalClientMTLSCertificateDesc,
      SearchExternalClientMTLSCertificateRequest.fromPartial(request),
      metadata,
    );
  }

  createApiTokenConfiguration(
    request: DeepPartial<CreateApiTokenConfigurationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateApiTokenConfigurationResponse> {
    return this.rpc.unary(
      DiscountAdministrationServiceCreateApiTokenConfigurationDesc,
      CreateApiTokenConfigurationRequest.fromPartial(request),
      metadata,
    );
  }

  getApiTokenConfiguration(
    request: DeepPartial<GetApiTokenConfigurationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetApiTokenConfigurationResponse> {
    return this.rpc.unary(
      DiscountAdministrationServiceGetApiTokenConfigurationDesc,
      GetApiTokenConfigurationRequest.fromPartial(request),
      metadata,
    );
  }

  updateApiTokenAuthenticationConfiguration(
    request: DeepPartial<UpdateApiTokenConfigurationRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateApiTokenConfigurationResponse> {
    return this.rpc.unary(
      DiscountAdministrationServiceUpdateAPITokenAuthenticationConfigurationDesc,
      UpdateApiTokenConfigurationRequest.fromPartial(request),
      metadata,
    );
  }

  pingExternal(request: DeepPartial<PingRequest>, metadata?: grpc.Metadata): Promise<PingResponse> {
    return this.rpc.unary(DiscountAdministrationServicePingExternalDesc, PingRequest.fromPartial(request), metadata);
  }
}

export const DiscountAdministrationServiceDesc = {
  serviceName: "mica.discount.administration.v1.DiscountAdministrationService",
};

export const DiscountAdministrationServiceGenerateMTLSCertificateDesc: UnaryMethodDefinitionish = {
  methodName: "GenerateMTLSCertificate",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GenerateMTLSCertificateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GenerateMTLSCertificateResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountAdministrationServiceUpdateMTLSCertificateDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateMTLSCertificate",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateMTLSCertificateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateMTLSCertificateResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountAdministrationServiceSearchMTLSCertificateDesc: UnaryMethodDefinitionish = {
  methodName: "SearchMTLSCertificate",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchMTLSCertificateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchMTLSCertificateResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountAdministrationServiceGetMTLSCertificateDesc: UnaryMethodDefinitionish = {
  methodName: "GetMTLSCertificate",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetMTLSCertificateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetMTLSCertificateResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountAdministrationServiceGetExternalClientSettingsDesc: UnaryMethodDefinitionish = {
  methodName: "GetExternalClientSettings",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetExternalClientSettingsRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetExternalClientSettingsResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountAdministrationServiceUpdateExternalClientCallbackAddressDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateExternalClientCallbackAddress",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateExternalClientCallBackAddressRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateExternalClientCallBackAddressResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountAdministrationServiceGenerateExternalClientMTLSCertificateDesc: UnaryMethodDefinitionish = {
  methodName: "GenerateExternalClientMTLSCertificate",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GenerateExternalClientMTLSCertificateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GenerateExternalClientMTLSCertificateResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountAdministrationServiceUpdateExternalClientMTLSCertificateDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateExternalClientMTLSCertificate",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateExternalClientMTLSCertificateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateExternalClientMTLSCertificateResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountAdministrationServiceSearchExternalClientMTLSCertificateDesc: UnaryMethodDefinitionish = {
  methodName: "SearchExternalClientMTLSCertificate",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchExternalClientMTLSCertificateRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchExternalClientMTLSCertificateResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountAdministrationServiceCreateApiTokenConfigurationDesc: UnaryMethodDefinitionish = {
  methodName: "CreateApiTokenConfiguration",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateApiTokenConfigurationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateApiTokenConfigurationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountAdministrationServiceGetApiTokenConfigurationDesc: UnaryMethodDefinitionish = {
  methodName: "GetApiTokenConfiguration",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetApiTokenConfigurationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetApiTokenConfigurationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountAdministrationServiceUpdateAPITokenAuthenticationConfigurationDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateAPITokenAuthenticationConfiguration",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateApiTokenConfigurationRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateApiTokenConfigurationResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountAdministrationServicePingExternalDesc: UnaryMethodDefinitionish = {
  methodName: "PingExternal",
  service: DiscountAdministrationServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
