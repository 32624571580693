import {
  MonetaryAmount,
  PercentageAmount,
} from 'mica-proto/src/mica/discount/discount/v1/discount';
import { ButtonGroup } from 'mica-ui-kit/src/components/inputs/buttons/ButtonGroup/ButtonGroup';
import MonetaryAmountForm from 'mica-ui-kit/src/components/layout/forms/MonetaryAmountForm/MonetaryAmountForm';
import PercentageAmountForm from 'mica-ui-kit/src/components/layout/forms/PercentageAmountForm/PercentageAmountForm';
import React, { useEffect, useState } from 'react';

const discountTypes = ['Monetary Discount', 'Percent Discount'];

type MonetaryPercentageOptionsProps = {
  isUpdate?: boolean;
  monetaryAmount?: MonetaryAmount;
  onSetMonetaryAmount: (amount?: Partial<MonetaryAmount>) => void;
  onSetPercentageAmount: (amount?: Partial<PercentageAmount>) => void;
  percentageAmount?: PercentageAmount;
};

/**
 A Component to switch between Monetary and Percentage inputs for a Discount/Definition
 */
const MonetaryPercentageOptions = ({
  isUpdate,
  monetaryAmount,
  onSetMonetaryAmount,
  onSetPercentageAmount,
  percentageAmount,
}: MonetaryPercentageOptionsProps) => {
  const [discountType, setDiscountType] = useState(
    percentageAmount?.percentageAmount ? discountTypes[1] : discountTypes[0]
  );

  useEffect(() => {
    discountType === discountTypes[0]
      ? onSetPercentageAmount(undefined)
      : onSetMonetaryAmount(undefined);
  }, [discountType]);

  return (
    <>
      {!isUpdate && (
        <ButtonGroup
          className={'mb-10'}
          options={discountTypes}
          selectedOption={discountType}
          onSelectionChange={setDiscountType}
        />
      )}
      {discountType === discountTypes[0] ? (
        <MonetaryAmountForm
          style={{ width: 320 }}
          amount={monetaryAmount || {}}
          onChange={onSetMonetaryAmount}
        />
      ) : (
        <PercentageAmountForm
          amount={percentageAmount || {}}
          onChange={onSetPercentageAmount}
        />
      )}
    </>
  );
};

export default MonetaryPercentageOptions;
