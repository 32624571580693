import styles from './ChartTile.module.css';
import { Card } from 'mica-ui-kit/src/components/consoles/layout/Card/Card';
import React, { PropsWithChildren } from 'react';

type ChartTileProps = {
  title: string;
};

/**
 A Container for our Dashboard charts
 */
const ChartTile = ({ children, title }: PropsWithChildren<ChartTileProps>) => {
  return (
    <Card title={title}>
      <div className={styles.chart}>{children}</div>
    </Card>
  );
};

export default ChartTile;
