import { Login } from '../../../consoles/screens/auth/Login/Login';
import { LoadingOverlay } from '../../../loading';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useBoolean } from 'mica-shared-react';
import React from 'react';
import { Outlet } from 'react-router-dom';

type OktaAuthRouteContainerProps = {
  appName: string;
};

/**
 A Container that wraps all of our authenticated routes, forcing unauthenticated users to login via Okta
 */
export const OktaAuthRouteContainer = ({
  appName,
}: OktaAuthRouteContainerProps) => {
  const { authState, oktaAuth } = useOktaAuth();
  const loading = useBoolean();

  const handleLogin = async () => {
    loading.setTrue();
    const originalUri = toRelativeUrl(
      window.location.href,
      window.location.origin
    );
    oktaAuth.setOriginalUri(originalUri);
    await oktaAuth.signInWithRedirect();
  };

  if (!authState) {
    return <LoadingOverlay />;
  }

  if (!authState?.isAuthenticated) {
    return (
      <Login
        appName={appName}
        loading={loading.value}
        onLoginClicked={handleLogin}
      />
    );
  }

  return <Outlet />;
};
