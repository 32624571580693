import { useOktaTenant } from '../../../../hooks/auth/okta/useOktaTenant';
import { InfoBox } from '../../../consoles/layout/text/InfoBox/InfoBox';
import { Button, TextInput } from '../../../inputs';
import styles from './OktaTenantRequired.module.css';
import React, { useState } from 'react';

type OktaTenantRequiredProps = {
  onTenantProvided?: () => void;
};

/**
 A Component to request a user provide their okta tenant, if we don't already have one
 from the start route or localStorage
 */
const OktaTenantRequired = ({ onTenantProvided }: OktaTenantRequiredProps) => {
  const [tenantInput, setTenantInput] = useState('');

  const [, setTenant] = useOktaTenant();

  const handleSetTenant = () => {
    setTenant(tenantInput);
    onTenantProvided?.();
  };

  return (
    <div className={styles.container}>
      <div>Enter the Mica-provided tenant ID for your organization</div>
      <InfoBox
        className={styles.infoBox}
        message={
          'Your tenant ID is a unique identifier for your firm, set by Mica. Contact your Mica administrator for this.'
        }
      />
      <TextInput
        inputClassName={styles.input}
        onChange={setTenantInput}
        onEnterPressed={handleSetTenant}
        value={tenantInput}
      />
      <Button title={'GO'} onClick={handleSetTenant} />
    </div>
  );
};

export default OktaTenantRequired;
