import { useDiscountClient } from '../../useDiscountClient/useDiscountClient';
import { useMutation, useQueryClient } from 'react-query';

/**
 * A hook for updated a certificate
 */
export const useUpdateMTLSCertificate = () => {
  const client = useDiscountClient();
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(client.updateMtlsCertificate, {
    onSuccess: () => {
      queryClient.invalidateQueries('searchMtlsCertificate');
    },
  });

  return {
    updateMTLSCertificate: mutate,
    ...rest,
  };
};
