/**
 * A hook for fetching the current Settings
 */
import { useDiscountClient } from '../../useDiscountClient/useDiscountClient';
import { useQuery } from 'react-query';

export const useApiTokenConfiguration = () => {
  const client = useDiscountClient();
  return useQuery(['getApiTokenConfiguration'], () =>
    client.getApiTokenConfiguration({})
  );
};
