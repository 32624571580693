import DiscountForm from '../../../shared/discounts/DiscountForm/DiscountForm';
import { useDiscount } from 'discount-client-react/src/client/discount/useDiscount/useDiscount';
import { useUpdateDiscount } from 'discount-client-react/src/client/discount/useUpdateDiscount/useUpdateDiscount';
import { useDiscountDefinition } from 'discount-client-react/src/client/discountDefinition/useDiscountDefinition/useDiscountDefinition';
import { UpdateDiscountRequest } from 'mica-proto/src/mica/discount/discount/v1/discount';
import { LoadingOverlay } from 'mica-ui-kit/src/components';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 Our screen for searching for DiscountProviderAccounts
 */
const Discount = () => {
  const discountRef = useParams().discountRef!;

  const { data, isLoading } = useDiscount({ discountRef });

  const { updateDiscount, isLoading: updateLoading } = useUpdateDiscount();

  const { data: definitionData, isLoading: definitionLoading } =
    useDiscountDefinition(
      { discountDefinitionRef: data?.discount?.discountDefinitionRef || '' },
      {
        enabled: !!data?.discount?.discountDefinitionRef,
      }
    );

  const handleUpdateDiscount = (discount: UpdateDiscountRequest) =>
    updateDiscount(discount, {
      onSuccess: () => {
        toast.success('Discount Updated');
      },
    });

  return (
    <Page title={`Discount: ${data?.discount?.discountRef}`}>
      {isLoading || definitionLoading ? (
        <LoadingOverlay />
      ) : (
        <DiscountForm
          definition={definitionData!.discountDefinition!}
          discount={data!.discount}
          onSave={handleUpdateDiscount}
          loading={updateLoading}
        />
      )}
    </Page>
  );
};

export default Discount;
