import { TextInput } from '../../../inputs';
import styles from './TextListInputAndTable.module.css';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { GrReturn } from '@react-icons/all-files/gr/GrReturn';
import { flatten, remove } from 'ramda';
import React, { useState } from 'react';

type TextListInputAndTableProps = {
  inputLabel?: string;
  onChange: (newList: string[]) => void;
  splitCSV?: boolean; // split comma separated values in input into separate items
  values: string[];
};

/**
 A form component for adding and removing string values to a list
 */
export const TextListInputAndTable = ({
  inputLabel = 'Add item',
  onChange,
  splitCSV,
  values,
}: TextListInputAndTableProps) => {
  const [input, setInput] = useState('');

  const handleRemoveItemAtIndex = (index: number) =>
    onChange(remove(index, 1, values));

  const handleAddEntry = () => {
    const newEntry = splitCSV
      ? flatten(input.split(',').map((i) => i.split(', ')))
      : [input];

    onChange([...values, ...newEntry]);
    setInput('');
  };

  return (
    <div className={styles.container}>
      <TextInput
        className={'mt-10'}
        onChange={setInput}
        onEnterPressed={handleAddEntry}
        value={input}
        label={inputLabel}
      />
      <button className={styles.enterButton} onClick={() => handleAddEntry()}>
        <GrReturn color={'#FFFFFF'} />
      </button>
      <table className={'table'} style={{ width: '100%' }}>
        <tbody>
          {values.map((val, i) => (
            <tr className={styles.tr} key={i}>
              <td
                className={styles.removeCell}
                onClick={() => handleRemoveItemAtIndex(i)}>
                <FaTimes />
              </td>
              <td className={styles.text}>{val}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
