import styles from './LoadingIndicator.module.css';
import { BiLoaderCircle } from '@react-icons/all-files/bi/BiLoaderCircle';
import cx from 'classnames';
import React from 'react';

type LoadingIndicatorProps = {
  className?: string;
  size?: number;
};

/**
 Our standard loading indicator - a spinny thing, cos thats real original
 */
export const LoadingIndicator = ({
  className,
  size = 15,
}: LoadingIndicatorProps) => {
  return (
    <BiLoaderCircle
      data-testid={'LoadingIndicator'}
      className={cx(styles.loadingIndicator, className)}
      size={size}
    />
  );
};
