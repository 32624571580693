import { useDiscountClient } from './useDiscountClient/useDiscountClient';
import { DiscountClient } from 'discount-client';
import { useQuery } from 'react-query';

type FilterConditionally<Source, Condition> = Pick<
  Source,
  { [K in keyof Source]: Source[K] extends Condition ? K : never }[keyof Source]
>;

export const useReactQuery =
  <
    K extends keyof FilterConditionally<
      DiscountClient,
      (args: A) => Promise<unknown>
    >,
    A = Parameters<DiscountClient[K]>[0]
  >(
    key: K
  ) =>
  (args: A, options?: any) => {
    const client = useDiscountClient();
    const fetch = async () => client[key](args as any);

    return useQuery<Awaited<ReturnType<DiscountClient[K]>>>(
      [key, args],
      fetch as any,
      // @ts-ignore:
      options
    );
  };
