/* eslint-disable */
import { grpc } from "@improbable-eng/grpc-web";
import { BrowserHeaders } from "browser-headers";
import { PingRequest, PingResponse } from "../../../../micashared/common/ping/v1/ping";
import {
  CreateDiscountRequest,
  CreateDiscountResponse,
  GetDiscountRequest,
  GetDiscountResponse,
  RemoveDiscountRequest,
  RemoveDiscountResponse,
  SearchDiscountRequest,
  SearchDiscountResponse,
  UpdateDiscountRequest,
  UpdateDiscountResponse,
  UpdateDiscountStatusRequest,
  UpdateDiscountStatusResponse,
} from "../../discount/v1/discount";
import {
  CreateDiscountDefinitionRequest,
  CreateDiscountDefinitionResponse,
  GetDiscountDefinitionRequest,
  GetDiscountDefinitionResponse,
  RemoveDiscountDefinitionRequest,
  RemoveDiscountDefinitionResponse,
  SearchDiscountDefinitionRequest,
  SearchDiscountDefinitionResponse,
  UpdateDiscountDefinitionRequest,
  UpdateDiscountDefinitionResponse,
  UpdateDiscountDefinitionStatusRequest,
  UpdateDiscountDefinitionStatusResponse,
} from "../../discountdefinition/v1/discount_definition";
import {
  GetDiscountProviderRequest,
  GetDiscountProviderResponse,
  UpdateDiscountProviderRequest,
  UpdateDiscountProviderResponse,
} from "../../discountprovider/v1/discount_provider";
import {
  CreateDiscountProviderAccountRequest,
  CreateDiscountProviderAccountResponse,
  GetDiscountProviderAccountRequest,
  GetDiscountProviderAccountResponse,
  RemoveDiscountProviderAccountRequest,
  RemoveDiscountProviderAccountResponse,
  SearchDiscountProviderAccountRequest,
  SearchDiscountProviderAccountResponse,
  UpdateDiscountProviderAccountRequest,
  UpdateDiscountProviderAccountResponse,
  UpdateDiscountProviderAccountStatusRequest,
  UpdateDiscountProviderAccountStatusResponse,
} from "../../discountprovideraccount/v1/discount_provider_account";
import {
  CreateProductGroupRequest,
  CreateProductGroupResponse,
  GetProductGroupRequest,
  GetProductGroupResponse,
  RemoveProductGroupRequest,
  RemoveProductGroupResponse,
  SearchProductGroupRequest,
  SearchProductGroupResponse,
  UpdateProductGroupRequest,
  UpdateProductGroupResponse,
} from "../../productgroup/v1/product_group";
import { GetUserKeyRequest, GetUserKeyResponse } from "../../user/v1/user";

export const protobufPackage = "mica.discount.service.v1";

/** <editor-fold desc="Discount Provider Operations"> */
export interface DiscountToMicaService {
  getDiscountProvider(
    request: DeepPartial<GetDiscountProviderRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetDiscountProviderResponse>;
  updateDiscountProvider(
    request: DeepPartial<UpdateDiscountProviderRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountProviderResponse>;
  createDiscountProviderAccount(
    request: DeepPartial<CreateDiscountProviderAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateDiscountProviderAccountResponse>;
  getDiscountProviderAccount(
    request: DeepPartial<GetDiscountProviderAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetDiscountProviderAccountResponse>;
  updateDiscountProviderAccount(
    request: DeepPartial<UpdateDiscountProviderAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountProviderAccountResponse>;
  updateDiscountProviderAccountStatus(
    request: DeepPartial<UpdateDiscountProviderAccountStatusRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountProviderAccountStatusResponse>;
  removeDiscountProviderAccount(
    request: DeepPartial<RemoveDiscountProviderAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveDiscountProviderAccountResponse>;
  searchDiscountProviderAccount(
    request: DeepPartial<SearchDiscountProviderAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchDiscountProviderAccountResponse>;
  createProductGroup(
    request: DeepPartial<CreateProductGroupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateProductGroupResponse>;
  getProductGroup(
    request: DeepPartial<GetProductGroupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetProductGroupResponse>;
  updateProductGroup(
    request: DeepPartial<UpdateProductGroupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateProductGroupResponse>;
  removeProductGroup(
    request: DeepPartial<RemoveProductGroupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveProductGroupResponse>;
  searchProductGroup(
    request: DeepPartial<SearchProductGroupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchProductGroupResponse>;
  createDiscountDefinition(
    request: DeepPartial<CreateDiscountDefinitionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateDiscountDefinitionResponse>;
  getDiscountDefinition(
    request: DeepPartial<GetDiscountDefinitionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetDiscountDefinitionResponse>;
  updateDiscountDefinition(
    request: DeepPartial<UpdateDiscountDefinitionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountDefinitionResponse>;
  updateDiscountDefinitionStatus(
    request: DeepPartial<UpdateDiscountDefinitionStatusRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountDefinitionStatusResponse>;
  removeDiscountDefinition(
    request: DeepPartial<RemoveDiscountDefinitionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveDiscountDefinitionResponse>;
  searchDiscountDefinition(
    request: DeepPartial<SearchDiscountDefinitionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchDiscountDefinitionResponse>;
  createDiscount(
    request: DeepPartial<CreateDiscountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateDiscountResponse>;
  getDiscount(request: DeepPartial<GetDiscountRequest>, metadata?: grpc.Metadata): Promise<GetDiscountResponse>;
  updateDiscount(
    request: DeepPartial<UpdateDiscountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountResponse>;
  updateDiscountStatus(
    request: DeepPartial<UpdateDiscountStatusRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountStatusResponse>;
  removeDiscount(
    request: DeepPartial<RemoveDiscountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveDiscountResponse>;
  searchDiscount(
    request: DeepPartial<SearchDiscountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchDiscountResponse>;
  getUserKey(request: DeepPartial<GetUserKeyRequest>, metadata?: grpc.Metadata): Promise<GetUserKeyResponse>;
  /** An operation to ping the server to ensure it's up and running and that the connection is good. */
  ping(request: DeepPartial<PingRequest>, metadata?: grpc.Metadata): Promise<PingResponse>;
  /**
   * An operation that triggers a ping to the Mica servervice and that in turn triggers a ping to the discount provider service.
   * This is a useful call to ensure that the connectivity and security between the mica an external services is working.
   */
  pingWithRoundTrip(request: DeepPartial<PingRequest>, metadata?: grpc.Metadata): Promise<PingResponse>;
}

export class DiscountToMicaServiceClientImpl implements DiscountToMicaService {
  private readonly rpc: Rpc;

  constructor(rpc: Rpc) {
    this.rpc = rpc;
    this.getDiscountProvider = this.getDiscountProvider.bind(this);
    this.updateDiscountProvider = this.updateDiscountProvider.bind(this);
    this.createDiscountProviderAccount = this.createDiscountProviderAccount.bind(this);
    this.getDiscountProviderAccount = this.getDiscountProviderAccount.bind(this);
    this.updateDiscountProviderAccount = this.updateDiscountProviderAccount.bind(this);
    this.updateDiscountProviderAccountStatus = this.updateDiscountProviderAccountStatus.bind(this);
    this.removeDiscountProviderAccount = this.removeDiscountProviderAccount.bind(this);
    this.searchDiscountProviderAccount = this.searchDiscountProviderAccount.bind(this);
    this.createProductGroup = this.createProductGroup.bind(this);
    this.getProductGroup = this.getProductGroup.bind(this);
    this.updateProductGroup = this.updateProductGroup.bind(this);
    this.removeProductGroup = this.removeProductGroup.bind(this);
    this.searchProductGroup = this.searchProductGroup.bind(this);
    this.createDiscountDefinition = this.createDiscountDefinition.bind(this);
    this.getDiscountDefinition = this.getDiscountDefinition.bind(this);
    this.updateDiscountDefinition = this.updateDiscountDefinition.bind(this);
    this.updateDiscountDefinitionStatus = this.updateDiscountDefinitionStatus.bind(this);
    this.removeDiscountDefinition = this.removeDiscountDefinition.bind(this);
    this.searchDiscountDefinition = this.searchDiscountDefinition.bind(this);
    this.createDiscount = this.createDiscount.bind(this);
    this.getDiscount = this.getDiscount.bind(this);
    this.updateDiscount = this.updateDiscount.bind(this);
    this.updateDiscountStatus = this.updateDiscountStatus.bind(this);
    this.removeDiscount = this.removeDiscount.bind(this);
    this.searchDiscount = this.searchDiscount.bind(this);
    this.getUserKey = this.getUserKey.bind(this);
    this.ping = this.ping.bind(this);
    this.pingWithRoundTrip = this.pingWithRoundTrip.bind(this);
  }

  getDiscountProvider(
    request: DeepPartial<GetDiscountProviderRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetDiscountProviderResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceGetDiscountProviderDesc,
      GetDiscountProviderRequest.fromPartial(request),
      metadata,
    );
  }

  updateDiscountProvider(
    request: DeepPartial<UpdateDiscountProviderRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountProviderResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceUpdateDiscountProviderDesc,
      UpdateDiscountProviderRequest.fromPartial(request),
      metadata,
    );
  }

  createDiscountProviderAccount(
    request: DeepPartial<CreateDiscountProviderAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateDiscountProviderAccountResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceCreateDiscountProviderAccountDesc,
      CreateDiscountProviderAccountRequest.fromPartial(request),
      metadata,
    );
  }

  getDiscountProviderAccount(
    request: DeepPartial<GetDiscountProviderAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetDiscountProviderAccountResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceGetDiscountProviderAccountDesc,
      GetDiscountProviderAccountRequest.fromPartial(request),
      metadata,
    );
  }

  updateDiscountProviderAccount(
    request: DeepPartial<UpdateDiscountProviderAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountProviderAccountResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceUpdateDiscountProviderAccountDesc,
      UpdateDiscountProviderAccountRequest.fromPartial(request),
      metadata,
    );
  }

  updateDiscountProviderAccountStatus(
    request: DeepPartial<UpdateDiscountProviderAccountStatusRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountProviderAccountStatusResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceUpdateDiscountProviderAccountStatusDesc,
      UpdateDiscountProviderAccountStatusRequest.fromPartial(request),
      metadata,
    );
  }

  removeDiscountProviderAccount(
    request: DeepPartial<RemoveDiscountProviderAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveDiscountProviderAccountResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceRemoveDiscountProviderAccountDesc,
      RemoveDiscountProviderAccountRequest.fromPartial(request),
      metadata,
    );
  }

  searchDiscountProviderAccount(
    request: DeepPartial<SearchDiscountProviderAccountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchDiscountProviderAccountResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceSearchDiscountProviderAccountDesc,
      SearchDiscountProviderAccountRequest.fromPartial(request),
      metadata,
    );
  }

  createProductGroup(
    request: DeepPartial<CreateProductGroupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateProductGroupResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceCreateProductGroupDesc,
      CreateProductGroupRequest.fromPartial(request),
      metadata,
    );
  }

  getProductGroup(
    request: DeepPartial<GetProductGroupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetProductGroupResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceGetProductGroupDesc,
      GetProductGroupRequest.fromPartial(request),
      metadata,
    );
  }

  updateProductGroup(
    request: DeepPartial<UpdateProductGroupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateProductGroupResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceUpdateProductGroupDesc,
      UpdateProductGroupRequest.fromPartial(request),
      metadata,
    );
  }

  removeProductGroup(
    request: DeepPartial<RemoveProductGroupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveProductGroupResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceRemoveProductGroupDesc,
      RemoveProductGroupRequest.fromPartial(request),
      metadata,
    );
  }

  searchProductGroup(
    request: DeepPartial<SearchProductGroupRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchProductGroupResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceSearchProductGroupDesc,
      SearchProductGroupRequest.fromPartial(request),
      metadata,
    );
  }

  createDiscountDefinition(
    request: DeepPartial<CreateDiscountDefinitionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateDiscountDefinitionResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceCreateDiscountDefinitionDesc,
      CreateDiscountDefinitionRequest.fromPartial(request),
      metadata,
    );
  }

  getDiscountDefinition(
    request: DeepPartial<GetDiscountDefinitionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<GetDiscountDefinitionResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceGetDiscountDefinitionDesc,
      GetDiscountDefinitionRequest.fromPartial(request),
      metadata,
    );
  }

  updateDiscountDefinition(
    request: DeepPartial<UpdateDiscountDefinitionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountDefinitionResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceUpdateDiscountDefinitionDesc,
      UpdateDiscountDefinitionRequest.fromPartial(request),
      metadata,
    );
  }

  updateDiscountDefinitionStatus(
    request: DeepPartial<UpdateDiscountDefinitionStatusRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountDefinitionStatusResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceUpdateDiscountDefinitionStatusDesc,
      UpdateDiscountDefinitionStatusRequest.fromPartial(request),
      metadata,
    );
  }

  removeDiscountDefinition(
    request: DeepPartial<RemoveDiscountDefinitionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveDiscountDefinitionResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceRemoveDiscountDefinitionDesc,
      RemoveDiscountDefinitionRequest.fromPartial(request),
      metadata,
    );
  }

  searchDiscountDefinition(
    request: DeepPartial<SearchDiscountDefinitionRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchDiscountDefinitionResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceSearchDiscountDefinitionDesc,
      SearchDiscountDefinitionRequest.fromPartial(request),
      metadata,
    );
  }

  createDiscount(
    request: DeepPartial<CreateDiscountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<CreateDiscountResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceCreateDiscountDesc,
      CreateDiscountRequest.fromPartial(request),
      metadata,
    );
  }

  getDiscount(request: DeepPartial<GetDiscountRequest>, metadata?: grpc.Metadata): Promise<GetDiscountResponse> {
    return this.rpc.unary(DiscountToMicaServiceGetDiscountDesc, GetDiscountRequest.fromPartial(request), metadata);
  }

  updateDiscount(
    request: DeepPartial<UpdateDiscountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceUpdateDiscountDesc,
      UpdateDiscountRequest.fromPartial(request),
      metadata,
    );
  }

  updateDiscountStatus(
    request: DeepPartial<UpdateDiscountStatusRequest>,
    metadata?: grpc.Metadata,
  ): Promise<UpdateDiscountStatusResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceUpdateDiscountStatusDesc,
      UpdateDiscountStatusRequest.fromPartial(request),
      metadata,
    );
  }

  removeDiscount(
    request: DeepPartial<RemoveDiscountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<RemoveDiscountResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceRemoveDiscountDesc,
      RemoveDiscountRequest.fromPartial(request),
      metadata,
    );
  }

  searchDiscount(
    request: DeepPartial<SearchDiscountRequest>,
    metadata?: grpc.Metadata,
  ): Promise<SearchDiscountResponse> {
    return this.rpc.unary(
      DiscountToMicaServiceSearchDiscountDesc,
      SearchDiscountRequest.fromPartial(request),
      metadata,
    );
  }

  getUserKey(request: DeepPartial<GetUserKeyRequest>, metadata?: grpc.Metadata): Promise<GetUserKeyResponse> {
    return this.rpc.unary(DiscountToMicaServiceGetUserKeyDesc, GetUserKeyRequest.fromPartial(request), metadata);
  }

  ping(request: DeepPartial<PingRequest>, metadata?: grpc.Metadata): Promise<PingResponse> {
    return this.rpc.unary(DiscountToMicaServicePingDesc, PingRequest.fromPartial(request), metadata);
  }

  pingWithRoundTrip(request: DeepPartial<PingRequest>, metadata?: grpc.Metadata): Promise<PingResponse> {
    return this.rpc.unary(DiscountToMicaServicePingWithRoundTripDesc, PingRequest.fromPartial(request), metadata);
  }
}

export const DiscountToMicaServiceDesc = { serviceName: "mica.discount.service.v1.DiscountToMicaService" };

export const DiscountToMicaServiceGetDiscountProviderDesc: UnaryMethodDefinitionish = {
  methodName: "GetDiscountProvider",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDiscountProviderRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetDiscountProviderResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceUpdateDiscountProviderDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateDiscountProvider",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateDiscountProviderRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateDiscountProviderResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceCreateDiscountProviderAccountDesc: UnaryMethodDefinitionish = {
  methodName: "CreateDiscountProviderAccount",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateDiscountProviderAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateDiscountProviderAccountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceGetDiscountProviderAccountDesc: UnaryMethodDefinitionish = {
  methodName: "GetDiscountProviderAccount",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDiscountProviderAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetDiscountProviderAccountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceUpdateDiscountProviderAccountDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateDiscountProviderAccount",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateDiscountProviderAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateDiscountProviderAccountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceUpdateDiscountProviderAccountStatusDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateDiscountProviderAccountStatus",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateDiscountProviderAccountStatusRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateDiscountProviderAccountStatusResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceRemoveDiscountProviderAccountDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveDiscountProviderAccount",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveDiscountProviderAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveDiscountProviderAccountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceSearchDiscountProviderAccountDesc: UnaryMethodDefinitionish = {
  methodName: "SearchDiscountProviderAccount",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchDiscountProviderAccountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchDiscountProviderAccountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceCreateProductGroupDesc: UnaryMethodDefinitionish = {
  methodName: "CreateProductGroup",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateProductGroupRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateProductGroupResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceGetProductGroupDesc: UnaryMethodDefinitionish = {
  methodName: "GetProductGroup",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetProductGroupRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetProductGroupResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceUpdateProductGroupDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateProductGroup",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateProductGroupRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateProductGroupResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceRemoveProductGroupDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveProductGroup",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveProductGroupRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveProductGroupResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceSearchProductGroupDesc: UnaryMethodDefinitionish = {
  methodName: "SearchProductGroup",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchProductGroupRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchProductGroupResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceCreateDiscountDefinitionDesc: UnaryMethodDefinitionish = {
  methodName: "CreateDiscountDefinition",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateDiscountDefinitionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateDiscountDefinitionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceGetDiscountDefinitionDesc: UnaryMethodDefinitionish = {
  methodName: "GetDiscountDefinition",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDiscountDefinitionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetDiscountDefinitionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceUpdateDiscountDefinitionDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateDiscountDefinition",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateDiscountDefinitionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateDiscountDefinitionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceUpdateDiscountDefinitionStatusDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateDiscountDefinitionStatus",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateDiscountDefinitionStatusRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateDiscountDefinitionStatusResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceRemoveDiscountDefinitionDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveDiscountDefinition",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveDiscountDefinitionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveDiscountDefinitionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceSearchDiscountDefinitionDesc: UnaryMethodDefinitionish = {
  methodName: "SearchDiscountDefinition",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchDiscountDefinitionRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchDiscountDefinitionResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceCreateDiscountDesc: UnaryMethodDefinitionish = {
  methodName: "CreateDiscount",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return CreateDiscountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = CreateDiscountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceGetDiscountDesc: UnaryMethodDefinitionish = {
  methodName: "GetDiscount",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetDiscountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetDiscountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceUpdateDiscountDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateDiscount",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateDiscountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateDiscountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceUpdateDiscountStatusDesc: UnaryMethodDefinitionish = {
  methodName: "UpdateDiscountStatus",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return UpdateDiscountStatusRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = UpdateDiscountStatusResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceRemoveDiscountDesc: UnaryMethodDefinitionish = {
  methodName: "RemoveDiscount",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return RemoveDiscountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = RemoveDiscountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceSearchDiscountDesc: UnaryMethodDefinitionish = {
  methodName: "SearchDiscount",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return SearchDiscountRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = SearchDiscountResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServiceGetUserKeyDesc: UnaryMethodDefinitionish = {
  methodName: "GetUserKey",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return GetUserKeyRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = GetUserKeyResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServicePingDesc: UnaryMethodDefinitionish = {
  methodName: "Ping",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

export const DiscountToMicaServicePingWithRoundTripDesc: UnaryMethodDefinitionish = {
  methodName: "PingWithRoundTrip",
  service: DiscountToMicaServiceDesc,
  requestStream: false,
  responseStream: false,
  requestType: {
    serializeBinary() {
      return PingRequest.encode(this).finish();
    },
  } as any,
  responseType: {
    deserializeBinary(data: Uint8Array) {
      const value = PingResponse.decode(data);
      return {
        ...value,
        toObject() {
          return value;
        },
      };
    },
  } as any,
};

interface UnaryMethodDefinitionishR extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

interface Rpc {
  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any>;
}

export class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;

    debug?: boolean;
    metadata?: grpc.Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;

      debug?: boolean;
      metadata?: grpc.Metadata;
      upStreamRetryCodes?: number[];
    },
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata: grpc.Metadata | undefined,
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };
    const maybeCombinedMetadata = metadata && this.options.metadata
      ? new BrowserHeaders({ ...this.options?.metadata.headersMap, ...metadata?.headersMap })
      : metadata || this.options.metadata;
    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response) {
          if (response.status === grpc.Code.OK) {
            resolve(response.message!.toObject());
          } else {
            const err = new GrpcWebError(response.statusMessage, response.status, response.trailers);
            reject(err);
          }
        },
      });
    });
  }
}

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

export class GrpcWebError extends tsProtoGlobalThis.Error {
  constructor(message: string, public code: grpc.Code, public metadata: grpc.Metadata) {
    super(message);
  }
}
