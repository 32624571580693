/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { LineItemStatus } from "../enums/lineitemstatus/v1/line_item_status";
import { Unit } from "../enums/unit/v1/unit";

export const protobufPackage = "micashared.common.v1";

export enum AdjustmentType {
  ADJUSTMENT_TYPE_UNSPECIFIED = 0,
  ADJUSTMENT_TYPE_MANUFACTURER_DISCOUNT = 1,
  ADJUSTMENT_TYPE_STORE_DISCOUNT = 2,
  ADJUSTMENT_TYPE_RETURN = 3,
  ADJUSTMENT_TYPE_PAYMENT_CASH = 4,
  ADJUSTMENT_TYPE_PAYMENT_GIFT_CARD = 5,
  ADJUSTMENT_TYPE_PAYMENT_OTHER = 6,
}

export interface LineItem {
  /** unique number in a list of items that can be used to refer to a specific item */
  sequence: number;
  /** a header label that is shown on a receipt to group line items */
  lineItemGroup: string;
  productCode: string;
  description: string;
  /** an integer or decimal number */
  quantity: string;
  unit: Unit;
  unitAmount: string;
  unitTaxAmount: string;
  /**
   * The total amount for this line item excluding tax.
   * Amount expressed as: [+-]?([0-9]*[.])?[0-9]+
   */
  lineAmount: string;
  /**
   * The amount of tax for this line item.
   * Amount expressed as: [+-]?([0-9]*[.])?[0-9]+
   */
  lineTaxAmount: string;
}

export interface LineItemWrapper {
  lineItems: LineItem[];
}

export interface Adjustment {
  /** unique number in a list of items that can be used to refer to a specific item */
  sequence: number;
  adjustmentType: AdjustmentType;
  productCode: string;
  description: string;
  /** an integer or decimal number */
  quantity: string;
  unit: Unit;
  unitAmount: string;
  unitTaxAmount: string;
  /**
   * The total amount for this line item excluding tax.
   * Amount expressed as: [+-]?([0-9]*[.])?[0-9]+
   */
  lineAmount: string;
  /**
   * The amount of tax for this line item.
   * Amount expressed as: [+-]?([0-9]*[.])?[0-9]+
   */
  lineTaxAmount: string;
}

export interface LineItemSequenceAndStatus {
  /** unique number in a list of items that can be used to refer to a specific item */
  sequence: number;
  status: LineItemStatus;
}

export interface LineItemSequenceAndStatusValue {
  lineItemSequenceAndStatuses: LineItemSequenceAndStatus[];
}

export interface LineItemAndStatus {
  lineItem: LineItem | undefined;
  status: LineItemStatus;
}

export interface LineItemResponseValue {
  lineItemAndStatuses: LineItemAndStatus[];
}

function createBaseLineItem(): LineItem {
  return {
    sequence: 0,
    lineItemGroup: "",
    productCode: "",
    description: "",
    quantity: "",
    unit: 0,
    unitAmount: "",
    unitTaxAmount: "",
    lineAmount: "",
    lineTaxAmount: "",
  };
}

export const LineItem = {
  encode(message: LineItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sequence !== 0) {
      writer.uint32(8).int32(message.sequence);
    }
    if (message.lineItemGroup !== "") {
      writer.uint32(18).string(message.lineItemGroup);
    }
    if (message.productCode !== "") {
      writer.uint32(26).string(message.productCode);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.quantity !== "") {
      writer.uint32(42).string(message.quantity);
    }
    if (message.unit !== 0) {
      writer.uint32(48).int32(message.unit);
    }
    if (message.unitAmount !== "") {
      writer.uint32(58).string(message.unitAmount);
    }
    if (message.unitTaxAmount !== "") {
      writer.uint32(66).string(message.unitTaxAmount);
    }
    if (message.lineAmount !== "") {
      writer.uint32(74).string(message.lineAmount);
    }
    if (message.lineTaxAmount !== "") {
      writer.uint32(82).string(message.lineTaxAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LineItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLineItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sequence = reader.int32();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.lineItemGroup = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.productCode = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.quantity = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.unit = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.unitAmount = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.unitTaxAmount = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.lineAmount = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.lineTaxAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LineItem>, I>>(base?: I): LineItem {
    return LineItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LineItem>, I>>(object: I): LineItem {
    const message = createBaseLineItem();
    message.sequence = object.sequence ?? 0;
    message.lineItemGroup = object.lineItemGroup ?? "";
    message.productCode = object.productCode ?? "";
    message.description = object.description ?? "";
    message.quantity = object.quantity ?? "";
    message.unit = object.unit ?? 0;
    message.unitAmount = object.unitAmount ?? "";
    message.unitTaxAmount = object.unitTaxAmount ?? "";
    message.lineAmount = object.lineAmount ?? "";
    message.lineTaxAmount = object.lineTaxAmount ?? "";
    return message;
  },
};

function createBaseLineItemWrapper(): LineItemWrapper {
  return { lineItems: [] };
}

export const LineItemWrapper = {
  encode(message: LineItemWrapper, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.lineItems) {
      LineItem.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LineItemWrapper {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLineItemWrapper();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lineItems.push(LineItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LineItemWrapper>, I>>(base?: I): LineItemWrapper {
    return LineItemWrapper.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LineItemWrapper>, I>>(object: I): LineItemWrapper {
    const message = createBaseLineItemWrapper();
    message.lineItems = object.lineItems?.map((e) => LineItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseAdjustment(): Adjustment {
  return {
    sequence: 0,
    adjustmentType: 0,
    productCode: "",
    description: "",
    quantity: "",
    unit: 0,
    unitAmount: "",
    unitTaxAmount: "",
    lineAmount: "",
    lineTaxAmount: "",
  };
}

export const Adjustment = {
  encode(message: Adjustment, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sequence !== 0) {
      writer.uint32(8).int32(message.sequence);
    }
    if (message.adjustmentType !== 0) {
      writer.uint32(16).int32(message.adjustmentType);
    }
    if (message.productCode !== "") {
      writer.uint32(26).string(message.productCode);
    }
    if (message.description !== "") {
      writer.uint32(34).string(message.description);
    }
    if (message.quantity !== "") {
      writer.uint32(42).string(message.quantity);
    }
    if (message.unit !== 0) {
      writer.uint32(48).int32(message.unit);
    }
    if (message.unitAmount !== "") {
      writer.uint32(58).string(message.unitAmount);
    }
    if (message.unitTaxAmount !== "") {
      writer.uint32(66).string(message.unitTaxAmount);
    }
    if (message.lineAmount !== "") {
      writer.uint32(74).string(message.lineAmount);
    }
    if (message.lineTaxAmount !== "") {
      writer.uint32(82).string(message.lineTaxAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): Adjustment {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAdjustment();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sequence = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.adjustmentType = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.productCode = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.description = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.quantity = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.unit = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.unitAmount = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.unitTaxAmount = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.lineAmount = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.lineTaxAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<Adjustment>, I>>(base?: I): Adjustment {
    return Adjustment.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<Adjustment>, I>>(object: I): Adjustment {
    const message = createBaseAdjustment();
    message.sequence = object.sequence ?? 0;
    message.adjustmentType = object.adjustmentType ?? 0;
    message.productCode = object.productCode ?? "";
    message.description = object.description ?? "";
    message.quantity = object.quantity ?? "";
    message.unit = object.unit ?? 0;
    message.unitAmount = object.unitAmount ?? "";
    message.unitTaxAmount = object.unitTaxAmount ?? "";
    message.lineAmount = object.lineAmount ?? "";
    message.lineTaxAmount = object.lineTaxAmount ?? "";
    return message;
  },
};

function createBaseLineItemSequenceAndStatus(): LineItemSequenceAndStatus {
  return { sequence: 0, status: 0 };
}

export const LineItemSequenceAndStatus = {
  encode(message: LineItemSequenceAndStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.sequence !== 0) {
      writer.uint32(8).int32(message.sequence);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LineItemSequenceAndStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLineItemSequenceAndStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.sequence = reader.int32();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LineItemSequenceAndStatus>, I>>(base?: I): LineItemSequenceAndStatus {
    return LineItemSequenceAndStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LineItemSequenceAndStatus>, I>>(object: I): LineItemSequenceAndStatus {
    const message = createBaseLineItemSequenceAndStatus();
    message.sequence = object.sequence ?? 0;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseLineItemSequenceAndStatusValue(): LineItemSequenceAndStatusValue {
  return { lineItemSequenceAndStatuses: [] };
}

export const LineItemSequenceAndStatusValue = {
  encode(message: LineItemSequenceAndStatusValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.lineItemSequenceAndStatuses) {
      LineItemSequenceAndStatus.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LineItemSequenceAndStatusValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLineItemSequenceAndStatusValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lineItemSequenceAndStatuses.push(LineItemSequenceAndStatus.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LineItemSequenceAndStatusValue>, I>>(base?: I): LineItemSequenceAndStatusValue {
    return LineItemSequenceAndStatusValue.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LineItemSequenceAndStatusValue>, I>>(
    object: I,
  ): LineItemSequenceAndStatusValue {
    const message = createBaseLineItemSequenceAndStatusValue();
    message.lineItemSequenceAndStatuses =
      object.lineItemSequenceAndStatuses?.map((e) => LineItemSequenceAndStatus.fromPartial(e)) || [];
    return message;
  },
};

function createBaseLineItemAndStatus(): LineItemAndStatus {
  return { lineItem: undefined, status: 0 };
}

export const LineItemAndStatus = {
  encode(message: LineItemAndStatus, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.lineItem !== undefined) {
      LineItem.encode(message.lineItem, writer.uint32(10).fork()).ldelim();
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LineItemAndStatus {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLineItemAndStatus();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lineItem = LineItem.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LineItemAndStatus>, I>>(base?: I): LineItemAndStatus {
    return LineItemAndStatus.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LineItemAndStatus>, I>>(object: I): LineItemAndStatus {
    const message = createBaseLineItemAndStatus();
    message.lineItem = (object.lineItem !== undefined && object.lineItem !== null)
      ? LineItem.fromPartial(object.lineItem)
      : undefined;
    message.status = object.status ?? 0;
    return message;
  },
};

function createBaseLineItemResponseValue(): LineItemResponseValue {
  return { lineItemAndStatuses: [] };
}

export const LineItemResponseValue = {
  encode(message: LineItemResponseValue, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    for (const v of message.lineItemAndStatuses) {
      LineItemAndStatus.encode(v!, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): LineItemResponseValue {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseLineItemResponseValue();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.lineItemAndStatuses.push(LineItemAndStatus.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<LineItemResponseValue>, I>>(base?: I): LineItemResponseValue {
    return LineItemResponseValue.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<LineItemResponseValue>, I>>(object: I): LineItemResponseValue {
    const message = createBaseLineItemResponseValue();
    message.lineItemAndStatuses = object.lineItemAndStatuses?.map((e) => LineItemAndStatus.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
