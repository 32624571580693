import { useDiscountClient } from '../../useDiscountClient/useDiscountClient';
import { useMutation, useQueryClient } from 'react-query';

export const useUpdateDiscountProviderAccount = () => {
  const client = useDiscountClient();
  const queryClient = useQueryClient();

  const { mutate: updateDiscountProviderAccount, ...rest } = useMutation(
    client.updateDiscountProviderAccount,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('searchDiscountProviderAccount');
      },
    }
  );

  return {
    updateDiscountProviderAccount,
    ...rest,
  };
};
