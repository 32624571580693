import IconMessageBox, {
  IconMessageBoxProps,
} from '../IconMessageBox/IconMessageBox';
import styles from './WarningBox.module.css';
import { FaExclamationTriangle } from '@react-icons/all-files/fa/FaExclamationTriangle';
import cx from 'classnames';
import React, { PropsWithChildren } from 'react';

/**
 A standard warning box with '!' icon
 */
export const WarningBox = ({
  className,
  ...props
}: PropsWithChildren<Omit<IconMessageBoxProps, 'icon'>>) => (
  <IconMessageBox
    className={cx(styles.warningBox, className)}
    icon={<FaExclamationTriangle size={18} />}
    {...props}
  />
);
