export const cssColors = {
  primary: '#ABC123',
  accent: '#123456',
};

export const initializeColorsFromCssVariables = () => {
  for (const color in cssColors) {
    const cssValue = getComputedStyle(
      document.documentElement
    ).getPropertyValue(`--color-${color}`);
    cssColors[color as keyof typeof cssColors] = cssValue;
  }
};
