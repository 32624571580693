import { useGetMTLSCertificate } from 'discount-client-react/src/client/certs/useGetMTLSCertificate/useGetMTLSCertificate';
import { useUpdateMTLSCertificate } from 'discount-client-react/src/client/certs/useUpdateMTLSCertificate/useUpdateMTLSCertificate';
import { LoadingOverlay } from 'mica-ui-kit/src/components';
import MtlsCert from 'mica-ui-kit/src/components/consoles/screens/settings/network/certs/MtlsCert/MtlsCert';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

const mtlsCertificateRoleOptions = [
  {
    label: 'Administrator',
    value: 'RoleDiscountHumanAdmin',
  },
  {
    label: 'View Only',
    value: 'RoleDiscountHumanAdminViewer',
  },
  {
    label: 'Admin Service Account',
    value: 'RoleDiscountAdminServiceAccount',
  },
  {
    label: 'External Service Account',
    value: 'RoleDiscountExternalServiceAccount',
  },
  {
    label: 'Internal Service Account',
    value: 'RoleDiscountInternalServiceAccount',
  },
];

/**
 Our screen to display/update a MTLS cert
 */
const MtlsCertificate = () => {
  const serialNumber = useParams().serialNumber!;

  const { data } = useGetMTLSCertificate({
    serialNumber,
  });

  const { updateMTLSCertificate, isLoading } = useUpdateMTLSCertificate();

  const roles = useMemo(
    () =>
      (data?.certificate?.roles || []).map(
        (role) =>
          mtlsCertificateRoleOptions.find((r) => r.value === role)!.label
      ),
    [data]
  );

  return data?.certificate ? (
    <MtlsCert
      mtlsCertificate={data.certificate}
      loading={isLoading}
      onUpdateCertificate={updateMTLSCertificate}
      roles={roles}
    />
  ) : (
    <LoadingOverlay />
  );
};

export default MtlsCertificate;
