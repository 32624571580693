import DiscountCriteriaTiles from '../../definitions/DiscountCriteriaTiles/DiscountCriteriaTiles';
import styles from './DiscountForm.module.css';
import InputOverride from './InputOverride/InputOverride';
import UserKeyLookupModal from './UserKeyLookupModal/UserKeyLookupModal';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import dayjs from 'dayjs';
import {
  CreateDiscountRequest,
  DiscountCriteria,
  DiscountStatus,
} from 'mica-proto/src/mica/discount/discount/v1/discount';
import { DiscountDefinition } from 'mica-proto/src/mica/discount/discountdefinition/v1/discount_definition';
import { useBoolean } from 'mica-shared-react/src/hooks/useBoolean';
import { useCreateUpdateForm } from 'mica-shared-react/src/hooks/useCreateUpdateForm';
import { LoadingButton, Select, TextInput } from 'mica-ui-kit/src/components';
import { enumAsSelectOptions } from 'mica-ui-kit/src/utils/formatters/enums';
import { Card } from 'mica-ui-kit/src/components/consoles/layout/Card/Card';
import { DatePicker } from 'mica-ui-kit/src/components/inputs/DatePicker/DatePicker';
import MonetaryAmountForm from 'mica-ui-kit/src/components/layout/forms/MonetaryAmountForm/MonetaryAmountForm';
import PercentageAmountForm from 'mica-ui-kit/src/components/layout/forms/PercentageAmountForm/PercentageAmountForm';
import React from 'react';

// @ts-ignore
export const defaultDiscount: CreateDiscountRequest = {
  discountCriteria: [],
  discountDefinitionRef: '',
  discountRef: '',
  receiptDescription: '',
  status: 1 as const,
  userKey: '',
  userRef: '',
};

type Input = typeof defaultDiscount & {
  version?: number;
};

const statusOptions = enumAsSelectOptions(DiscountStatus, 'DISCOUNT_STATUS_');

const dateFormat = 'MMMM D YYYY @h:mm a';

type DiscountFormProps<BaseRecord extends Input> = {
  definition: DiscountDefinition;
  discount?: BaseRecord;
  loading?: boolean;
  onSave: (input: BaseRecord) => void;
};

/**
 The create/update form for a discount
 */
const DiscountForm = <BaseRecord extends Input>({
  definition,
  discount,
  loading,
  onSave,
}: DiscountFormProps<BaseRecord>) => {
  const showUserKeyLookup = useBoolean();
  const overrideCriteria = useBoolean(
    (discount?.discountCriteria || []).length > 0
  );

  const { displayData, updateProperty } = useCreateUpdateForm(
    discount || defaultDiscount
  );

  const handleSave = () => onSave(displayData as BaseRecord);

  const handleToggleOverrideCriteria = () => {
    if (overrideCriteria.value) {
      updateProperty('discountCriteria')([]);
    }
    overrideCriteria.toggle();
  };

  const displayDiscountCriteria = overrideCriteria.value
    ? displayData.discountCriteria
    : definition.discountCriteria;

  return (
    <Card>
      <div className={styles.row}>
        <TextInput
          autoFocus
          className={'mb-20'}
          style={{ width: 320 }}
          onChange={updateProperty('discountRef')}
          value={displayData.discountRef}
          label={'Internal Ref/ID'}
          required
        />
      </div>

      <TextInput
        className={'mb-20'}
        style={{ width: 320 }}
        onChange={updateProperty('userKey')}
        value={displayData.userKey}
        label={'User Key'}
        required>
        <FaSearch onClick={showUserKeyLookup.setTrue} className={'pointer'} />
      </TextInput>
      <TextInput
        className={'mb-10'}
        style={{ width: 320 }}
        onChange={updateProperty('userRef')}
        value={displayData.userRef}
        label={'User Ref'}
        required
      />

      <div className={styles.overideInstructions}>
        Properties below will be inherited from the associated Discount
        Definition (def) unless overridden:
      </div>

      <div className={'my-20'}>
        <div className={'mb-5 flex justify-btw'} style={{ width: 330 }}>
          <div>Criteria:</div>
          <button
            className={styles.overrideCriteriaButton}
            onClick={handleToggleOverrideCriteria}>
            {overrideCriteria.value ? 'USE DEF' : 'OVERRIDE'}
          </button>
        </div>
        <DiscountCriteriaTiles
          criteria={displayDiscountCriteria}
          onAddCriteria={(criteria: DiscountCriteria) =>
            updateProperty('discountCriteria')([
              ...displayData.discountCriteria,
              criteria,
            ])
          }
          onUpdateCriteria={updateProperty('discountCriteria')}
          viewOnly={!overrideCriteria.value}
        />
      </div>

      {definition.monetaryAmount ? (
        <InputOverride
          className={styles.overrideRow}
          isOveridden={!!displayData.monetaryAmount?.amount}
          onSetToDefault={() => updateProperty('monetaryAmount')(undefined)}
          label={'Monetary Amount | Tax Amount'}
          value={`${definition.monetaryAmount.amount} | ${definition.monetaryAmount.taxAmount}`}>
          <MonetaryAmountForm
            className={styles.monetaryAmount}
            onChange={updateProperty('monetaryAmount')}
            amount={displayData.monetaryAmount}
          />
        </InputOverride>
      ) : (
        <InputOverride
          className={styles.overrideRow}
          style={{ height: 70 }}
          isOveridden={!!displayData.percentageAmount}
          onSetToDefault={() => updateProperty('percentageAmount')(undefined)}
          label={'Percentage Amount | Percentage Tax Amount'}
          value={`${definition?.percentageAmount?.percentageAmount} | ${definition.percentageAmount?.percentageTaxAmount}`}>
          <PercentageAmountForm
            className={styles.monetaryAmount}
            onChange={updateProperty('percentageAmount')}
            amount={displayData.percentageAmount!}
          />
        </InputOverride>
      )}

      <InputOverride
        className={styles.overrideRow}
        isOveridden={!!displayData.receiptDescription}
        onSetToDefault={() => updateProperty('receiptDescription')(undefined)}
        label={'Receipt Description'}
        value={definition.receiptDescription}>
        <TextInput
          className={'mb-10'}
          style={{ width: 240 }}
          onChange={updateProperty('receiptDescription')}
          value={displayData.receiptDescription}
          label={'Receipt Description'}
        />
      </InputOverride>

      <InputOverride
        className={styles.overrideRow}
        label={'Start Date'}
        isOveridden={!!displayData.validFrom}
        onSetToDefault={() => updateProperty('validFrom')(undefined)}
        value={dayjs(definition.validFrom).format(dateFormat)}>
        <DatePicker
          displayTime
          style={{ width: 240 }}
          label={'Start Date'}
          value={displayData?.validFrom}
          onChange={updateProperty('validFrom')}
        />
      </InputOverride>

      <InputOverride
        className={styles.overrideRow}
        label={'End Date'}
        isOveridden={!!displayData.validTo}
        onSetToDefault={() => updateProperty('validTo')(undefined)}
        value={dayjs(definition.validTo).format(dateFormat)}>
        <DatePicker
          className={'mb-10'}
          style={{ width: 240 }}
          displayTime
          label={'End Date'}
          value={displayData.validTo}
          onChange={updateProperty('validTo')}
        />
      </InputOverride>

      <Select
        className={'mt-15'}
        label={'Status'}
        onChange={updateProperty('status')}
        options={statusOptions}
        value={displayData.status}
        required
      />
      <LoadingButton
        className={'mt-30'}
        loading={loading}
        title={discount ? 'UPDATE' : 'CREATE'}
        onClick={handleSave}
      />
      <UserKeyLookupModal
        active={showUserKeyLookup.value}
        onDismiss={showUserKeyLookup.setFalse}
        onUserFound={updateProperty('userKey')}
      />
    </Card>
  );
};

export default DiscountForm;
