import { TextInput } from '../../../inputs';
import styles from './MonetaryAmountForm.module.css';
import cx from 'classnames';
import { MonetaryAmount } from 'mica-proto/src/mica/discount/discount/v1/discount';
import React, { CSSProperties } from 'react';

type MonetaryAmountFormProps = {
  amount?: Partial<MonetaryAmount>;
  className?: string;
  labels?: [string, string];
  onChange: (update: Partial<MonetaryAmount>) => void;
  style?: CSSProperties;
};

/**
 A form to enter a monetary amount (amount & tax amount)
 */
const MonetaryAmountForm = ({
  amount,
  className,
  labels = ['Amount', 'Tax Amount'],
  onChange,
  style,
}: MonetaryAmountFormProps) => {
  const handleChange = (property: string) => (value: string) =>
    onChange({
      ...amount,
      [property]: value,
    });

  return (
    <div className={cx(styles.container, className)} style={style}>
      <TextInput
        className={styles.input}
        label={labels[0]}
        onChange={handleChange('amount')}
        value={amount?.amount || ''}
      />
      <TextInput
        className={styles.input}
        label={labels[1]}
        onChange={handleChange('taxAmount')}
        value={amount?.taxAmount || ''}
      />
    </div>
  );
};

export default MonetaryAmountForm;
