import { DiscountClient } from 'discount-client';
import React, { createContext, PropsWithChildren } from 'react';
import {
  QueryClient,
  QueryClientConfig,
  QueryClientProvider,
} from 'react-query';

export { createMicaDiscountClient } from 'discount-client';
export type { DiscountClient };

export const DiscountClientContext = createContext<DiscountClient | undefined>(
  undefined
);

type DiscountClientProviderProps = {
  client: DiscountClient;
  config: QueryClientConfig;
};

export const DiscountClientProvider = ({
  children,
  client,
  config,
}: PropsWithChildren<DiscountClientProviderProps>) => {
  const queryClient = new QueryClient(config);
  return (
    <QueryClientProvider client={queryClient}>
      <DiscountClientContext.Provider value={client}>
        {children}
      </DiscountClientContext.Provider>
    </QueryClientProvider>
  );
};
