import { useDiscountClient } from '../../useDiscountClient/useDiscountClient';
import { useMutation, useQueryClient } from 'react-query';

export const useUpdateDiscountDefinition = () => {
  const client = useDiscountClient();
  const queryClient = useQueryClient();

  const { mutate: updateDiscountDefinition, ...rest } = useMutation(
    client.updateDiscountDefinition,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('searchDiscountDefinition');
        queryClient.invalidateQueries('getDiscountDefinition');
      },
    }
  );

  return {
    updateDiscountDefinition,
    ...rest,
  };
};
