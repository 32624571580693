import { StyleProps, StyledElement } from '../StyledElement/StyledElement';
import styles from './Card.module.css';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import React, { ForwardedRef, ReactNode } from 'react';

type CardProps = StyleProps & {
  children?: ReactNode;
  title?: string;
  titleAccessories?: JSX.Element | null;
  titleTooltip?: string;
};

/**
 Our common 'Card' component - white box for use over grey bg
 */
export const Card = React.forwardRef(
  (
    { children, title, titleAccessories, titleTooltip, ...style }: CardProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <StyledElement constantClassName={styles.card} {...style} ref={ref}>
      {title && (
        <div className={styles.titleRow}>
          <span className={styles.title}>{title}</span>
          {titleTooltip && (
            <FaInfoCircle
              color={'#999'}
              className={'ml-5'}
              data-tip={titleTooltip}
            />
          )}
          {titleAccessories}
        </div>
      )}
      {children}
    </StyledElement>
  )
);
