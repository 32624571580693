import DiscountProviderAccountForm from '../../../shared/accounts/DiscountProviderAccountForm/DiscountProviderAccountForm';
import { useDiscountProviderAccount } from 'discount-client-react/src/client/discountProviderAccount/useDiscountProviderAccount/useDiscountProviderAccount';
import { useUpdateDiscountProviderAccount } from 'discount-client-react/src/client/discountProviderAccount/useUpdateDiscountProviderAccount/useUpdateDiscountProviderAccount';
import { UpdateDiscountProviderAccountRequest } from 'mica-proto/src/mica/discount/discountprovideraccount/v1/discount_provider_account';
import { LoadingOverlay } from 'mica-ui-kit/src/components';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 Our screen for searching for DiscountProviderAccounts
 */
const Account = () => {
  const discountProviderAccountRef = useParams().discountProviderAccountRef!;

  const { data, isLoading } = useDiscountProviderAccount({
    discountProviderAccountRef,
  });

  const { updateDiscountProviderAccount, isLoading: updateLoading } =
    useUpdateDiscountProviderAccount();

  const handleUpdate = (update: UpdateDiscountProviderAccountRequest) =>
    updateDiscountProviderAccount(update, {
      onSuccess: () => {
        toast.success('Discount Provider Account updated');
      },
    });

  return (
    <Page title={`Account: ${data?.discountProviderAccount?.name || ''}`}>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <DiscountProviderAccountForm
          onSave={handleUpdate}
          loading={updateLoading}
          discountProviderAccount={data!.discountProviderAccount}
        />
      )}
    </Page>
  );
};

export default Account;
