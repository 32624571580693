import { Backdrop } from '../layout/Backdrop/Backdrop';
import { LoadingIndicator } from './LoadingIndicator/LoadingIndicator';
import * as React from 'react';

export const LoadingOverlay = () => {
  return (
    <Backdrop active>
      <LoadingIndicator />
    </Backdrop>
  );
};
