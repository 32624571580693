import { LabelDetail } from '../../../../../../layout';
import Checkbox from '../../../../../inputs/Checkbox/Checkbox';
import { Button } from '../../../../../inputs/buttons/Button/Button';
import { Card } from '../../../../../layout/Card/Card';
import { Page } from '../../../../../layout/Page/Page';
import {
  MTLSCertificate,
  UpdateMTLSCertificateRequest,
} from 'mica-proto/src/micashared/common/v1/mtls_certificate';
import { useCreateUpdateForm } from 'mica-shared-react/src/hooks/useCreateUpdateForm';
import React from 'react';

type MtlsCertProps = {
  mtlsCertificate: MTLSCertificate;
  loading: boolean;
  onUpdateCertificate: (args: UpdateMTLSCertificateRequest) => void;
  roles: string[];
};

/**
 A Component
 */
const MtlsCert = ({
  mtlsCertificate,
  loading,
  onUpdateCertificate,
  roles,
}: MtlsCertProps) => {
  const { displayData, updateProperty } = useCreateUpdateForm(mtlsCertificate);

  return (
    <Page title={'Member Client Certificate Details'}>
      <Card>
        <LabelDetail
          className={'my-10'}
          label={'Serial Number'}
          value={'' + mtlsCertificate.serialNumber}
        />
        <LabelDetail
          className={'my-10'}
          label={'Issued'}
          value={mtlsCertificate.issued?.toLocaleDateString()}
        />
        <LabelDetail
          className={'my-10'}
          label={'Expires'}
          value={mtlsCertificate.expiry?.toLocaleDateString()}
        />
        <LabelDetail
          className={'my-10'}
          label={'Roles'}
          value={roles.join(', ')}
        />

        <Checkbox
          checked={displayData.enabled}
          className={'my-10'}
          onChange={updateProperty('enabled')}
          label={'Enabled'}
        />

        <Button
          label={'SAVE'}
          loading={loading}
          onClick={() => onUpdateCertificate(displayData)}
        />
      </Card>
    </Page>
  );
};

export default MtlsCert;
