import DiscountForm from '../../../shared/discounts/DiscountForm/DiscountForm';
import styles from './CreateDiscount.module.css';
import { useCreateDiscount } from 'discount-client-react/src/client/discount/useCreateDiscount/useCreateDiscount';
import { useDiscountDefinition } from 'discount-client-react/src/client/discountDefinition/useDiscountDefinition/useDiscountDefinition';
import { CreateDiscountRequest } from 'mica-proto/src/mica/discount/discount/v1/discount';
import {
  LabelDetail,
  LoadingIndicator,
  TextInput,
} from 'mica-ui-kit/src/components';
import { Card } from 'mica-ui-kit/src/components/consoles/layout/Card/Card';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 A Screen to create a new discount from a discount definition
 */
const CreateDiscount = () => {
  const [searchParams] = useSearchParams();

  const [discountDefinitionRef, setDiscountDefinitionRef] = useState(
    searchParams.get('discountDefinitionRef') || ''
  );

  const { data, isLoading } = useDiscountDefinition(
    { discountDefinitionRef },
    {
      enabled: discountDefinitionRef.length > 3,
    }
  );

  const { createDiscount, isLoading: createLoading } = useCreateDiscount();

  const navigate = useNavigate();

  const definition = data?.discountDefinition;

  const handleCreateDiscount = (discount: CreateDiscountRequest) => {
    createDiscount(
      {
        ...discount,
        discountDefinitionRef,
      },
      {
        onSuccess: () => {
          toast.success('Discount Created');
          navigate('/app/discounts/' + discount.discountRef);
        },
      }
    );
  };

  return (
    <Page title={'Create Discount'}>
      <Card title={'Search Definition'}>
        <div className={styles.discountDefRow}>
          {definition ? (
            <LabelDetail
              label={'Discount Definition Ref'}
              value={discountDefinitionRef}
              style={{ width: 300 }}
            />
          ) : (
            <TextInput
              onChange={setDiscountDefinitionRef}
              value={discountDefinitionRef}
              label={'Discount Definition Ref'}
            />
          )}
        </div>
      </Card>
      {isLoading && <LoadingIndicator />}
      {definition && (
        <DiscountForm
          onSave={handleCreateDiscount}
          definition={definition}
          loading={createLoading}
        />
      )}
    </Page>
  );
};

export default CreateDiscount;
