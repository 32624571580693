import styles from './IconButton.module.css';
import cx from 'classnames';
import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import ReactTooltip from 'react-tooltip';

export type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  label: string;
};

/**
 The composition base for our icon buttons
 */
export const IconButton = ({
  children,
  className,
  label,
  ...rest
}: PropsWithChildren<IconButtonProps>) => (
  <>
    <button
      className={cx(styles.button, className)}
      data-tip={label}
      aria-label={label}
      {...rest}>
      {children}
    </button>
    <ReactTooltip />
  </>
);
