import { useLazyQuery } from 'mica-shared-react/src/hooks/useLazyQuery/useLazyQuery';
import { useDiscountClient } from '../../useDiscountClient/useDiscountClient';

/**
 * A hook for fetching a discount definition
 */
export const useSearchDiscountDefinition = () => {
  const client = useDiscountClient();

  const { fetch, ...rest } = useLazyQuery(
    'searchDiscountDefinition',
    client.searchDiscountDefinition
  );

  return {
    searchDiscountDefinition: fetch,
    discountDefinitions: rest.data?.discountDefinitions ?? [],
    ...rest,
  };
};
