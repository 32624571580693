import { AddIconButton } from '../../../inputs/buttons/AddIconButton/AddIconButton';
import { Card } from '../../../layout/Card/Card';
import { Link } from '../../../layout/navigation/Link/Link';
import { EmptyList } from '../../../layout/text/EmptyList/EmptyList';
import { InfoBox } from '../../../layout/text/InfoBox/InfoBox';
import styles from '../NetworkSettings.module.css';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { MTLSCertificate } from 'mica-proto/src/micashared/common/v1/mtls_certificate';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const NEW_CERT_ROUTE = '/app/settings/certs/mtls/new';

export type MtlsCertificatesCardProps = {
  mtlsCertificates?: MTLSCertificate[];
};

/**
 The 'Incoming Certicates' segment of our Network Config
 */
export const MtlsCertificatesCard = ({
  mtlsCertificates = [],
}: MtlsCertificatesCardProps) => {
  const navigate = useNavigate();

  return (
    <Card
      title={'Member Client Certificates'}
      titleAccessories={
        <AddIconButton
          className={styles.addButton}
          onClick={() => navigate(NEW_CERT_ROUTE)}
        />
      }>
      <InfoBox className={'my-20'}>
        Certificates are used to identify requests from you to the Mica network
      </InfoBox>
      {mtlsCertificates.length > 0 && (
        <table className={'table'}>
          <thead>
            <tr>
              <th>Serial Number</th>
              <th>Enabled</th>
            </tr>
          </thead>
          <tbody>
            {mtlsCertificates.map((cert) => (
              <tr
                className={'pointer'}
                key={cert.serialNumber}
                onClick={() =>
                  navigate('/app/settings/certs/mtls/' + cert.serialNumber)
                }>
                <td>{cert.serialNumber}</td>
                <td>{cert.enabled ? <FaCheck /> : <FaTimes />}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {mtlsCertificates.length === 0 && (
        <EmptyList>
          No certificates configured.
          <Link
            className="ml-3"
            onClick={() => navigate(NEW_CERT_ROUTE)}
            label={'Add One?'}
          />
        </EmptyList>
      )}
    </Card>
  );
};
