import styles from './LabelDetail.module.css';
import React, { CSSProperties } from 'react';
import { Label } from '../Label/Label';

type LabelDetailProps = {
  label: string;
  value?: string;
  className?: string;
  style?: CSSProperties;
};

/**
 A Component that displays a value with a label above it, mimicking our text input
 */
export const LabelDetail = ({
  className,
  label,
  style,
  value,
}: LabelDetailProps) => (
  <div className={className} style={style}>
    <Label value={label} />
    <div className={styles.value}>{value || '-'}</div>
  </div>
);
