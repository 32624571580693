/* eslint-disable */

export const protobufPackage = "micashared.common.enums.discounttype.v1";

export enum DiscountType {
  DISCOUNT_TYPE_UNSPECIFIED = 0,
  DISCOUNT_TYPE_CPG = 1,
  DISCOUNT_TYPE_GOVERNMENT = 2,
  DISCOUNT_TYPE_PARTNER = 3,
  DISCOUNT_TYPE_SERVICE_PROVIDER = 4,
}
