import DiscountProviderAccountForm from '../../../shared/accounts/DiscountProviderAccountForm/DiscountProviderAccountForm';
import { useCreateDiscountProviderAccount } from 'discount-client-react/src/client/discountProviderAccount/useCreateDiscountProviderAccount/useCreateDiscountProviderAccount';
import { CreateDiscountProviderAccountRequest } from 'mica-proto/src/mica/discount/discountprovideraccount/v1/discount_provider_account';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 A modal form to create a discount provider account
 */
const CreateAccount = () => {
  const navigate = useNavigate();

  const { createDiscountProviderAccount, isLoading } =
    useCreateDiscountProviderAccount();

  const handleCreateProductGroup = (
    account: CreateDiscountProviderAccountRequest
  ) =>
    createDiscountProviderAccount(account, {
      onSuccess: () => {
        toast.success('Account created');
        navigate(`/app/accounts/${account.discountProviderAccountRef}`);
      },
    });

  return (
    <Page title={'Create Discount Provider Account'}>
      <DiscountProviderAccountForm
        onSave={handleCreateProductGroup}
        loading={isLoading}
      />
    </Page>
  );
};

export default CreateAccount;
