import ChartTile from '../ChartTile/ChartTile';
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
} from 'chart.js';
import dayjs from 'dayjs';
import { range } from 'ramda';
import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

/**
 A Component
 */
const OpenDiscounts = () => {
  const [labels, data] = useMemo(() => {
    const months = range(0, new Date().getMonth());
    const values = months.map(() => 500 + Math.random() * 500);
    return [months, values];
  }, []);

  return (
    <ChartTile title={'Live Discounts'}>
      <Line
        options={options}
        datasetIdKey="id"
        data={{
          labels: labels.map((l) => dayjs().set('months', l).format('MMM')),
          datasets: [
            {
              borderColor: '#DC0073',
              backgroundColor: '#DC007350',
              label: '$',
              data,
            },
          ],
        }}
      />
    </ChartTile>
  );
};

export default OpenDiscounts;
