import { StyleProps, StyledElement } from '../../StyledElement/StyledElement';
import styles from './IconMessageBox.module.css';
import React, { PropsWithChildren } from 'react';

export type IconMessageBoxProps = StyleProps & {
  icon: JSX.Element;
  message?: string;
};

/**
 The composition root for our elements that display an icon and a message
 such as information, warning, error, empty list
 */
export const IconMessageBox = ({
  children,
  icon,
  message,
  ...style
}: PropsWithChildren<IconMessageBoxProps>) => (
  <StyledElement constantClassName={styles.container} {...style}>
    <div className={styles.iconContainer}>{icon}</div>
    <div className={styles.messageContainer}>
      {message}
      {children}
    </div>
  </StyledElement>
);

export default IconMessageBox;
