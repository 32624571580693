import styles from './SearchProductGroups.module.css';
import { useSearchProductGroup } from 'discount-client-react/src/client/productGroup/useSearchProductGroup/useSearchProductGroup';
import { TextInput } from 'mica-ui-kit/src/components';
import { AddIconButton } from 'mica-ui-kit/src/components/consoles/inputs/buttons/AddIconButton/AddIconButton';
import { Card } from 'mica-ui-kit/src/components/consoles/layout/Card/Card';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'mica-ui-kit/src/components/consoles/inputs/buttons/Button/Button';

/**
 Our screen for searching for DiscountProviderAccounts
 */
const SearchProductGroups = () => {
  const [name, setName] = useState('');

  const { searchProductGroup, productGroups, isLoading } =
    useSearchProductGroup();

  const navigate = useNavigate();

  const handleSearch = () => searchProductGroup({ name });

  return (
    <Page title={'Product Groups'}>
      <Card
        title={'Search'}
        titleAccessories={
          <AddIconButton
            style={{ position: 'absolute', right: 20 }}
            onClick={() => navigate('create')}
          />
        }>
        <div className={styles.searchInputs}>
          <TextInput
            onChange={setName}
            value={name}
            label={'Name'}
            style={{ width: 280 }}
          />
          <Button
            className={'mt-20'}
            label={'Search'}
            loading={isLoading}
            onClick={handleSearch}
          />
        </div>

        <div className={styles.results}>
          <table className={'table'} style={{ opacity: isLoading ? 0.6 : 1 }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Reference</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {productGroups.map((pg) => (
                <tr
                  className={'pointer'}
                  key={pg.productGroupKey}
                  onClick={() =>
                    navigate(`/app/products/${pg.productGroupRef}`)
                  }>
                  <td>{pg.name}</td>
                  <td>{pg.productGroupRef}</td>
                  <td>{pg.productCodes.length}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </Page>
  );
};

export default SearchProductGroups;
