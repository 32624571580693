import { LoadingIndicator } from '../../../../loading';
import styles from './Button.module.css';
import cx from 'classnames';
import React, { PropsWithChildren } from 'react';

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  isDanger?: boolean;
  loading?: boolean;
  label?: string;
};

/**
 Our standard button component with a loading indicator
 */
export const Button = ({
  children,
  className,
  isDanger,
  label,
  loading,
  ...rest
}: PropsWithChildren<ButtonProps>) => (
  <button
    className={cx(
      styles.button,
      {
        [styles.danger]: isDanger,
        [styles.disabled]: rest.disabled,
        [styles.loading]: loading,
      },
      className
    )}
    disabled={rest.disabled || loading}
    {...rest}>
    <div className={cx(styles.content, { [styles.loading]: loading })}>
      {label ? label : children}
    </div>
    {loading && <LoadingIndicator className={styles.loader} />}
  </button>
);
