import styles from './Button.module.css';
import cx from 'classnames';
import React, { PropsWithChildren } from 'react';

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> & {
  title?: string;
  small?: boolean;
};

/**
 Our standard button component
 */
export const Button = ({
  children,
  className,
  title,
  small,
  ...rest
}: PropsWithChildren<ButtonProps>) => (
  <button
    className={cx(
      styles.button,
      { [styles.disabled]: rest.disabled, [styles.small]: small },
      className
    )}
    {...rest}>
    {title ? title : children}
  </button>
);
