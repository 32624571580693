import { enumAsSelectOptions } from '../../../../utils';
import { Select, TextInput } from '../../../inputs';
import styles from './QuantityForm.module.css';
import { Unit } from 'mica-proto/src/micashared/common/enums/unit/v1/unit';
import { Quantity } from 'mica-proto/src/mica/discount/discount/v1/discount';
import React from 'react';

type QuantityFormProps = {
  quantity: Partial<Quantity>;
  onChange: (update: Partial<Quantity>) => void;
};

const unitOptions = enumAsSelectOptions(Unit, 'UNIT_');

/**
 A Component
 */
const QuantityForm = ({ quantity, onChange }: QuantityFormProps) => {
  const handleChange = (property: string) => (value: string) =>
    onChange({
      ...quantity,
      [property]: value,
    });

  return (
    <div className={styles.container}>
      <Select
        onChange={handleChange('unit')}
        options={unitOptions}
        label={'Unit'}
        value={quantity.unit || ''}
      />
      <TextInput
        style={{ width: 100, marginLeft: 15 }}
        label={'Quantity'}
        onChange={handleChange('quantity')}
        value={quantity.quantity || ''}
      />
    </div>
  );
};

export default QuantityForm;
