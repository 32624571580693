import IconMessageBox, {
  IconMessageBoxProps,
} from '../IconMessageBox/IconMessageBox';
import styles from './InfoBox.module.css';
import { FaInfoCircle } from '@react-icons/all-files/fa/FaInfoCircle';
import cx from 'classnames';
import React, { PropsWithChildren } from 'react';

/**
 A standard information box with 'i' icon
 */
export const InfoBox = ({
  className,
  ...props
}: PropsWithChildren<Omit<IconMessageBoxProps, 'icon'>>) => (
  <IconMessageBox
    className={cx(styles.infoBox, className)}
    icon={<FaInfoCircle size={12} />}
    {...props}
  />
);
