import styles from './TextArea.module.css';
import cx from 'classnames';
import React, { createRef, useState } from 'react';

type TextInputProps = Omit<
  React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >,
  'onChange'
> & {
  className?: string;
  inputClassName?: string;
  label?: string;
  labelClassName?: string;
  onChange: (val: string) => void;
  useMonospaceFont?: boolean;
  value: string;
};

/**
 Our standard Text Area component
 */
export const TextArea = ({
  className,
  inputClassName,
  label,
  labelClassName,
  onChange,
  useMonospaceFont,
  ...rest
}: TextInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = createRef<HTMLTextAreaElement>();

  const handleBlur = (e: any) => {
    setIsFocused(false);
    rest.onBlur?.(e);
  };

  const handleChange = (e: any) => onChange(e.target.value);

  const handleFocus = (e: any) => {
    setIsFocused(true);
    rest.onFocus?.(e);
  };

  return (
    <div className={cx(styles.container, className)}>
      <textarea
        className={cx(
          styles.input,
          {
            [styles.isFocused]: isFocused,
            [styles.isMonospace]: useMonospaceFont,
          },
          inputClassName
        )}
        {...rest}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        ref={inputRef}
      />
      {label && (
        <span
          className={cx(styles.label, labelClassName, {
            [styles.isFocused]: isFocused,
            [styles.isReduced]: isFocused || !!rest.value,
          })}>
          {label}
        </span>
      )}
    </div>
  );
};
