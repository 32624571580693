import { LoadingIndicator } from '../../../loading';
import { Button, ButtonProps } from '../Button/Button';
import styles from '../Button/Button.module.css';
import cx from 'classnames';
import React from 'react';

type LoadingButtonProps = ButtonProps & {
  loading?: boolean;
};

/**
 A version of our base Button that swaps its content for a loading indicator
 for use when the button fires a request we need to wait for
 */
export const LoadingButton = ({
  children,
  loading,
  title,
  ...props
}: LoadingButtonProps) => (
  <Button
    {...props}
    disabled={loading || props.disabled}
    children={
      loading ? (
        <>
          {title || children}
          <div className={cx(styles.button, styles.loadingButton)}>
            <LoadingIndicator />
          </div>
        </>
      ) : (
        title || children
      )
    }
  />
);
