import { localStorageEffect } from 'mica-shared-react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SetterOrUpdater, atom, useRecoilState } from 'recoil';

export const oktaTenantState = atom<string>({
  key: 'oktaTenantState',
  default: '',
  effects: [localStorageEffect<string>('oktaTenantState')],
});

/**
 * A hook to keep the string value of the okta tenant persisted between sessions, globally available
 * and overrideable via query string
 */
export const useOktaTenant = () => {
  const [tenant, setTenant] = useRecoilState(oktaTenantState);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const searchParamTenant = searchParams.get('tenant');
    if (searchParamTenant && searchParamTenant !== tenant) {
      setTenant(searchParamTenant);
      window.location.replace(
        window.location.href.replace(`?tenant=${searchParamTenant}`, '')
      );
    }
  }, [searchParams, tenant]);

  return [tenant, setTenant] as [string, SetterOrUpdater<string>];
};
