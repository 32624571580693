import { useDiscountClient } from '../../useDiscountClient/useDiscountClient';
import { useMutation, useQueryClient } from 'react-query';

/**
 * A hook for updating api token config
 */
export const useCreateApiTokenConfiguration = () => {
  const client = useDiscountClient();
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(client.createApiTokenConfiguration, {
    onSuccess: () => {
      queryClient.invalidateQueries('getApiTokenConfiguration');
    },
  });

  return {
    createApiTokenConfiguration: mutate,
    ...rest,
  };
};
