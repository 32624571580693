import {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';

export interface ReturnType {
  value: boolean;
  setValue: Dispatch<SetStateAction<boolean>>;
  setTrue: () => void;
  setFalse: () => void;
  toggle: () => void;
}

const isUseBoolean = (value?: boolean | ReturnType): value is ReturnType => {
  return !!value && typeof value !== 'boolean';
};

export const useBoolean = (defaultValue?: boolean | ReturnType): ReturnType => {
  const defaultIsUseBoolean = isUseBoolean(defaultValue);
  const [value, setValue] = useState(
    defaultIsUseBoolean ? defaultValue.value : !!defaultValue
  );

  const setTrue = useCallback(
    () => (defaultIsUseBoolean ? defaultValue.setTrue() : setValue(true)),
    [defaultIsUseBoolean, defaultValue]
  );
  const setFalse = useCallback(
    () => (defaultIsUseBoolean ? defaultValue.setFalse() : setValue(false)),
    [defaultIsUseBoolean, defaultValue]
  );
  const toggle = useCallback(
    () => (defaultIsUseBoolean ? defaultValue.toggle() : setValue((x) => !x)),
    [defaultIsUseBoolean, defaultValue]
  );

  return useMemo(
    () => ({ value, setValue, setTrue, setFalse, toggle }),
    [value, setValue, setTrue, setFalse, toggle]
  );
};
