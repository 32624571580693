import styles from './Switch.module.css';
import cx from 'classnames';
import React, { CSSProperties } from 'react';
import { MaterialUiStyleLabel } from '../../../layout/MaterialUiStyleLabel/MaterialUiStyleLabel';

/*
A standard switch
*/

export interface SwitchProps {
  className?: string;
  disabled?: boolean;
  label?: string;
  labelClassName?: string;
  on: boolean;
  onClick: () => void;
  size?: 'small' | 'medium' | 'large';
  style?: CSSProperties;
}

const sizeMultiplier = {
  small: 0.75,
  medium: 1,
  large: 1.25,
};

export const Switch = ({
  className = '',
  disabled,
  label,
  labelClassName,
  on,
  size = 'large',
  style,
  ...rest
}: SwitchProps) => {
  return (
    <div
      className={cx(
        styles.container,
        {
          [styles.withLabel]: !!label,
        },
        className
      )}
      style={style}>
      {label && (
        <MaterialUiStyleLabel
          isFocused={true}
          isReduced={true}
          className={cx(styles.label, labelClassName)}
          value={label}
        />
      )}
      <div
        className={cx(styles.switchContainer, className, {
          [styles.disabled]: disabled,
          [styles.switchContainerOn]: on,
        })}
        // @ts-ignore: custom css prop
        style={{ '--size-multiplier': sizeMultiplier[size] }}
        {...rest}>
        <div className={cx(styles.switch, { [styles.switchOn]: on })} />
      </div>
    </div>
  );
};
