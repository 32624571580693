import { TextInput } from '../../../inputs';
import { assoc } from 'ramda';
import React from 'react';
import {
  StyledElement,
  StyleProps,
} from '../../../consoles/layout/StyledElement/StyledElement';

export const defaultBankAccount = {
  bankAccountNumber: '',
  domesticAchRoutingNumber: '',
  internationalAchRoutingNumber: '',
  wireRoutingNumber: '',
  swiftRoutingNumber: '',
};

export type BankAccount = typeof defaultBankAccount;

type BankAccountFormProps = {
  bankAccount?: Partial<BankAccount>;
  onChange: (bankAccount: Partial<BankAccount>) => void;
};

const inputs = Object.keys(defaultBankAccount);

export const camelCaseToTitleCase = (camelCase: string) => {
  const result = camelCase.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

/**
 The inputs required for a bank account
 */
export const BankAccountForm = ({
  bankAccount = defaultBankAccount,
  onChange,
  ...style
}: BankAccountFormProps & StyleProps) => {
  const handleChange = (property: string) => (value: any) =>
    onChange(assoc(property, value, bankAccount));

  return (
    <StyledElement {...style}>
      {inputs.map((property, i) => (
        <TextInput
          key={property}
          className={i === 0 ? '' : 'mt-5'}
          style={{ width: 300 }}
          onChange={handleChange(property)}
          value={bankAccount[property as keyof BankAccount] || ''}
          label={camelCaseToTitleCase(property)}
        />
      ))}
    </StyledElement>
  );
};
