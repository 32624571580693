import { createMicaDiscountClient } from 'discount-client/src';
import { DiscountClientProvider } from 'discount-client-react/src/client/DiscountClientProvider/DiscountClientProvider';
import { metadataForOktaAuth, pathFromEnvOrOrigin } from 'mica-utilities/src';
import { basicQueryConfig } from 'mica-ui-kit/src/utils/reactQuery/reactQuery';
import { PropsWithChildren } from 'react';

const client = createMicaDiscountClient({
  host: pathFromEnvOrOrigin(import.meta.env.VITE_DISCOUNT_HOST),
  metadata: metadataForOktaAuth,
});

const Provider = ({ children }: PropsWithChildren<object>) => (
  <DiscountClientProvider client={client} config={basicQueryConfig}>
    {children}
  </DiscountClientProvider>
);

export default Provider;
