import {
  StyleProps,
  StyledElement,
} from '../../../layout/StyledElement/StyledElement';
import { Button } from '../Button/Button';
import styles from './ButtonGroup.module.scss';
import cx from 'classnames';
import * as React from 'react';

type Props<T> = StyleProps & {
  options: T[];
  selectedOption: T;
  onSelectionChange: (newOption: T) => void;
};

export const ButtonGroup = <T extends string>({
  options,
  onSelectionChange,
  selectedOption,
  ...style
}: Props<T>) => {
  return (
    <StyledElement constantClassName={styles.container} {...style}>
      {options.map((option) => (
        <Button
          className={cx(styles.optionButton, {
            [styles.selectedButton]: option === selectedOption,
          })}
          key={option}
          onClick={() => onSelectionChange(option)}>
          {option}
        </Button>
      ))}
    </StyledElement>
  );
};
