import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { useSearchProductGroup } from 'discount-client-react/src/client/productGroup/useSearchProductGroup/useSearchProductGroup';
import { ProductGroup } from 'mica-proto/src/mica/discount/productgroup/v1/product_group';
import { TextInput } from 'mica-ui-kit/src/components';
import React, { useState } from 'react';
import { Button } from 'mica-ui-kit/src/components/consoles/inputs/buttons/Button/Button';

type SearchProductGroupProps = {
  multiSelect?: boolean;
  onProductGroupSelected: (productGroup: ProductGroup) => void;
  selectedRefs?: string[];
};

/**
 Our UI for searching Product Groups
 */
const SearchProductGroup = ({
  multiSelect,
  onProductGroupSelected,
  selectedRefs,
}: SearchProductGroupProps) => {
  const [name, setName] = useState('');

  const { data, isLoading, searchProductGroup } = useSearchProductGroup();

  const handleSearch = () => searchProductGroup({ name });

  return (
    <div>
      <TextInput onChange={setName} value={name} label={'Name'} />
      <Button
        className={'mt-20'}
        label={'Search'}
        loading={isLoading}
        onClick={handleSearch}
      />
      <table className={'table mt-20'} style={{ opacity: isLoading ? 0.6 : 1 }}>
        <thead>
          <tr>
            <th>Name</th>
            <th>Reference</th>
            <th>{multiSelect ? 'Selected' : '# Products'}</th>
          </tr>
        </thead>
        <tbody>
          {(data?.productGroups || []).map((pg) => (
            <tr
              className={'pointer'}
              key={pg.productGroupKey}
              onClick={() => onProductGroupSelected(pg)}>
              <td>{pg.name}</td>
              <td>{pg.productGroupRef}</td>
              <td>
                {multiSelect ? (
                  <FaCheck
                    color={
                      selectedRefs?.includes(pg.productGroupRef)
                        ? 'green'
                        : '#CCC'
                    }
                  />
                ) : (
                  pg.productCodes.length
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SearchProductGroup;
