import DiscountDefinitionForm from '../../../shared/definitions/DiscountDefinitionForm/DiscountDefinitionForm';
import { useDiscountDefinition } from 'discount-client-react/src/client/discountDefinition/useDiscountDefinition/useDiscountDefinition';
import { useUpdateDiscountDefinition } from 'discount-client-react/src/client/discountDefinition/useUpdateDiscountDefinition/useUpdateDiscountDefinition';
import { UpdateDiscountDefinitionRequest } from 'mica-proto/src/mica/discount/discountdefinition/v1/discount_definition';
import { LoadingOverlay } from 'mica-ui-kit/src/components';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import { Button } from 'mica-ui-kit/src/components/inputs/buttons/Button/Button';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 Our screen for searching for DiscountProviderAccounts
 */
const Definition = () => {
  const discountDefinitionRef = useParams().discountDefinitionRef!;

  const { data, isLoading } = useDiscountDefinition({ discountDefinitionRef });
  const { updateDiscountDefinition, isLoading: updateLoading } =
    useUpdateDiscountDefinition();

  const navigate = useNavigate();

  const handleUpdateDefinition = (update: UpdateDiscountDefinitionRequest) =>
    updateDiscountDefinition(update, {
      onSuccess: () => {
        toast.success('Definition Updated');
      },
    });

  return (
    <Page
      title={
        'Definition: ' + data?.discountDefinition?.discountDefinitionRef || ''
      }>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <DiscountDefinitionForm
          onSave={handleUpdateDefinition}
          discountDefinition={data!.discountDefinition}
          loading={updateLoading}
        />
      )}
      <Button
        className={'mt-10'}
        title={'CREATE DISCOUNT'}
        onClick={() =>
          navigate(
            '/app/discounts/create?discountDefinitionRef=' +
              discountDefinitionRef
          )
        }
      />
    </Page>
  );
};

export default Definition;
