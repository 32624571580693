import ProductGroupForm from '../../../shared/products/ProductGroupForm/ProductGroupForm';
import { useProductGroup } from 'discount-client-react/src/client/productGroup/useProductGroup/useProductGroup';
import { useUpdateProductGroup } from 'discount-client-react/src/client/productGroup/useUpdateProductGroup/useUpdateProductGroup';
import { UpdateProductGroupRequest } from 'mica-proto/src/mica/discount/productgroup/v1/product_group';
import { LoadingOverlay } from 'mica-ui-kit/src/components';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 Our screen for displaying a single Product Group
 */
const ProductGroup = () => {
  const productGroupRef = useParams().productGroupRef!;

  const { data, isLoading } = useProductGroup({ productGroupRef });
  const { updateProductGroup, isLoading: updateLoading } =
    useUpdateProductGroup();

  const handleUpdate = (update: UpdateProductGroupRequest) => {
    updateProductGroup(update, {
      onSuccess: () => {
        toast.success('Product Group updated');
      },
    });
  };

  return (
    <Page title={`Product Group: ${data?.productGroup?.name || ''}`}>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <ProductGroupForm
          onSave={handleUpdate}
          loading={updateLoading}
          productGroup={data!.productGroup!}
        />
      )}
    </Page>
  );
};

export default ProductGroup;
