/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { Error } from "./error";

export const protobufPackage = "micashared.common.v1";

export enum ClientAuthenticationType {
  CLIENT_AUTHENTICATION_TYPE_UNSPECIFIED = 0,
  CLIENT_AUTHENTICATION_TYPE_CLIENT_CERTIFICATE = 1,
  CLIENT_AUTHENTICATION_TYPE_API_TOKEN = 2,
}

export interface ExternalClientSettings {
  version: number;
  created: Date | undefined;
  updated: Date | undefined;
  callbackAddress: string;
  clientAuthenticationSettings: ClientAuthenticationSettings | undefined;
}

export interface ClientAuthenticationSettings {
  suportedClientAuthenticationTypes: ClientAuthenticationType[];
  currentAuthenticationType: ClientAuthenticationType;
}

export interface GetExternalClientSettingsRequest {
}

export interface GetExternalClientSettingsResponse {
  status: GetExternalClientSettingsResponse_Status;
  error: Error | undefined;
  settings: ExternalClientSettings | undefined;
}

export enum GetExternalClientSettingsResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface UpdateExternalClientCallBackAddressRequest {
  version: number;
  /**
   * the callback address must folow a <hostname>:<port> pattern for example
   * mycallbackservicie.somecompany.io:443
   */
  callbackAddress: string;
}

export interface UpdateExternalClientCallBackAddressResponse {
  status: UpdateExternalClientCallBackAddressResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum UpdateExternalClientCallBackAddressResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface UpdateExternalClientAuthenticationTypeRequest {
  version: number;
  authenticationType: ClientAuthenticationType;
}

export interface UpdateExternalClientAuthenticationTypeResponse {
  status: UpdateExternalClientAuthenticationTypeResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum UpdateExternalClientAuthenticationTypeResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

function createBaseExternalClientSettings(): ExternalClientSettings {
  return {
    version: 0,
    created: undefined,
    updated: undefined,
    callbackAddress: "",
    clientAuthenticationSettings: undefined,
  };
}

export const ExternalClientSettings = {
  encode(message: ExternalClientSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.version !== 0) {
      writer.uint32(8).int64(message.version);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(18).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(26).fork()).ldelim();
    }
    if (message.callbackAddress !== "") {
      writer.uint32(34).string(message.callbackAddress);
    }
    if (message.clientAuthenticationSettings !== undefined) {
      ClientAuthenticationSettings.encode(message.clientAuthenticationSettings, writer.uint32(42).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ExternalClientSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseExternalClientSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.callbackAddress = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.clientAuthenticationSettings = ClientAuthenticationSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ExternalClientSettings>, I>>(base?: I): ExternalClientSettings {
    return ExternalClientSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ExternalClientSettings>, I>>(object: I): ExternalClientSettings {
    const message = createBaseExternalClientSettings();
    message.version = object.version ?? 0;
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.callbackAddress = object.callbackAddress ?? "";
    message.clientAuthenticationSettings =
      (object.clientAuthenticationSettings !== undefined && object.clientAuthenticationSettings !== null)
        ? ClientAuthenticationSettings.fromPartial(object.clientAuthenticationSettings)
        : undefined;
    return message;
  },
};

function createBaseClientAuthenticationSettings(): ClientAuthenticationSettings {
  return { suportedClientAuthenticationTypes: [], currentAuthenticationType: 0 };
}

export const ClientAuthenticationSettings = {
  encode(message: ClientAuthenticationSettings, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    writer.uint32(10).fork();
    for (const v of message.suportedClientAuthenticationTypes) {
      writer.int32(v);
    }
    writer.ldelim();
    if (message.currentAuthenticationType !== 0) {
      writer.uint32(16).int32(message.currentAuthenticationType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientAuthenticationSettings {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientAuthenticationSettings();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag === 8) {
            message.suportedClientAuthenticationTypes.push(reader.int32() as any);

            continue;
          }

          if (tag === 10) {
            const end2 = reader.uint32() + reader.pos;
            while (reader.pos < end2) {
              message.suportedClientAuthenticationTypes.push(reader.int32() as any);
            }

            continue;
          }

          break;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.currentAuthenticationType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ClientAuthenticationSettings>, I>>(base?: I): ClientAuthenticationSettings {
    return ClientAuthenticationSettings.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ClientAuthenticationSettings>, I>>(object: I): ClientAuthenticationSettings {
    const message = createBaseClientAuthenticationSettings();
    message.suportedClientAuthenticationTypes = object.suportedClientAuthenticationTypes?.map((e) => e) || [];
    message.currentAuthenticationType = object.currentAuthenticationType ?? 0;
    return message;
  },
};

function createBaseGetExternalClientSettingsRequest(): GetExternalClientSettingsRequest {
  return {};
}

export const GetExternalClientSettingsRequest = {
  encode(_: GetExternalClientSettingsRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetExternalClientSettingsRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetExternalClientSettingsRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetExternalClientSettingsRequest>, I>>(
    base?: I,
  ): GetExternalClientSettingsRequest {
    return GetExternalClientSettingsRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetExternalClientSettingsRequest>, I>>(
    _: I,
  ): GetExternalClientSettingsRequest {
    const message = createBaseGetExternalClientSettingsRequest();
    return message;
  },
};

function createBaseGetExternalClientSettingsResponse(): GetExternalClientSettingsResponse {
  return { status: 0, error: undefined, settings: undefined };
}

export const GetExternalClientSettingsResponse = {
  encode(message: GetExternalClientSettingsResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.settings !== undefined) {
      ExternalClientSettings.encode(message.settings, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetExternalClientSettingsResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetExternalClientSettingsResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.settings = ExternalClientSettings.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetExternalClientSettingsResponse>, I>>(
    base?: I,
  ): GetExternalClientSettingsResponse {
    return GetExternalClientSettingsResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetExternalClientSettingsResponse>, I>>(
    object: I,
  ): GetExternalClientSettingsResponse {
    const message = createBaseGetExternalClientSettingsResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.settings = (object.settings !== undefined && object.settings !== null)
      ? ExternalClientSettings.fromPartial(object.settings)
      : undefined;
    return message;
  },
};

function createBaseUpdateExternalClientCallBackAddressRequest(): UpdateExternalClientCallBackAddressRequest {
  return { version: 0, callbackAddress: "" };
}

export const UpdateExternalClientCallBackAddressRequest = {
  encode(message: UpdateExternalClientCallBackAddressRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.version !== 0) {
      writer.uint32(8).int64(message.version);
    }
    if (message.callbackAddress !== "") {
      writer.uint32(18).string(message.callbackAddress);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateExternalClientCallBackAddressRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateExternalClientCallBackAddressRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.callbackAddress = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateExternalClientCallBackAddressRequest>, I>>(
    base?: I,
  ): UpdateExternalClientCallBackAddressRequest {
    return UpdateExternalClientCallBackAddressRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateExternalClientCallBackAddressRequest>, I>>(
    object: I,
  ): UpdateExternalClientCallBackAddressRequest {
    const message = createBaseUpdateExternalClientCallBackAddressRequest();
    message.version = object.version ?? 0;
    message.callbackAddress = object.callbackAddress ?? "";
    return message;
  },
};

function createBaseUpdateExternalClientCallBackAddressResponse(): UpdateExternalClientCallBackAddressResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const UpdateExternalClientCallBackAddressResponse = {
  encode(message: UpdateExternalClientCallBackAddressResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateExternalClientCallBackAddressResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateExternalClientCallBackAddressResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateExternalClientCallBackAddressResponse>, I>>(
    base?: I,
  ): UpdateExternalClientCallBackAddressResponse {
    return UpdateExternalClientCallBackAddressResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateExternalClientCallBackAddressResponse>, I>>(
    object: I,
  ): UpdateExternalClientCallBackAddressResponse {
    const message = createBaseUpdateExternalClientCallBackAddressResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseUpdateExternalClientAuthenticationTypeRequest(): UpdateExternalClientAuthenticationTypeRequest {
  return { version: 0, authenticationType: 0 };
}

export const UpdateExternalClientAuthenticationTypeRequest = {
  encode(message: UpdateExternalClientAuthenticationTypeRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.version !== 0) {
      writer.uint32(8).int64(message.version);
    }
    if (message.authenticationType !== 0) {
      writer.uint32(24).int32(message.authenticationType);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateExternalClientAuthenticationTypeRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateExternalClientAuthenticationTypeRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.authenticationType = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateExternalClientAuthenticationTypeRequest>, I>>(
    base?: I,
  ): UpdateExternalClientAuthenticationTypeRequest {
    return UpdateExternalClientAuthenticationTypeRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateExternalClientAuthenticationTypeRequest>, I>>(
    object: I,
  ): UpdateExternalClientAuthenticationTypeRequest {
    const message = createBaseUpdateExternalClientAuthenticationTypeRequest();
    message.version = object.version ?? 0;
    message.authenticationType = object.authenticationType ?? 0;
    return message;
  },
};

function createBaseUpdateExternalClientAuthenticationTypeResponse(): UpdateExternalClientAuthenticationTypeResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const UpdateExternalClientAuthenticationTypeResponse = {
  encode(
    message: UpdateExternalClientAuthenticationTypeResponse,
    writer: _m0.Writer = _m0.Writer.create(),
  ): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateExternalClientAuthenticationTypeResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateExternalClientAuthenticationTypeResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateExternalClientAuthenticationTypeResponse>, I>>(
    base?: I,
  ): UpdateExternalClientAuthenticationTypeResponse {
    return UpdateExternalClientAuthenticationTypeResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateExternalClientAuthenticationTypeResponse>, I>>(
    object: I,
  ): UpdateExternalClientAuthenticationTypeResponse {
    const message = createBaseUpdateExternalClientAuthenticationTypeResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
