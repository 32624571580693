import styles from './NavigationContainer.module.css';
import React, { PropsWithChildren } from 'react';
import { Outlet } from 'react-router-dom';

/**
 Our outer container for rendering our Navigation elements (header and menu) correctly.
 Takes 2 children only:
 - an instance of NavigationMenu
 - an instance of NavigationHeader
 structured this way so each child can take its own children, rather than ugly render... props
 */
export const NavigationContainer = ({
  children,
}: PropsWithChildren<object>) => {
  const arrayChildren = React.Children.toArray(children);

  return (
    <div className={styles.container}>
      {arrayChildren[0]}
      <div className={styles.mainSection}>
        {arrayChildren[1]}
        <div className={styles.content}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
