import styles from './SearchDefinitions.module.css';
import { useSearchDiscountDefinition } from 'discount-client-react/src/client/discountDefinition/useSearchDiscountDefinition/useSearchDiscountDefinition';
import { DiscountType } from 'mica-proto/src/micashared/common/enums/discounttype/v1/discount_type';
import { DiscountDefinitionStatus } from 'mica-proto/src/mica/discount/discountdefinition/v1/discount_definition';
import { TextInput } from 'mica-ui-kit/src/components';
import { AddIconButton } from 'mica-ui-kit/src/components/consoles/inputs/buttons/AddIconButton/AddIconButton';
import { Card } from 'mica-ui-kit/src/components/consoles/layout/Card/Card';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import { readableValueForEnum } from 'mica-ui-kit/src/utils/formatters/enums';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'mica-ui-kit/src/components/consoles/inputs/buttons/Button/Button';

const readableType = readableValueForEnum(DiscountType, 'DISCOUNT_TYPE_');
const readableStatus = readableValueForEnum(
  DiscountDefinitionStatus,
  'DISCOUNT_DEFINITION_STATUS_'
);

/**
 Our screen for searching for DiscountProviderAccounts
 */
const SearchDefinitions = () => {
  const [receiptDescription, setReceiptDescription] = useState('');

  const { discountDefinitions, isLoading, searchDiscountDefinition } =
    useSearchDiscountDefinition();

  const handleSearch = () =>
    searchDiscountDefinition({
      receiptDescription,
    });

  const navigate = useNavigate();

  return (
    <Page title={'Definitions'}>
      <Card
        title={'Search'}
        titleAccessories={
          <AddIconButton
            style={{ position: 'absolute', right: 20 }}
            onClick={() => navigate('create')}
          />
        }>
        <div className={styles.searchInputs}>
          <TextInput
            onChange={setReceiptDescription}
            value={receiptDescription}
            label={'Receipt Description'}
            style={{ width: 280 }}
          />
          <Button
            className={'mt-20'}
            label={'Search'}
            loading={isLoading}
            onClick={handleSearch}
          />
        </div>

        <div className={styles.results}>
          <table className={'table'} style={{ opacity: isLoading ? 0.6 : 1 }}>
            <thead>
              <tr>
                <th>Type</th>
                <th>Ref</th>
                <th>Receipt Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {discountDefinitions.map((def) => (
                <tr
                  className={'pointer'}
                  key={def.discountDefinitionKey}
                  onClick={() =>
                    navigate(`/app/definitions/${def.discountDefinitionRef}`)
                  }>
                  <td>{readableType(def.type)}</td>
                  <td>{def.discountDefinitionRef}</td>
                  <td>{def.receiptDescription}</td>
                  <td>{readableStatus(def.status)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </Page>
  );
};

export default SearchDefinitions;
