/**
 * A hook for fetching the current Settings
 */
import { useDiscountClient } from '../../useDiscountClient/useDiscountClient';
import { useQuery } from 'react-query';

export const useSettings = () => {
  const client = useDiscountClient();
  return useQuery(['getExternalClientSettings'], () =>
    client.getExternalClientSettings({})
  );
};
