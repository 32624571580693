import { useBoolean } from './useBoolean';

export const useBooleanWithClickListener = (startValue = false) => {
  const value = useBoolean(startValue);

  const handleSetFalseOnClick = () => {
    // delay ensures if someone clicks on the element that fires onSetTrue, that
    // event flushes before we set false
    setTimeout(() => {
      value.setFalse();
    }, 150);

    window.removeEventListener('pointerdown', handleSetFalseOnClick);
  };

  const setTrue = () => {
    if (!value.value) {
      value.setTrue();
      window.addEventListener('pointerdown', handleSetFalseOnClick);
    }
  };

  return {
    ...value,
    setTrue,
  };
};
