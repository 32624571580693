import { IconButton, IconButtonProps } from '../IconButton/IconButton';
import { FaPlus } from '@react-icons/all-files/fa/FaPlus';
import React from 'react';

/**
 Our Add icon button
 */
export const AddIconButton = ({
  className,
  label = 'Add',
  onClick,
  style,
}: Omit<IconButtonProps, 'label'> & { label?: string }) => (
  <IconButton
    label={label}
    style={style}
    className={className}
    onClick={onClick}>
    <FaPlus />
  </IconButton>
);
