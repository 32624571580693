import styles from './Backdrop.module.scss';
import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { PropsWithChildren, MouseEventHandler } from 'react';

interface Props {
  active: boolean;
  onClick?: MouseEventHandler;
}

export const Backdrop = ({
  active,
  children,
  onClick,
}: PropsWithChildren<Props>) => {
  return (
    <AnimatePresence>
      {active && (
        <motion.div
          className={styles.container}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}>
          <div onClick={onClick} className={styles.backdrop} />
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
