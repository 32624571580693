import { useToastError } from '../../../../../../../hooks/toast/useToastError/useToastError';
import { TextArea } from '../../../../../../inputs';
import CheckboxList from '../../../../../inputs/CheckboxList/CheckboxList';
import { Button } from '../../../../../inputs/buttons/Button/Button';
import { Page } from '../../../../../layout/Page/Page';
import Wizard from '../../../../../layout/Wizard/Wizard';
import { InfoBox } from '../../../../../layout/text/InfoBox/InfoBox';
import styles from './CreateMtlsCert.module.css';
import CsrHelpPopover from './CsrHelpPopover/CsrHelpPopover';
import {
  GenerateMTLSCertificateRequest,
  GenerateMTLSCertificateResponse,
  GenerateMTLSCertificateResponse_Status,
} from 'mica-proto/src/micashared/common/v1/mtls_certificate';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import MtlsCertExpiryInput from './MtlsCertExpiryInput/MtlsCertExpiryInput';

type UseGenerateMTLSCertificate = () => {
  generateMTLSCertificate: (args: GenerateMTLSCertificateRequest) => void;
  isLoading: boolean;
  data?: GenerateMTLSCertificateResponse;
};

type Role = {
  label: string;
  value: string;
};

type CreateMtlsCertProps = {
  fixedRole?: string;
  roleOptions?: Role[];
  useGenerateMTLSCertificate: UseGenerateMTLSCertificate;
};

const wizardSteps = [
  {
    title: 'Provide Certificate Signing Request (CSR)',
    titleAccessories: <CsrHelpPopover />,
  },
  {
    title: 'Store Signed Certificate in your system',
  },
];

const expiryDurationString = (expiryDuration: Date | number) =>
  `${
    typeof expiryDuration === 'number'
      ? expiryDuration
      : dayjs(expiryDuration).diff(dayjs(), 'hour')
  }h`;

/**
 Our screen/wizard for creating an MTLS cert - common to a handful of our consoles
 */
export const CreateMtlsCert = ({
  fixedRole,
  roleOptions = [],
  useGenerateMTLSCertificate,
}: CreateMtlsCertProps) => {
  const [pemCsr, setPemCsr] = useState('');
  const [roles, setRoles] = useState<Array<string>>(
    fixedRole ? [fixedRole] : []
  );
  const [expiryDuration, setExpiryDuration] = useState<Date | number>(8760);

  const navigate = useNavigate();

  const { generateMTLSCertificate, isLoading, data } =
    useGenerateMTLSCertificate();

  const handleGenerateCertificate = () =>
    generateMTLSCertificate({
      expireInDuration: expiryDurationString(expiryDuration),
      roles,
      csr: {
        pemCsr,
      },
    });

  useToastError(data);

  const handleCopyBundle = async () => {
    await navigator.clipboard.writeText(data!.certificate!.pemBundle);
    toast.success('PEM bundle copied to clipboard');
  };

  const handleCopyCert = async () => {
    await navigator.clipboard.writeText(data!.certificate!.pemCertificate);
    toast.success('PEM certificate copied to clipboard');
  };

  return (
    <Page title={'Create Certificate'}>
      <Wizard
        isComplete={false}
        stepIndex={
          data?.status === GenerateMTLSCertificateResponse_Status.STATUS_SUCCESS
            ? 1
            : 0
        }
        stepTitles={wizardSteps}>
        <>
          <InfoBox
            className={'mb-10'}
            message={'Generate a CSR and paste it below for Mica to sign'}
          />
          <TextArea
            inputClassName={styles.textArea}
            onChange={setPemCsr}
            value={pemCsr}
            label={'CSR PEM'}
            useMonospaceFont
          />
          {roleOptions.length > 0 && (
            <CheckboxList
              className={'mt-10 mb-20'}
              label={'Roles/Permissions'}
              onChange={setRoles}
              options={roleOptions}
              value={roles}
            />
          )}
          <MtlsCertExpiryInput
            expiry={expiryDuration}
            onExpiryChanged={setExpiryDuration}
          />

          <div className={'flex w-full justify-ctr'}>
            <Button
              className={'button'}
              label={'SUBMIT CSR'}
              loading={isLoading}
              onClick={handleGenerateCertificate}
            />
          </div>
        </>
        <>
          <InfoBox
            className={'mt-10'}
            message={
              "Copy the CSR below and sign it with your private key. In the next step, you'll paste the signed credentials"
            }
          />
          <TextArea
            style={{
              height: 250,
              maxWidth: 500,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
            disabled
            label={'PEM Certificate'}
            // eslint-disable-next-line
            onChange={() => {}}
            value={data?.certificate?.pemCertificate ?? ''}
            useMonospaceFont
          />
          <button className={styles.copyButton} onClick={handleCopyCert}>
            COPY TO CLIPBOARD
          </button>

          <TextArea
            style={{
              height: 250,
              maxWidth: 500,
              borderTopLeftRadius: 5,
              borderTopRightRadius: 5,
            }}
            disabled
            label={'PEM Bundle'}
            // eslint-disable-next-line
            onChange={() => {}}
            value={data?.certificate?.pemBundle ?? ''}
            useMonospaceFont
          />
          <button className={styles.copyButton} onClick={handleCopyBundle}>
            COPY TO CLIPBOARD
          </button>
          <div className={'flex w-full justify-ctr mt-20'}>
            <Button
              className={'button'}
              onClick={() => navigate('/app/settings/network')}
              label={'DONE'}
            />
          </div>
        </>
      </Wizard>
    </Page>
  );
};
