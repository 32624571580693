import { IconButton, IconButtonProps } from '../IconButton/IconButton';
import { FaEdit } from '@react-icons/all-files/fa/FaEdit';
import React from 'react';

/**
 Our EDIT icon button
 */
export const EditIconButton = ({
  className,
  onClick,
  style,
}: Omit<IconButtonProps, 'label'>) => (
  <IconButton
    label={'Edit'}
    style={style}
    className={className}
    onClick={onClick}>
    <FaEdit />
  </IconButton>
);
