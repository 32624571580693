import { useDiscountClient } from '../../useDiscountClient/useDiscountClient';
import { useMutation, useQueryClient } from 'react-query';

/**
 * A hook for updating settings
 */
export const useUpdateCallbackAddress = () => {
  const client = useDiscountClient();
  const queryClient = useQueryClient();
  const { mutate, ...rest } = useMutation(
    client.updateExternalClientCallbackAddress,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('getExternalClientSettings');
      },
    }
  );

  return {
    updateCallbackAddress: mutate,
    ...rest,
  };
};
