import { DiscountProviderAccountStatus } from 'mica-proto/src/mica/discount/discountprovideraccount/v1/discount_provider_account';
import { useCreateUpdateForm } from 'mica-shared-react/src/hooks/useCreateUpdateForm';
import { Button, LoadingButton, Select } from 'mica-ui-kit/src/components';
import { enumAsSelectOptions } from 'mica-ui-kit/src/utils/formatters/enums';
import { Card } from 'mica-ui-kit/src/components/consoles/layout/Card/Card';
import { TextInput } from 'mica-ui-kit/src/components/inputs/text/TextInput/TextInput';
import { BankAccountForm } from 'mica-ui-kit/src/components/layout/forms/BankAccountForm/BankAccountForm';
import React from 'react';

const statusOptions = enumAsSelectOptions(
  DiscountProviderAccountStatus,
  'DISCOUNT_PROVIDER_ACCOUNT_STATUS_'
);

export const defaultDiscountProviderAccount = {
  discountProviderAccountRef: '',
  name: '',
  discountProviderAccountStatus:
    DiscountProviderAccountStatus.DISCOUNT_PROVIDER_ACCOUNT_STATUS_ACTIVE,
};

type Input = typeof defaultDiscountProviderAccount & {
  bankAccountDetail?: {
    domesticAchRoutingNumber: string;
    internationalAchRoutingNumber: string;
    wireRoutingNumber: string;
    swiftRoutingNumber: string;
    bankAccountNumber: string;
  };
  version?: number;
};

type DiscountProviderAccountFormProps<BaseRecord extends Input> = {
  discountProviderAccount?: BaseRecord;
  loading?: boolean;
  onSave: (input: BaseRecord) => void;
};

const isValid = (displayData: Input) =>
  !!displayData.discountProviderAccountRef && displayData.name.length > 3;

/**
 The create/update form for a discount provider account
 */
const DiscountProviderAccountForm = <BaseRecord extends Input>({
  discountProviderAccount,
  loading,
  onSave,
}: DiscountProviderAccountFormProps<BaseRecord>) => {
  const { displayData, updateProperty } = useCreateUpdateForm(
    discountProviderAccount || (defaultDiscountProviderAccount as BaseRecord)
  );
  const handleSave = () => onSave(displayData);

  return (
    <Card>
      <div style={{ width: 320 }}>
        <TextInput
          autoFocus
          className={'mb-20'}
          style={{ width: 320 }}
          onChange={updateProperty('discountProviderAccountRef')}
          value={displayData.discountProviderAccountRef}
          label={'Internal Ref/ID'}
          required
        />
        <TextInput
          className={'mb-20'}
          style={{ width: 320 }}
          onChange={updateProperty('name')}
          value={displayData.name}
          label={'Name'}
          required
        />
        <Select
          label={'Status'}
          onChange={updateProperty('discountProviderAccountStatus')}
          options={statusOptions}
          value={displayData.discountProviderAccountStatus}
        />
        <Button
          className={'my-30'}
          onClick={() => alert('Mica Connect Coming Soon!')}
          title={'Link Account via Mica Connect'}
        />
        <i className={'mb-10'}>
          <b>OR</b> Provide at least one of the following:
        </i>
        <BankAccountForm
          bankAccount={displayData.bankAccountDetail}
          onChange={updateProperty('bankAccountDetail')}
        />
        <LoadingButton
          className={'mt-20'}
          disabled={!isValid(displayData)}
          loading={loading}
          onClick={handleSave}
          title={discountProviderAccount ? 'UPDATE' : 'CREATE'}
        />
      </div>
    </Card>
  );
};

export default DiscountProviderAccountForm;
