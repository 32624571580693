import { useDiscountClient } from '../../useDiscountClient/useDiscountClient';
import { useMutation, useQueryClient } from 'react-query';

export const useCreateDiscountDefinition = () => {
  const client = useDiscountClient();
  const queryClient = useQueryClient();

  const { mutate: createDiscountDefinition, ...rest } = useMutation(
    client.createDiscountDefinition,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('searchDiscountDefinition');
      },
    }
  );

  return {
    createDiscountDefinition,
    ...rest,
  };
};
