import micaLogoWhite from '../../../../../assets/images/micaLogoWhite.png';
import { Button } from '../../../inputs/buttons/Button/Button';
import styles from './Login.module.css';
import React from 'react';

type LoginProps = {
  appName: string;
  loading: boolean;
  onLoginClicked: () => void;
};

/**
 The common login screen for our consoles - we only require a button since we use Okta for the auth
 */
export const Login = ({ appName, loading, onLoginClicked }: LoginProps) => (
  <div className={styles.container}>
    <div className={styles.welcome}>Welcome to the Mica {appName}</div>
    <div className={styles.logoContainer}>
      <img src={micaLogoWhite} className={styles.logo} />
      <div className={styles.sheen} />
    </div>
    <Button
      className={styles.button}
      loading={loading}
      label={'START'}
      onClick={onLoginClicked}
    />
  </div>
);
