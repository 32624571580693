import DiscountDefinitionForm from '../../../shared/definitions/DiscountDefinitionForm/DiscountDefinitionForm';
import { useCreateDiscountDefinition } from 'discount-client-react/src/client/discountDefinition/useCreateDiscountDefinition/useCreateDiscountDefinition';
import { CreateDiscountDefinitionRequest } from 'mica-proto/src/mica/discount/discountdefinition/v1/discount_definition';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

/**
 Our screen/form to create a new Discount Definition
 */
const CreateDefinition = () => {
  const { createDiscountDefinition, isLoading } = useCreateDiscountDefinition();

  const navigate = useNavigate();

  const handleCreate = (definition: CreateDiscountDefinitionRequest) =>
    createDiscountDefinition(definition, {
      onSuccess: () => {
        toast.success('Definition Created');
        navigate('/app/definitions/' + definition.discountDefinitionRef);
      },
    });

  return (
    <Page title={'Create Discount Definition'}>
      <DiscountDefinitionForm onSave={handleCreate} loading={isLoading} />
    </Page>
  );
};

export default CreateDefinition;
