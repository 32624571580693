import { useSearchExternalClientMTLSCertificate } from 'discount-client-react/src/client/certs/useSearchExternalClientMTLSCertificate/useSearchExternalClientMTLSCertificate';
import { useSearchMTLSCertificate } from 'discount-client-react/src/client/certs/useSearchMTLSCertificate/useSearchMTLSCertificate';
import { useApiTokenConfiguration } from 'discount-client-react/src/client/settings/useApiTokenConfiguration/useApiTokenConfiguration';
import { useCreateApiTokenConfiguration } from 'discount-client-react/src/client/settings/useCreateApiTokenConfiguration/useCreateApiTokenConfiguration';
import { useSettings } from 'discount-client-react/src/client/settings/useSettings/useSettings';
import { useUpdateApiTokenConfiguration } from 'discount-client-react/src/client/settings/useUpdateApiTokenConfiguration/useUpdateApiTokenConfiguration';
import { useUpdateCallbackAddress } from 'discount-client-react/src/client/settings/useUpdateCallbackAddress/useUpdateCallbackAddress';
import { ApiTokenConfiguration } from 'mica-proto/src/micashared/common/v1/authentication_token';
import { NetworkSettings } from 'mica-ui-kit/src/components/consoles/screens/NetworkSettings/NetworkSettings';
import React from 'react';

/**
 Our Network Configuration screen - where admin users can change the callbackAddress and mtls certs
 */
const NetworkConfig = () => {
  const { data: settingsData } = useSettings();
  const { data: mtlsCertData } = useSearchMTLSCertificate();
  const { data: clientCertData } = useSearchExternalClientMTLSCertificate();
  const { data: apiData } = useApiTokenConfiguration();

  const { updateCallbackAddress } = useUpdateCallbackAddress();
  const { createApiTokenConfiguration, isLoading: createApiConfigLoading } =
    useCreateApiTokenConfiguration();
  const { updateApiTokenConfiguration, isLoading: updateApiConfigLoading } =
    useUpdateApiTokenConfiguration();

  const externalClientSettings = settingsData?.settings;

  const mtlsCertificates = mtlsCertData?.certificates || [];
  const clientCertificates = clientCertData?.certificates || [];

  const handleSaveApiTokenConfig = (config: ApiTokenConfiguration) =>
    apiData?.apiTokenConfiguration
      ? updateApiTokenConfiguration(config)
      : createApiTokenConfiguration(config);

  const handleUpdateCallbackAddress = (callbackAddress: string) =>
    updateCallbackAddress({
      callbackAddress,
      version: externalClientSettings!.version ?? 0,
    });

  return (
    <NetworkSettings
      apiTokenConfiguration={apiData?.apiTokenConfiguration}
      saveApiTokenConfigLoading={
        createApiConfigLoading || updateApiConfigLoading
      }
      settings={externalClientSettings}
      onSaveApiTokenConfig={handleSaveApiTokenConfig}
      onUpdateCallbackAddress={handleUpdateCallbackAddress}
      mtlsCertificates={mtlsCertificates}
      clientMtlsCertificates={clientCertificates}
    />
  );
};

export default NetworkConfig;
