import { SentryRoutes } from './init';
import Home from '../screens/Home/Home';
import NetworkSettings from '../screens/Settings/Network/NetworkSettings';
import ClientMtlsCert from '../screens/Settings/certs/ClientMtlsCert/ClientMtlsCert';
import CreateClientMTLSCert from '../screens/Settings/certs/CreateClientMTLSCert/CreateClientMTLSCert';
import CreateMTLSCert from '../screens/Settings/certs/CreateMTLSCert/CreateMTLSCert';
import MtlsCert from '../screens/Settings/certs/MtlsCert/MtlsCert';
import Account from '../screens/accounts/Account/Account';
import CreateAccount from '../screens/accounts/CreateAccount/CreateAccount';
import SearchAccounts from '../screens/accounts/SearchAccounts/SearchAccounts';
import NavigationLayout from '../screens/containers/NavigationLayout/NavigationLayout';
import CreateDefinition from '../screens/definitions/CreateDefinition/CreateDefinition';
import Definition from '../screens/definitions/Definition/Definition';
import SearchDefinitions from '../screens/definitions/SearchDefinitions/SearchDefinitions';
import CreateDiscount from '../screens/discounts/CreateDiscount/CreateDiscount';
import Discount from '../screens/discounts/Discount/Discount';
import SearchDiscounts from '../screens/discounts/SearchDiscounts/SearchDiscounts';
import CreateProductGroup from '../screens/products/CreateProductGroup/CreateProductGroup';
import ProductGroup from '../screens/products/ProductGroup/ProductGroup';
import SearchProductGroups from '../screens/products/SearchProductGroups/SearchProductGroups';
import styles from './App.module.css';
import { LoginCallback } from '@okta/okta-react';
import { LoadingOverlay } from 'mica-ui-kit/src/components';
import { OktaAuthRouteContainer } from 'mica-ui-kit/src/components/auth/okta/OktaAuthRouteContainer/OktaAuthRouteContainer';
import { OktaWrapper } from 'mica-ui-kit/src/components/auth/okta/OktaWrapper/OktaWrapper';
import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';

const App = () => {
  return (
    <div className={styles.container}>
      <OktaWrapper goAppLocalPort={14102} requiresTenant>
        <Suspense fallback={<LoadingOverlay />}>
          <SentryRoutes>
            <Route path="/app">
              <Route path="auth/login/callback" element={<LoginCallback />} />
              <Route
                path={''}
                element={
                  <OktaAuthRouteContainer appName={'Discount Console'} />
                }>
                <Route path={''} element={<NavigationLayout />}>
                  <Route path="" element={<Home />} />
                  <Route path={'accounts'}>
                    <Route path={''} element={<SearchAccounts />} />
                    <Route path={'create'} element={<CreateAccount />} />
                    <Route
                      path={':discountProviderAccountRef'}
                      element={<Account />}
                    />
                  </Route>
                  <Route path={'products'}>
                    <Route path={''} element={<SearchProductGroups />} />
                    <Route path={'create'} element={<CreateProductGroup />} />
                    <Route
                      path={':productGroupRef'}
                      element={<ProductGroup />}
                    />
                  </Route>
                  <Route path={'definitions'}>
                    <Route path={''} element={<SearchDefinitions />} />
                    <Route path={'create'} element={<CreateDefinition />} />
                    <Route
                      path={':discountDefinitionRef'}
                      element={<Definition />}
                    />
                  </Route>
                  <Route path={'discounts'}>
                    <Route path={''} element={<SearchDiscounts />} />
                    <Route path={'create'} element={<CreateDiscount />} />
                    <Route path={':discountRef'} element={<Discount />} />
                  </Route>

                  <Route path="settings">
                    <Route path="network" element={<NetworkSettings />} />
                    <Route path={'certs'}>
                      <Route path={'mtls'}>
                        <Route path="new" element={<CreateMTLSCert />} />
                        <Route path=":serialNumber" element={<MtlsCert />} />
                      </Route>
                      <Route path={'client'}>
                        <Route path="new" element={<CreateClientMTLSCert />} />
                        <Route
                          path=":certificateRefKey"
                          element={<ClientMtlsCert />}
                        />
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
          </SentryRoutes>
        </Suspense>
      </OktaWrapper>
    </div>
  );
};

export default App;
