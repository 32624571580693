/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../google/protobuf/timestamp";
import { Currency } from "../../../../micashared/common/enums/currency/v1/currency";
import { Region } from "../../../../micashared/common/enums/region/v1/region";
import { Error } from "../../../../micashared/common/v1/error";

export const protobufPackage = "mica.partner.transaction.v1";

export enum IntervalType {
  INTERVAL_TYPE_UNSPECIFIED = 0,
  INTERVAL_TYPE_HOUR = 1,
  INTERVAL_TYPE_DAY = 2,
  INTERVAL_TYPE_WEEK = 3,
  INTERVAL_TYPE_MONTH = 4,
  INTERVAL_TYPE_YEAR = 5,
  INTERVAL_TYPE_SECOND = 60,
  INTERVAL_TYPE_MINUTE = 61,
}

export enum TransactionType {
  TRANSACTION_TYPE_UNSPECIFIED = 0,
  TRANSACTION_TYPE_OBTAIN_APPROVAL = 1,
  TRANSACTION_TYPE_OBTAIN_DECLINE = 2,
  TRANSACTION_TYPE_RETURN_APPROVAL = 3,
  TRANSACTION_TYPE_RETURN_DECLINE = 4,
  TRANSACTION_TYPE_OBTAIN_NETWORK_DECLINE = 5,
  TRANSACTION_TYPE_RETURN_NETWORK_DECLINE = 6,
}

export interface SearchTransactionDataRequest {
  /** start of data range inclusive */
  dateFrom:
    | Date
    | undefined;
  /** end of data range exclusive - optional */
  dateTo:
    | Date
    | undefined;
  /** optional */
  intervalType: IntervalType;
  /**
   * the number of records to return
   * optional
   */
  topN: number;
  /** optional */
  transactionType: TransactionType;
  /** optional */
  currency: Currency;
  /** optional */
  organizationKey: string;
  /** optional */
  storeKey: string;
  /** minimum amount inclusive - optional */
  minAmount: string;
  /** maximum amount exclusive - optional */
  maxAmount: string;
}

export interface CountAmountItem {
  date: Date | undefined;
  currency: Currency;
  obtainApprovalCount: number;
  obtainApprovalAmount: string;
  obtainDeclineCount: number;
  obtainDeclineAmount: string;
  obtainNetworkDeclineCount: number;
  obtainNetworkDeclineAmount: string;
  returnApprovalCount: number;
  returnApprovalAmount: string;
  returnDeclineCount: number;
  returnDeclineAmount: string;
  returnNetworkDeclineCount: number;
  returnNetworkDeclineAmount: string;
}

export interface SearchTransactionDataResponse {
  status: SearchTransactionDataResponse_Status;
  error: Error | undefined;
  cid: string;
  countAmountItems: CountAmountItem[];
}

export enum SearchTransactionDataResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface SearchTransactionGeographyDataRequest {
  /** start of data range inclusive */
  dateFrom:
    | Date
    | undefined;
  /** end of data range exclusive */
  dateTo:
    | Date
    | undefined;
  /** optional */
  intervalType: IntervalType;
  /**
   * the number of records to return
   * optional
   */
  topN: number;
  /** optional */
  transactionType: TransactionType;
  /** optional */
  currency: Currency;
  organizationKey: string;
  storeKey: string;
  region: Region;
  postalCode: string;
  /** minimum amount inclusive - optional */
  minAmount: string;
  /** maximum amount exclusive - optional */
  maxAmount: string;
}

export interface SearchTransactionGeographyDataItem {
  latitude: number;
  longitude: number;
  countAmountItems: CountAmountItem[];
}

export interface SearchTransactionGeographyDataResponse {
  status: SearchTransactionGeographyDataResponse_Status;
  error: Error | undefined;
  cid: string;
  items: SearchTransactionGeographyDataItem[];
}

export enum SearchTransactionGeographyDataResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface SearchTransactionServiceProviderDataRequest {
  /** start of data range inclusive */
  dateFrom:
    | Date
    | undefined;
  /** end of data range exclusive */
  dateTo:
    | Date
    | undefined;
  /** optional */
  intervalType: IntervalType;
  /**
   * the number of records to return
   * optional
   */
  topN: number;
  /** optional */
  transactionType: TransactionType;
  /** optional */
  currency: Currency;
  organizationKey: string;
  storeKey: string;
  /** minimum amount inclusive - optional */
  minAmount: string;
  /** maximum amount exclusive - optional */
  maxAmount: string;
}

export interface SearchTransactionServiceProviderDataItem {
  serviceProviderKey: string;
  serviceProviderName: string;
  countAmountItem: CountAmountItem | undefined;
}

export interface SearchTransactionServiceProviderDataResponse {
  status: SearchTransactionServiceProviderDataResponse_Status;
  error: Error | undefined;
  cid: string;
  items: SearchTransactionServiceProviderDataItem[];
}

export enum SearchTransactionServiceProviderDataResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

function createBaseSearchTransactionDataRequest(): SearchTransactionDataRequest {
  return {
    dateFrom: undefined,
    dateTo: undefined,
    intervalType: 0,
    topN: 0,
    transactionType: 0,
    currency: 0,
    organizationKey: "",
    storeKey: "",
    minAmount: "",
    maxAmount: "",
  };
}

export const SearchTransactionDataRequest = {
  encode(message: SearchTransactionDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dateFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.dateFrom), writer.uint32(10).fork()).ldelim();
    }
    if (message.dateTo !== undefined) {
      Timestamp.encode(toTimestamp(message.dateTo), writer.uint32(18).fork()).ldelim();
    }
    if (message.intervalType !== 0) {
      writer.uint32(24).int32(message.intervalType);
    }
    if (message.topN !== 0) {
      writer.uint32(32).int64(message.topN);
    }
    if (message.transactionType !== 0) {
      writer.uint32(40).int32(message.transactionType);
    }
    if (message.currency !== 0) {
      writer.uint32(48).int32(message.currency);
    }
    if (message.organizationKey !== "") {
      writer.uint32(58).string(message.organizationKey);
    }
    if (message.storeKey !== "") {
      writer.uint32(66).string(message.storeKey);
    }
    if (message.minAmount !== "") {
      writer.uint32(74).string(message.minAmount);
    }
    if (message.maxAmount !== "") {
      writer.uint32(82).string(message.maxAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchTransactionDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchTransactionDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dateFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dateTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.intervalType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.topN = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.transactionType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.organizationKey = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.storeKey = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.minAmount = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.maxAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchTransactionDataRequest>, I>>(base?: I): SearchTransactionDataRequest {
    return SearchTransactionDataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchTransactionDataRequest>, I>>(object: I): SearchTransactionDataRequest {
    const message = createBaseSearchTransactionDataRequest();
    message.dateFrom = object.dateFrom ?? undefined;
    message.dateTo = object.dateTo ?? undefined;
    message.intervalType = object.intervalType ?? 0;
    message.topN = object.topN ?? 0;
    message.transactionType = object.transactionType ?? 0;
    message.currency = object.currency ?? 0;
    message.organizationKey = object.organizationKey ?? "";
    message.storeKey = object.storeKey ?? "";
    message.minAmount = object.minAmount ?? "";
    message.maxAmount = object.maxAmount ?? "";
    return message;
  },
};

function createBaseCountAmountItem(): CountAmountItem {
  return {
    date: undefined,
    currency: 0,
    obtainApprovalCount: 0,
    obtainApprovalAmount: "",
    obtainDeclineCount: 0,
    obtainDeclineAmount: "",
    obtainNetworkDeclineCount: 0,
    obtainNetworkDeclineAmount: "",
    returnApprovalCount: 0,
    returnApprovalAmount: "",
    returnDeclineCount: 0,
    returnDeclineAmount: "",
    returnNetworkDeclineCount: 0,
    returnNetworkDeclineAmount: "",
  };
}

export const CountAmountItem = {
  encode(message: CountAmountItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.date !== undefined) {
      Timestamp.encode(toTimestamp(message.date), writer.uint32(10).fork()).ldelim();
    }
    if (message.currency !== 0) {
      writer.uint32(16).int32(message.currency);
    }
    if (message.obtainApprovalCount !== 0) {
      writer.uint32(24).int64(message.obtainApprovalCount);
    }
    if (message.obtainApprovalAmount !== "") {
      writer.uint32(34).string(message.obtainApprovalAmount);
    }
    if (message.obtainDeclineCount !== 0) {
      writer.uint32(40).int64(message.obtainDeclineCount);
    }
    if (message.obtainDeclineAmount !== "") {
      writer.uint32(50).string(message.obtainDeclineAmount);
    }
    if (message.obtainNetworkDeclineCount !== 0) {
      writer.uint32(56).int64(message.obtainNetworkDeclineCount);
    }
    if (message.obtainNetworkDeclineAmount !== "") {
      writer.uint32(66).string(message.obtainNetworkDeclineAmount);
    }
    if (message.returnApprovalCount !== 0) {
      writer.uint32(72).int64(message.returnApprovalCount);
    }
    if (message.returnApprovalAmount !== "") {
      writer.uint32(82).string(message.returnApprovalAmount);
    }
    if (message.returnDeclineCount !== 0) {
      writer.uint32(88).int64(message.returnDeclineCount);
    }
    if (message.returnDeclineAmount !== "") {
      writer.uint32(98).string(message.returnDeclineAmount);
    }
    if (message.returnNetworkDeclineCount !== 0) {
      writer.uint32(104).int64(message.returnNetworkDeclineCount);
    }
    if (message.returnNetworkDeclineAmount !== "") {
      writer.uint32(114).string(message.returnNetworkDeclineAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CountAmountItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCountAmountItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.date = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.obtainApprovalCount = longToNumber(reader.int64() as Long);
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.obtainApprovalAmount = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.obtainDeclineCount = longToNumber(reader.int64() as Long);
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.obtainDeclineAmount = reader.string();
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.obtainNetworkDeclineCount = longToNumber(reader.int64() as Long);
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.obtainNetworkDeclineAmount = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.returnApprovalCount = longToNumber(reader.int64() as Long);
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.returnApprovalAmount = reader.string();
          continue;
        case 11:
          if (tag !== 88) {
            break;
          }

          message.returnDeclineCount = longToNumber(reader.int64() as Long);
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.returnDeclineAmount = reader.string();
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.returnNetworkDeclineCount = longToNumber(reader.int64() as Long);
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.returnNetworkDeclineAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CountAmountItem>, I>>(base?: I): CountAmountItem {
    return CountAmountItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CountAmountItem>, I>>(object: I): CountAmountItem {
    const message = createBaseCountAmountItem();
    message.date = object.date ?? undefined;
    message.currency = object.currency ?? 0;
    message.obtainApprovalCount = object.obtainApprovalCount ?? 0;
    message.obtainApprovalAmount = object.obtainApprovalAmount ?? "";
    message.obtainDeclineCount = object.obtainDeclineCount ?? 0;
    message.obtainDeclineAmount = object.obtainDeclineAmount ?? "";
    message.obtainNetworkDeclineCount = object.obtainNetworkDeclineCount ?? 0;
    message.obtainNetworkDeclineAmount = object.obtainNetworkDeclineAmount ?? "";
    message.returnApprovalCount = object.returnApprovalCount ?? 0;
    message.returnApprovalAmount = object.returnApprovalAmount ?? "";
    message.returnDeclineCount = object.returnDeclineCount ?? 0;
    message.returnDeclineAmount = object.returnDeclineAmount ?? "";
    message.returnNetworkDeclineCount = object.returnNetworkDeclineCount ?? 0;
    message.returnNetworkDeclineAmount = object.returnNetworkDeclineAmount ?? "";
    return message;
  },
};

function createBaseSearchTransactionDataResponse(): SearchTransactionDataResponse {
  return { status: 0, error: undefined, cid: "", countAmountItems: [] };
}

export const SearchTransactionDataResponse = {
  encode(message: SearchTransactionDataResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.cid !== "") {
      writer.uint32(26).string(message.cid);
    }
    for (const v of message.countAmountItems) {
      CountAmountItem.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchTransactionDataResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchTransactionDataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.countAmountItems.push(CountAmountItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchTransactionDataResponse>, I>>(base?: I): SearchTransactionDataResponse {
    return SearchTransactionDataResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchTransactionDataResponse>, I>>(
    object: I,
  ): SearchTransactionDataResponse {
    const message = createBaseSearchTransactionDataResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.cid = object.cid ?? "";
    message.countAmountItems = object.countAmountItems?.map((e) => CountAmountItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSearchTransactionGeographyDataRequest(): SearchTransactionGeographyDataRequest {
  return {
    dateFrom: undefined,
    dateTo: undefined,
    intervalType: 0,
    topN: 0,
    transactionType: 0,
    currency: 0,
    organizationKey: "",
    storeKey: "",
    region: 0,
    postalCode: "",
    minAmount: "",
    maxAmount: "",
  };
}

export const SearchTransactionGeographyDataRequest = {
  encode(message: SearchTransactionGeographyDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dateFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.dateFrom), writer.uint32(10).fork()).ldelim();
    }
    if (message.dateTo !== undefined) {
      Timestamp.encode(toTimestamp(message.dateTo), writer.uint32(18).fork()).ldelim();
    }
    if (message.intervalType !== 0) {
      writer.uint32(24).int32(message.intervalType);
    }
    if (message.topN !== 0) {
      writer.uint32(32).int64(message.topN);
    }
    if (message.transactionType !== 0) {
      writer.uint32(40).int32(message.transactionType);
    }
    if (message.currency !== 0) {
      writer.uint32(48).int32(message.currency);
    }
    if (message.organizationKey !== "") {
      writer.uint32(58).string(message.organizationKey);
    }
    if (message.storeKey !== "") {
      writer.uint32(66).string(message.storeKey);
    }
    if (message.region !== 0) {
      writer.uint32(72).int32(message.region);
    }
    if (message.postalCode !== "") {
      writer.uint32(82).string(message.postalCode);
    }
    if (message.minAmount !== "") {
      writer.uint32(90).string(message.minAmount);
    }
    if (message.maxAmount !== "") {
      writer.uint32(98).string(message.maxAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchTransactionGeographyDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchTransactionGeographyDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dateFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dateTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.intervalType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.topN = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.transactionType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.organizationKey = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.storeKey = reader.string();
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.region = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.postalCode = reader.string();
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.minAmount = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.maxAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchTransactionGeographyDataRequest>, I>>(
    base?: I,
  ): SearchTransactionGeographyDataRequest {
    return SearchTransactionGeographyDataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchTransactionGeographyDataRequest>, I>>(
    object: I,
  ): SearchTransactionGeographyDataRequest {
    const message = createBaseSearchTransactionGeographyDataRequest();
    message.dateFrom = object.dateFrom ?? undefined;
    message.dateTo = object.dateTo ?? undefined;
    message.intervalType = object.intervalType ?? 0;
    message.topN = object.topN ?? 0;
    message.transactionType = object.transactionType ?? 0;
    message.currency = object.currency ?? 0;
    message.organizationKey = object.organizationKey ?? "";
    message.storeKey = object.storeKey ?? "";
    message.region = object.region ?? 0;
    message.postalCode = object.postalCode ?? "";
    message.minAmount = object.minAmount ?? "";
    message.maxAmount = object.maxAmount ?? "";
    return message;
  },
};

function createBaseSearchTransactionGeographyDataItem(): SearchTransactionGeographyDataItem {
  return { latitude: 0, longitude: 0, countAmountItems: [] };
}

export const SearchTransactionGeographyDataItem = {
  encode(message: SearchTransactionGeographyDataItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.latitude !== 0) {
      writer.uint32(9).double(message.latitude);
    }
    if (message.longitude !== 0) {
      writer.uint32(17).double(message.longitude);
    }
    for (const v of message.countAmountItems) {
      CountAmountItem.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchTransactionGeographyDataItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchTransactionGeographyDataItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 9) {
            break;
          }

          message.latitude = reader.double();
          continue;
        case 2:
          if (tag !== 17) {
            break;
          }

          message.longitude = reader.double();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.countAmountItems.push(CountAmountItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchTransactionGeographyDataItem>, I>>(
    base?: I,
  ): SearchTransactionGeographyDataItem {
    return SearchTransactionGeographyDataItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchTransactionGeographyDataItem>, I>>(
    object: I,
  ): SearchTransactionGeographyDataItem {
    const message = createBaseSearchTransactionGeographyDataItem();
    message.latitude = object.latitude ?? 0;
    message.longitude = object.longitude ?? 0;
    message.countAmountItems = object.countAmountItems?.map((e) => CountAmountItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSearchTransactionGeographyDataResponse(): SearchTransactionGeographyDataResponse {
  return { status: 0, error: undefined, cid: "", items: [] };
}

export const SearchTransactionGeographyDataResponse = {
  encode(message: SearchTransactionGeographyDataResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.cid !== "") {
      writer.uint32(26).string(message.cid);
    }
    for (const v of message.items) {
      SearchTransactionGeographyDataItem.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchTransactionGeographyDataResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchTransactionGeographyDataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.items.push(SearchTransactionGeographyDataItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchTransactionGeographyDataResponse>, I>>(
    base?: I,
  ): SearchTransactionGeographyDataResponse {
    return SearchTransactionGeographyDataResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchTransactionGeographyDataResponse>, I>>(
    object: I,
  ): SearchTransactionGeographyDataResponse {
    const message = createBaseSearchTransactionGeographyDataResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.cid = object.cid ?? "";
    message.items = object.items?.map((e) => SearchTransactionGeographyDataItem.fromPartial(e)) || [];
    return message;
  },
};

function createBaseSearchTransactionServiceProviderDataRequest(): SearchTransactionServiceProviderDataRequest {
  return {
    dateFrom: undefined,
    dateTo: undefined,
    intervalType: 0,
    topN: 0,
    transactionType: 0,
    currency: 0,
    organizationKey: "",
    storeKey: "",
    minAmount: "",
    maxAmount: "",
  };
}

export const SearchTransactionServiceProviderDataRequest = {
  encode(message: SearchTransactionServiceProviderDataRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.dateFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.dateFrom), writer.uint32(10).fork()).ldelim();
    }
    if (message.dateTo !== undefined) {
      Timestamp.encode(toTimestamp(message.dateTo), writer.uint32(18).fork()).ldelim();
    }
    if (message.intervalType !== 0) {
      writer.uint32(24).int32(message.intervalType);
    }
    if (message.topN !== 0) {
      writer.uint32(32).int64(message.topN);
    }
    if (message.transactionType !== 0) {
      writer.uint32(40).int32(message.transactionType);
    }
    if (message.currency !== 0) {
      writer.uint32(48).int32(message.currency);
    }
    if (message.organizationKey !== "") {
      writer.uint32(58).string(message.organizationKey);
    }
    if (message.storeKey !== "") {
      writer.uint32(66).string(message.storeKey);
    }
    if (message.minAmount !== "") {
      writer.uint32(74).string(message.minAmount);
    }
    if (message.maxAmount !== "") {
      writer.uint32(82).string(message.maxAmount);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchTransactionServiceProviderDataRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchTransactionServiceProviderDataRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.dateFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.dateTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.intervalType = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.topN = longToNumber(reader.int64() as Long);
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.transactionType = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.organizationKey = reader.string();
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.storeKey = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.minAmount = reader.string();
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.maxAmount = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchTransactionServiceProviderDataRequest>, I>>(
    base?: I,
  ): SearchTransactionServiceProviderDataRequest {
    return SearchTransactionServiceProviderDataRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchTransactionServiceProviderDataRequest>, I>>(
    object: I,
  ): SearchTransactionServiceProviderDataRequest {
    const message = createBaseSearchTransactionServiceProviderDataRequest();
    message.dateFrom = object.dateFrom ?? undefined;
    message.dateTo = object.dateTo ?? undefined;
    message.intervalType = object.intervalType ?? 0;
    message.topN = object.topN ?? 0;
    message.transactionType = object.transactionType ?? 0;
    message.currency = object.currency ?? 0;
    message.organizationKey = object.organizationKey ?? "";
    message.storeKey = object.storeKey ?? "";
    message.minAmount = object.minAmount ?? "";
    message.maxAmount = object.maxAmount ?? "";
    return message;
  },
};

function createBaseSearchTransactionServiceProviderDataItem(): SearchTransactionServiceProviderDataItem {
  return { serviceProviderKey: "", serviceProviderName: "", countAmountItem: undefined };
}

export const SearchTransactionServiceProviderDataItem = {
  encode(message: SearchTransactionServiceProviderDataItem, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serviceProviderKey !== "") {
      writer.uint32(10).string(message.serviceProviderKey);
    }
    if (message.serviceProviderName !== "") {
      writer.uint32(18).string(message.serviceProviderName);
    }
    if (message.countAmountItem !== undefined) {
      CountAmountItem.encode(message.countAmountItem, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchTransactionServiceProviderDataItem {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchTransactionServiceProviderDataItem();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serviceProviderKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.serviceProviderName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.countAmountItem = CountAmountItem.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchTransactionServiceProviderDataItem>, I>>(
    base?: I,
  ): SearchTransactionServiceProviderDataItem {
    return SearchTransactionServiceProviderDataItem.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchTransactionServiceProviderDataItem>, I>>(
    object: I,
  ): SearchTransactionServiceProviderDataItem {
    const message = createBaseSearchTransactionServiceProviderDataItem();
    message.serviceProviderKey = object.serviceProviderKey ?? "";
    message.serviceProviderName = object.serviceProviderName ?? "";
    message.countAmountItem = (object.countAmountItem !== undefined && object.countAmountItem !== null)
      ? CountAmountItem.fromPartial(object.countAmountItem)
      : undefined;
    return message;
  },
};

function createBaseSearchTransactionServiceProviderDataResponse(): SearchTransactionServiceProviderDataResponse {
  return { status: 0, error: undefined, cid: "", items: [] };
}

export const SearchTransactionServiceProviderDataResponse = {
  encode(message: SearchTransactionServiceProviderDataResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.cid !== "") {
      writer.uint32(26).string(message.cid);
    }
    for (const v of message.items) {
      SearchTransactionServiceProviderDataItem.encode(v!, writer.uint32(34).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchTransactionServiceProviderDataResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchTransactionServiceProviderDataResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.cid = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.items.push(SearchTransactionServiceProviderDataItem.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchTransactionServiceProviderDataResponse>, I>>(
    base?: I,
  ): SearchTransactionServiceProviderDataResponse {
    return SearchTransactionServiceProviderDataResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchTransactionServiceProviderDataResponse>, I>>(
    object: I,
  ): SearchTransactionServiceProviderDataResponse {
    const message = createBaseSearchTransactionServiceProviderDataResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.cid = object.cid ?? "";
    message.items = object.items?.map((e) => SearchTransactionServiceProviderDataItem.fromPartial(e)) || [];
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
