import { MaterialUiStyleLabel } from '../../layout/MaterialUiStyleLabel/MaterialUiStyleLabel';
import InputAccessoryText, {
  InputAccessoryTextProps,
} from '../InputAccessoryText/InputAccessoryText';
import styles from './Select.module.css';
import cx from 'classnames';
import React, { CSSProperties, useMemo } from 'react';

type SelectOption = {
  label: string;
  value: string;
};

type SelectProps = InputAccessoryTextProps & {
  className?: string;
  inputClassName?: string;
  label?: string;
  labelClassName?: string;
  name?: string;
  noUndefinedOption?: boolean;
  onChange: (value: string) => void;
  options: string[] | SelectOption[];
  style?: CSSProperties;
  value: string | number;
};

/**
 Our standard select component
 */
export const Select = ({
  className,
  error,
  info,
  inputClassName,
  label,
  labelClassName,
  name,
  noUndefinedOption,
  onChange,
  options,
  required,
  style,
  value,
}: SelectProps) => {
  const selectOptions = useMemo<SelectOption[]>(
    () =>
      options.map((o) => (typeof o === 'string' ? { label: o, value: o } : o)),
    [options]
  );

  const hasValue = !!value || value === 0;

  return (
    <div className={cx(styles.container, className)}>
      <select
        className={cx(styles.select, inputClassName)}
        aria-label={label}
        name={name}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        style={style}>
        {!noUndefinedOption && <option value={''}>{''}</option>}
        {selectOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {label && (
        <MaterialUiStyleLabel
          isReduced={hasValue}
          className={labelClassName}
          value={label}
        />
      )}
      <InputAccessoryText
        error={error}
        required={!hasValue && required}
        info={info}
      />
    </div>
  );
};
