import { grpc } from '@improbable-eng/grpc-web';
import { GrpcWebError } from 'mica-proto/src/mica/discount/service/v1/discount_to_mica_service';
import UnaryOutput = grpc.UnaryOutput;

interface UnaryMethodDefinitionishR
  extends grpc.UnaryMethodDefinition<any, any> {
  requestStream: any;
  responseStream: any;
}

type UnaryMethodDefinitionish = UnaryMethodDefinitionishR;

type Metadata = () => Record<string, any> | Record<string, any>;

class GrpcWebImpl {
  private host: string;
  private options: {
    transport?: grpc.TransportFactory;
    debug?: boolean;
    metadata?: Metadata;
    upStreamRetryCodes?: number[];
  };

  constructor(
    host: string,
    options: {
      transport?: grpc.TransportFactory;
      debug?: boolean;
      metadata?: Metadata;
      upStreamRetryCodes?: number[];
    }
  ) {
    this.host = host;
    this.options = options;
  }

  unary<T extends UnaryMethodDefinitionish>(
    methodDesc: T,
    _request: any,
    metadata?: Record<string, any>
  ): Promise<any> {
    const request = { ..._request, ...methodDesc.requestType };

    const oMetadata = this.options.metadata;

    const defaultMetadata =
      typeof oMetadata === 'function' ? oMetadata() : oMetadata;

    const maybeCombinedMetadata =
      metadata && defaultMetadata
        ? {
            ...defaultMetadata,
            ...metadata,
          }
        : metadata || defaultMetadata;

    return new Promise((resolve, reject) => {
      grpc.unary(methodDesc, {
        request,
        host: this.host,
        metadata: maybeCombinedMetadata,
        transport: this.options.transport,
        debug: this.options.debug,
        onEnd: function (response: UnaryOutput<any>) {
          // we might return a GRPC OK response, but with an error:
          if (response.message?.error) {
            reject(response.message.error);
          }

          if (response.status === grpc.Code.OK) {
            resolve(response.message);
          } else {
            const err = new GrpcWebError(
              response.statusMessage,
              response.status,
              response.trailers
            );
            reject(err);
          }
        },
      });
    });
  }
}

interface IBaseClient<T> {
  new (rpc: GrpcWebImpl): T;
}

export type MakeGrpcClientArgs = {
  debug?: boolean;
  host: string;
  metadata?: Metadata;
  transport?: ReturnType<typeof grpc.CrossBrowserHttpTransport>;
};

export const makeGrpcClient =
  <ClientType>(Client: IBaseClient<ClientType>) =>
  ({
    host,
    metadata,
    transport = grpc.CrossBrowserHttpTransport({ withCredentials: false }),
    debug = false,
  }: MakeGrpcClientArgs): ClientType => {
    const rpc = new GrpcWebImpl(host, {
      debug,
      metadata,
      transport,
    });

    return new Client(rpc);
  };
