/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { Error } from "./error";

export const protobufPackage = "micashared.common.v1";

export interface MTLSCertificateSignatureRequest {
  /** PEM encoded string */
  pemCsr: string;
}

export interface MTLSCertificate {
  /** Serial number of the generated certificate */
  serialNumber: string;
  issued: Date | undefined;
  expiry:
    | Date
    | undefined;
  /** whether this certificate can be used or has been disallowed for access regardless of expiration */
  enabled: boolean;
  /** The pem encoded certificate generated */
  pemCertificate: string;
  /** The pem encoded issuing CA */
  pemIssuingCa: string;
  /** The pem encoded certificate chain */
  pemCaChain: string[];
  /** Aggregate all in one pem bundle */
  pemBundle: string;
  /** The roles the certificate has */
  roles: string[];
}

export interface GenerateMTLSCertificateRequest {
  csr:
    | MTLSCertificateSignatureRequest
    | undefined;
  /** TODO we should probably enum this with the few roles that are allowed for mTLS? */
  roles: string[];
  expireInDuration: string;
}

export interface GenerateMTLSCertificateResponse {
  status: GenerateMTLSCertificateResponse_Status;
  error: Error | undefined;
  certificate: MTLSCertificate | undefined;
}

export enum GenerateMTLSCertificateResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface UpdateMTLSCertificateRequest {
  serialNumber: string;
  enabled: boolean;
}

export interface UpdateMTLSCertificateResponse {
  status: UpdateMTLSCertificateResponse_Status;
  error: Error | undefined;
  serialNumber: string;
}

export enum UpdateMTLSCertificateResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface GetMTLSCertificateRequest {
  serialNumber: string;
}

export interface GetMTLSCertificateResponse {
  status: GetMTLSCertificateResponse_Status;
  error: Error | undefined;
  certificate: MTLSCertificate | undefined;
}

export enum GetMTLSCertificateResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_NOT_FOUND = 2,
  STATUS_ERROR = 3,
}

/** Nothing for now; we will add criteria as we add console use-cases. */
export interface SearchMTLSCertificateRequest {
}

export interface SearchMTLSCertificateResponse {
  status: SearchMTLSCertificateResponse_Status;
  error: Error | undefined;
  certificates: MTLSCertificate[];
}

export enum SearchMTLSCertificateResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface ClientMTLSCertificate {
  /** Used for lookups NOT the actual private key. */
  certificateRefKey: string;
  displayName: string;
  crtPem: string;
  csrPem: string;
  rootcaBundlePem: string;
  enabled: boolean;
}

/** We generate the db entry and return the csr to the user so they can sign it. */
export interface GenerateExternalClientMTLSCertificateRequest {
  displayName: string;
}

export interface GenerateExternalClientMTLSCertificateResponse {
  status: GenerateExternalClientMTLSCertificateResponse_Status;
  error:
    | Error
    | undefined;
  /** This will be returned with certificate_ref_key, display_name, csr_pem, and enabled. */
  certificateToSign: ClientMTLSCertificate | undefined;
}

export enum GenerateExternalClientMTLSCertificateResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface UpdateExternalClientMTLSCertificateRequest {
  certificateRefKey: string;
  displayName: string;
  signedCertPemFromCsr: string;
  rootcaBundlePem: string;
  enabled: boolean;
}

export interface UpdateExternalClientMTLSCertificateResponse {
  status: UpdateExternalClientMTLSCertificateResponse_Status;
  error: Error | undefined;
  certificate: ClientMTLSCertificate | undefined;
}

export enum UpdateExternalClientMTLSCertificateResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface SearchExternalClientMTLSCertificateRequest {
}

export interface SearchExternalClientMTLSCertificateResponse {
  status: SearchExternalClientMTLSCertificateResponse_Status;
  error: Error | undefined;
  certificates: ClientMTLSCertificate[];
}

export enum SearchExternalClientMTLSCertificateResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

function createBaseMTLSCertificateSignatureRequest(): MTLSCertificateSignatureRequest {
  return { pemCsr: "" };
}

export const MTLSCertificateSignatureRequest = {
  encode(message: MTLSCertificateSignatureRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.pemCsr !== "") {
      writer.uint32(10).string(message.pemCsr);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MTLSCertificateSignatureRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMTLSCertificateSignatureRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.pemCsr = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<MTLSCertificateSignatureRequest>, I>>(base?: I): MTLSCertificateSignatureRequest {
    return MTLSCertificateSignatureRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MTLSCertificateSignatureRequest>, I>>(
    object: I,
  ): MTLSCertificateSignatureRequest {
    const message = createBaseMTLSCertificateSignatureRequest();
    message.pemCsr = object.pemCsr ?? "";
    return message;
  },
};

function createBaseMTLSCertificate(): MTLSCertificate {
  return {
    serialNumber: "",
    issued: undefined,
    expiry: undefined,
    enabled: false,
    pemCertificate: "",
    pemIssuingCa: "",
    pemCaChain: [],
    pemBundle: "",
    roles: [],
  };
}

export const MTLSCertificate = {
  encode(message: MTLSCertificate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    if (message.issued !== undefined) {
      Timestamp.encode(toTimestamp(message.issued), writer.uint32(18).fork()).ldelim();
    }
    if (message.expiry !== undefined) {
      Timestamp.encode(toTimestamp(message.expiry), writer.uint32(26).fork()).ldelim();
    }
    if (message.enabled === true) {
      writer.uint32(32).bool(message.enabled);
    }
    if (message.pemCertificate !== "") {
      writer.uint32(42).string(message.pemCertificate);
    }
    if (message.pemIssuingCa !== "") {
      writer.uint32(50).string(message.pemIssuingCa);
    }
    for (const v of message.pemCaChain) {
      writer.uint32(58).string(v!);
    }
    if (message.pemBundle !== "") {
      writer.uint32(66).string(message.pemBundle);
    }
    for (const v of message.roles) {
      writer.uint32(74).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): MTLSCertificate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseMTLSCertificate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.issued = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.expiry = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.enabled = reader.bool();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.pemCertificate = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.pemIssuingCa = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.pemCaChain.push(reader.string());
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.pemBundle = reader.string();
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.roles.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<MTLSCertificate>, I>>(base?: I): MTLSCertificate {
    return MTLSCertificate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<MTLSCertificate>, I>>(object: I): MTLSCertificate {
    const message = createBaseMTLSCertificate();
    message.serialNumber = object.serialNumber ?? "";
    message.issued = object.issued ?? undefined;
    message.expiry = object.expiry ?? undefined;
    message.enabled = object.enabled ?? false;
    message.pemCertificate = object.pemCertificate ?? "";
    message.pemIssuingCa = object.pemIssuingCa ?? "";
    message.pemCaChain = object.pemCaChain?.map((e) => e) || [];
    message.pemBundle = object.pemBundle ?? "";
    message.roles = object.roles?.map((e) => e) || [];
    return message;
  },
};

function createBaseGenerateMTLSCertificateRequest(): GenerateMTLSCertificateRequest {
  return { csr: undefined, roles: [], expireInDuration: "" };
}

export const GenerateMTLSCertificateRequest = {
  encode(message: GenerateMTLSCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.csr !== undefined) {
      MTLSCertificateSignatureRequest.encode(message.csr, writer.uint32(10).fork()).ldelim();
    }
    for (const v of message.roles) {
      writer.uint32(26).string(v!);
    }
    if (message.expireInDuration !== "") {
      writer.uint32(34).string(message.expireInDuration);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateMTLSCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateMTLSCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.csr = MTLSCertificateSignatureRequest.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.roles.push(reader.string());
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.expireInDuration = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GenerateMTLSCertificateRequest>, I>>(base?: I): GenerateMTLSCertificateRequest {
    return GenerateMTLSCertificateRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GenerateMTLSCertificateRequest>, I>>(
    object: I,
  ): GenerateMTLSCertificateRequest {
    const message = createBaseGenerateMTLSCertificateRequest();
    message.csr = (object.csr !== undefined && object.csr !== null)
      ? MTLSCertificateSignatureRequest.fromPartial(object.csr)
      : undefined;
    message.roles = object.roles?.map((e) => e) || [];
    message.expireInDuration = object.expireInDuration ?? "";
    return message;
  },
};

function createBaseGenerateMTLSCertificateResponse(): GenerateMTLSCertificateResponse {
  return { status: 0, error: undefined, certificate: undefined };
}

export const GenerateMTLSCertificateResponse = {
  encode(message: GenerateMTLSCertificateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.certificate !== undefined) {
      MTLSCertificate.encode(message.certificate, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateMTLSCertificateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateMTLSCertificateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.certificate = MTLSCertificate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GenerateMTLSCertificateResponse>, I>>(base?: I): GenerateMTLSCertificateResponse {
    return GenerateMTLSCertificateResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GenerateMTLSCertificateResponse>, I>>(
    object: I,
  ): GenerateMTLSCertificateResponse {
    const message = createBaseGenerateMTLSCertificateResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.certificate = (object.certificate !== undefined && object.certificate !== null)
      ? MTLSCertificate.fromPartial(object.certificate)
      : undefined;
    return message;
  },
};

function createBaseUpdateMTLSCertificateRequest(): UpdateMTLSCertificateRequest {
  return { serialNumber: "", enabled: false };
}

export const UpdateMTLSCertificateRequest = {
  encode(message: UpdateMTLSCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    if (message.enabled === true) {
      writer.uint32(16).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMTLSCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMTLSCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateMTLSCertificateRequest>, I>>(base?: I): UpdateMTLSCertificateRequest {
    return UpdateMTLSCertificateRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateMTLSCertificateRequest>, I>>(object: I): UpdateMTLSCertificateRequest {
    const message = createBaseUpdateMTLSCertificateRequest();
    message.serialNumber = object.serialNumber ?? "";
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseUpdateMTLSCertificateResponse(): UpdateMTLSCertificateResponse {
  return { status: 0, error: undefined, serialNumber: "" };
}

export const UpdateMTLSCertificateResponse = {
  encode(message: UpdateMTLSCertificateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.serialNumber !== "") {
      writer.uint32(26).string(message.serialNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateMTLSCertificateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateMTLSCertificateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateMTLSCertificateResponse>, I>>(base?: I): UpdateMTLSCertificateResponse {
    return UpdateMTLSCertificateResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateMTLSCertificateResponse>, I>>(
    object: I,
  ): UpdateMTLSCertificateResponse {
    const message = createBaseUpdateMTLSCertificateResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.serialNumber = object.serialNumber ?? "";
    return message;
  },
};

function createBaseGetMTLSCertificateRequest(): GetMTLSCertificateRequest {
  return { serialNumber: "" };
}

export const GetMTLSCertificateRequest = {
  encode(message: GetMTLSCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.serialNumber !== "") {
      writer.uint32(10).string(message.serialNumber);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMTLSCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMTLSCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.serialNumber = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetMTLSCertificateRequest>, I>>(base?: I): GetMTLSCertificateRequest {
    return GetMTLSCertificateRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetMTLSCertificateRequest>, I>>(object: I): GetMTLSCertificateRequest {
    const message = createBaseGetMTLSCertificateRequest();
    message.serialNumber = object.serialNumber ?? "";
    return message;
  },
};

function createBaseGetMTLSCertificateResponse(): GetMTLSCertificateResponse {
  return { status: 0, error: undefined, certificate: undefined };
}

export const GetMTLSCertificateResponse = {
  encode(message: GetMTLSCertificateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.certificate !== undefined) {
      MTLSCertificate.encode(message.certificate, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetMTLSCertificateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetMTLSCertificateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.certificate = MTLSCertificate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetMTLSCertificateResponse>, I>>(base?: I): GetMTLSCertificateResponse {
    return GetMTLSCertificateResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetMTLSCertificateResponse>, I>>(object: I): GetMTLSCertificateResponse {
    const message = createBaseGetMTLSCertificateResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.certificate = (object.certificate !== undefined && object.certificate !== null)
      ? MTLSCertificate.fromPartial(object.certificate)
      : undefined;
    return message;
  },
};

function createBaseSearchMTLSCertificateRequest(): SearchMTLSCertificateRequest {
  return {};
}

export const SearchMTLSCertificateRequest = {
  encode(_: SearchMTLSCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchMTLSCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchMTLSCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchMTLSCertificateRequest>, I>>(base?: I): SearchMTLSCertificateRequest {
    return SearchMTLSCertificateRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchMTLSCertificateRequest>, I>>(_: I): SearchMTLSCertificateRequest {
    const message = createBaseSearchMTLSCertificateRequest();
    return message;
  },
};

function createBaseSearchMTLSCertificateResponse(): SearchMTLSCertificateResponse {
  return { status: 0, error: undefined, certificates: [] };
}

export const SearchMTLSCertificateResponse = {
  encode(message: SearchMTLSCertificateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.certificates) {
      MTLSCertificate.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchMTLSCertificateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchMTLSCertificateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.certificates.push(MTLSCertificate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchMTLSCertificateResponse>, I>>(base?: I): SearchMTLSCertificateResponse {
    return SearchMTLSCertificateResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchMTLSCertificateResponse>, I>>(
    object: I,
  ): SearchMTLSCertificateResponse {
    const message = createBaseSearchMTLSCertificateResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.certificates = object.certificates?.map((e) => MTLSCertificate.fromPartial(e)) || [];
    return message;
  },
};

function createBaseClientMTLSCertificate(): ClientMTLSCertificate {
  return { certificateRefKey: "", displayName: "", crtPem: "", csrPem: "", rootcaBundlePem: "", enabled: false };
}

export const ClientMTLSCertificate = {
  encode(message: ClientMTLSCertificate, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.certificateRefKey !== "") {
      writer.uint32(10).string(message.certificateRefKey);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.crtPem !== "") {
      writer.uint32(26).string(message.crtPem);
    }
    if (message.csrPem !== "") {
      writer.uint32(34).string(message.csrPem);
    }
    if (message.rootcaBundlePem !== "") {
      writer.uint32(42).string(message.rootcaBundlePem);
    }
    if (message.enabled === true) {
      writer.uint32(48).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ClientMTLSCertificate {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseClientMTLSCertificate();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificateRefKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.crtPem = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.csrPem = reader.string();
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.rootcaBundlePem = reader.string();
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ClientMTLSCertificate>, I>>(base?: I): ClientMTLSCertificate {
    return ClientMTLSCertificate.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ClientMTLSCertificate>, I>>(object: I): ClientMTLSCertificate {
    const message = createBaseClientMTLSCertificate();
    message.certificateRefKey = object.certificateRefKey ?? "";
    message.displayName = object.displayName ?? "";
    message.crtPem = object.crtPem ?? "";
    message.csrPem = object.csrPem ?? "";
    message.rootcaBundlePem = object.rootcaBundlePem ?? "";
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseGenerateExternalClientMTLSCertificateRequest(): GenerateExternalClientMTLSCertificateRequest {
  return { displayName: "" };
}

export const GenerateExternalClientMTLSCertificateRequest = {
  encode(message: GenerateExternalClientMTLSCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.displayName !== "") {
      writer.uint32(10).string(message.displayName);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateExternalClientMTLSCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateExternalClientMTLSCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.displayName = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GenerateExternalClientMTLSCertificateRequest>, I>>(
    base?: I,
  ): GenerateExternalClientMTLSCertificateRequest {
    return GenerateExternalClientMTLSCertificateRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GenerateExternalClientMTLSCertificateRequest>, I>>(
    object: I,
  ): GenerateExternalClientMTLSCertificateRequest {
    const message = createBaseGenerateExternalClientMTLSCertificateRequest();
    message.displayName = object.displayName ?? "";
    return message;
  },
};

function createBaseGenerateExternalClientMTLSCertificateResponse(): GenerateExternalClientMTLSCertificateResponse {
  return { status: 0, error: undefined, certificateToSign: undefined };
}

export const GenerateExternalClientMTLSCertificateResponse = {
  encode(message: GenerateExternalClientMTLSCertificateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.certificateToSign !== undefined) {
      ClientMTLSCertificate.encode(message.certificateToSign, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GenerateExternalClientMTLSCertificateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGenerateExternalClientMTLSCertificateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.certificateToSign = ClientMTLSCertificate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GenerateExternalClientMTLSCertificateResponse>, I>>(
    base?: I,
  ): GenerateExternalClientMTLSCertificateResponse {
    return GenerateExternalClientMTLSCertificateResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GenerateExternalClientMTLSCertificateResponse>, I>>(
    object: I,
  ): GenerateExternalClientMTLSCertificateResponse {
    const message = createBaseGenerateExternalClientMTLSCertificateResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.certificateToSign = (object.certificateToSign !== undefined && object.certificateToSign !== null)
      ? ClientMTLSCertificate.fromPartial(object.certificateToSign)
      : undefined;
    return message;
  },
};

function createBaseUpdateExternalClientMTLSCertificateRequest(): UpdateExternalClientMTLSCertificateRequest {
  return { certificateRefKey: "", displayName: "", signedCertPemFromCsr: "", rootcaBundlePem: "", enabled: false };
}

export const UpdateExternalClientMTLSCertificateRequest = {
  encode(message: UpdateExternalClientMTLSCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.certificateRefKey !== "") {
      writer.uint32(10).string(message.certificateRefKey);
    }
    if (message.displayName !== "") {
      writer.uint32(18).string(message.displayName);
    }
    if (message.signedCertPemFromCsr !== "") {
      writer.uint32(26).string(message.signedCertPemFromCsr);
    }
    if (message.rootcaBundlePem !== "") {
      writer.uint32(34).string(message.rootcaBundlePem);
    }
    if (message.enabled === true) {
      writer.uint32(40).bool(message.enabled);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateExternalClientMTLSCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateExternalClientMTLSCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.certificateRefKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.displayName = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.signedCertPemFromCsr = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.rootcaBundlePem = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.enabled = reader.bool();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateExternalClientMTLSCertificateRequest>, I>>(
    base?: I,
  ): UpdateExternalClientMTLSCertificateRequest {
    return UpdateExternalClientMTLSCertificateRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateExternalClientMTLSCertificateRequest>, I>>(
    object: I,
  ): UpdateExternalClientMTLSCertificateRequest {
    const message = createBaseUpdateExternalClientMTLSCertificateRequest();
    message.certificateRefKey = object.certificateRefKey ?? "";
    message.displayName = object.displayName ?? "";
    message.signedCertPemFromCsr = object.signedCertPemFromCsr ?? "";
    message.rootcaBundlePem = object.rootcaBundlePem ?? "";
    message.enabled = object.enabled ?? false;
    return message;
  },
};

function createBaseUpdateExternalClientMTLSCertificateResponse(): UpdateExternalClientMTLSCertificateResponse {
  return { status: 0, error: undefined, certificate: undefined };
}

export const UpdateExternalClientMTLSCertificateResponse = {
  encode(message: UpdateExternalClientMTLSCertificateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.certificate !== undefined) {
      ClientMTLSCertificate.encode(message.certificate, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateExternalClientMTLSCertificateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateExternalClientMTLSCertificateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.certificate = ClientMTLSCertificate.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateExternalClientMTLSCertificateResponse>, I>>(
    base?: I,
  ): UpdateExternalClientMTLSCertificateResponse {
    return UpdateExternalClientMTLSCertificateResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateExternalClientMTLSCertificateResponse>, I>>(
    object: I,
  ): UpdateExternalClientMTLSCertificateResponse {
    const message = createBaseUpdateExternalClientMTLSCertificateResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.certificate = (object.certificate !== undefined && object.certificate !== null)
      ? ClientMTLSCertificate.fromPartial(object.certificate)
      : undefined;
    return message;
  },
};

function createBaseSearchExternalClientMTLSCertificateRequest(): SearchExternalClientMTLSCertificateRequest {
  return {};
}

export const SearchExternalClientMTLSCertificateRequest = {
  encode(_: SearchExternalClientMTLSCertificateRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchExternalClientMTLSCertificateRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchExternalClientMTLSCertificateRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchExternalClientMTLSCertificateRequest>, I>>(
    base?: I,
  ): SearchExternalClientMTLSCertificateRequest {
    return SearchExternalClientMTLSCertificateRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchExternalClientMTLSCertificateRequest>, I>>(
    _: I,
  ): SearchExternalClientMTLSCertificateRequest {
    const message = createBaseSearchExternalClientMTLSCertificateRequest();
    return message;
  },
};

function createBaseSearchExternalClientMTLSCertificateResponse(): SearchExternalClientMTLSCertificateResponse {
  return { status: 0, error: undefined, certificates: [] };
}

export const SearchExternalClientMTLSCertificateResponse = {
  encode(message: SearchExternalClientMTLSCertificateResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.certificates) {
      ClientMTLSCertificate.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchExternalClientMTLSCertificateResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchExternalClientMTLSCertificateResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.certificates.push(ClientMTLSCertificate.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchExternalClientMTLSCertificateResponse>, I>>(
    base?: I,
  ): SearchExternalClientMTLSCertificateResponse {
    return SearchExternalClientMTLSCertificateResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchExternalClientMTLSCertificateResponse>, I>>(
    object: I,
  ): SearchExternalClientMTLSCertificateResponse {
    const message = createBaseSearchExternalClientMTLSCertificateResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.certificates = object.certificates?.map((e) => ClientMTLSCertificate.fromPartial(e)) || [];
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}
