/**
 * www.lj5bgu.members.mica.io: www.lj5bgu.members.mica.io/management/identity
 * http://www.lj5bgu.localhost:3001/app : http://www.lj5bgu.localhost:13402/management/identity
 * www.discount.mica.io : www.discount.mica.io/management/identity
 */

export const identityUrl = (
  host: string,
  goAppLocalPort: number,
  tenant?: string
) => {
  const url =
    host.includes('127.0.0.1:') || host.includes('localhost:')
      ? `localhost:${goAppLocalPort}`
      : host;

  return `${url}/management/identity${tenant ? `?tenant=${tenant}` : ''}`;
};
