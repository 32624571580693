import { TextInput } from '../../../inputs';
import MonetaryAmountForm from '../MonetaryAmountForm/MonetaryAmountForm';
import styles from './PercentageAmountForm.module.css';
import cx from 'classnames';
import { PercentageAmount } from 'mica-proto/src/mica/discount/discount/v1/discount';
import React from 'react';

type PercentageAmountFormProps = {
  amount?: Partial<PercentageAmount>;
  className?: string;
  labels?: [string, string];
  onChange: (update: Partial<PercentageAmount>) => void;
};

/**
 A Component
 */
const PercentageAmountForm = ({
  amount,
  className,
  labels = ['Percent Amount', 'Percent Tax Amount'],
  onChange,
}: PercentageAmountFormProps) => {
  const handleChange = (property: string) => (value: any) =>
    onChange({
      ...amount,
      [property]: value,
    });

  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.row}>
        <TextInput
          className={styles.input}
          label={labels[0]}
          onChange={handleChange('percentageAmount')}
          value={amount?.percentageAmount || ''}
        />
        <TextInput
          className={styles.input}
          label={labels[1]}
          onChange={handleChange('percentageTaxAmount')}
          value={amount?.percentageTaxAmount || ''}
        />
      </div>
      <MonetaryAmountForm
        amount={amount?.maxAmount}
        className={styles.monetaryAmount}
        labels={['Max Value', 'Max Tax Value']}
        onChange={handleChange('maxAmount')}
      />
    </div>
  );
};

export default PercentageAmountForm;
