/* eslint-disable */
import _m0 from "protobufjs/minimal";
import { Error } from "../../../../micashared/common/v1/error";
import { UserDemographic } from "../../../../micashared/common/v1/user";

export const protobufPackage = "mica.discount.user.v1";

export interface GetUserKeyRequest {
  userDemographic: UserDemographic | undefined;
}

export interface GetUserKeyResponse {
  status: GetUserKeyResponse_Status;
  error: Error | undefined;
  userKey: string;
}

export enum GetUserKeyResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

function createBaseGetUserKeyRequest(): GetUserKeyRequest {
  return { userDemographic: undefined };
}

export const GetUserKeyRequest = {
  encode(message: GetUserKeyRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.userDemographic !== undefined) {
      UserDemographic.encode(message.userDemographic, writer.uint32(10).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserKeyRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserKeyRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.userDemographic = UserDemographic.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetUserKeyRequest>, I>>(base?: I): GetUserKeyRequest {
    return GetUserKeyRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetUserKeyRequest>, I>>(object: I): GetUserKeyRequest {
    const message = createBaseGetUserKeyRequest();
    message.userDemographic = (object.userDemographic !== undefined && object.userDemographic !== null)
      ? UserDemographic.fromPartial(object.userDemographic)
      : undefined;
    return message;
  },
};

function createBaseGetUserKeyResponse(): GetUserKeyResponse {
  return { status: 0, error: undefined, userKey: "" };
}

export const GetUserKeyResponse = {
  encode(message: GetUserKeyResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.userKey !== "") {
      writer.uint32(26).string(message.userKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetUserKeyResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetUserKeyResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.userKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetUserKeyResponse>, I>>(base?: I): GetUserKeyResponse {
    return GetUserKeyResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetUserKeyResponse>, I>>(object: I): GetUserKeyResponse {
    const message = createBaseGetUserKeyResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.userKey = object.userKey ?? "";
    return message;
  },
};

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };
