import App from './components/App/App';
import DiscountClientProvider from './components/providers/DiscountClientProvider';
import 'react-toastify/dist/ReactToastify.css';
import 'mica-ui-kit/src/styles/index.scss';
import './styles/index.css';
import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RecoilRoot } from 'recoil';
import { ErrorBoundaryUi } from 'mica-ui-kit/src/components/errors/ErrorBoundaryUi/ErrorBoundaryUi';
import { Sentry } from './components/App/init';
import { createRoot } from 'react-dom/client';
import { initializeColorsFromCssVariables } from 'mica-ui-kit/src/utils/css/color';

initializeColorsFromCssVariables();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorBoundaryUi />} showDialog>
      <RecoilRoot>
        <DiscountClientProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </DiscountClientProvider>
        <ToastContainer hideProgressBar />
      </RecoilRoot>
    </Sentry.ErrorBoundary>
  </StrictMode>
);
