/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Error } from "./error";

export const protobufPackage = "micashared.common.v1";

export interface AuthenticationHeaderConfiguration {
  /**
   * this is the name of the header to attach the token on the request, usually authorization is the value
   * due to grpc and http2 constrains the header key value will always be lowercased
   */
  headerKey: string;
  /**
   * adds a prefix to the value of the token the typical prefix is "Bearer" if ommited the value of the header will
   * just be the value of the token. This is case sensitive so whatever value is used it will be attached. A space will
   * always be added between this prefix and the actual token value:
   * "<header_value_prefix> <token_value>"
   */
  headerValuePrefix: string;
}

/**
 * Configuration for using an api token style configuration for authenticaton from mica to a provider, tenant or member.
 * Since a token is attached in the request header mica does not allow insecure channels for this configuration, so a
 * CA certificate bundle is required
 */
export interface ApiTokenConfiguration {
  headerConfiguration: AuthenticationHeaderConfiguration | undefined;
  apiTokenValue: string;
  /** The pem encoded certificate chain */
  pemCaChain: string[];
}

export interface CreateApiTokenConfigurationRequest {
  headerConfiguration: AuthenticationHeaderConfiguration | undefined;
  apiTokenValue: string;
  /** The pem encoded certificate chain */
  pemCaChain: string[];
}

export interface CreateApiTokenConfigurationResponse {
  status: CreateApiTokenConfigurationResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum CreateApiTokenConfigurationResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface GetApiTokenConfigurationRequest {
}

export interface GetApiTokenConfigurationResponse {
  status: GetApiTokenConfigurationResponse_Status;
  error: Error | undefined;
  apiTokenConfiguration: ApiTokenConfiguration | undefined;
}

export enum GetApiTokenConfigurationResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface UpdateApiTokenConfigurationRequest {
  headerConfiguration: AuthenticationHeaderConfiguration | undefined;
  apiTokenValue: string;
  /** The pem encoded certificate chain */
  pemCaChain: string[];
}

export interface UpdateApiTokenConfigurationResponse {
  status: UpdateApiTokenConfigurationResponse_Status;
  error: Error | undefined;
}

export enum UpdateApiTokenConfigurationResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

function createBaseAuthenticationHeaderConfiguration(): AuthenticationHeaderConfiguration {
  return { headerKey: "", headerValuePrefix: "" };
}

export const AuthenticationHeaderConfiguration = {
  encode(message: AuthenticationHeaderConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.headerKey !== "") {
      writer.uint32(10).string(message.headerKey);
    }
    if (message.headerValuePrefix !== "") {
      writer.uint32(18).string(message.headerValuePrefix);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): AuthenticationHeaderConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseAuthenticationHeaderConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.headerKey = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.headerValuePrefix = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<AuthenticationHeaderConfiguration>, I>>(
    base?: I,
  ): AuthenticationHeaderConfiguration {
    return AuthenticationHeaderConfiguration.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<AuthenticationHeaderConfiguration>, I>>(
    object: I,
  ): AuthenticationHeaderConfiguration {
    const message = createBaseAuthenticationHeaderConfiguration();
    message.headerKey = object.headerKey ?? "";
    message.headerValuePrefix = object.headerValuePrefix ?? "";
    return message;
  },
};

function createBaseApiTokenConfiguration(): ApiTokenConfiguration {
  return { headerConfiguration: undefined, apiTokenValue: "", pemCaChain: [] };
}

export const ApiTokenConfiguration = {
  encode(message: ApiTokenConfiguration, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.headerConfiguration !== undefined) {
      AuthenticationHeaderConfiguration.encode(message.headerConfiguration, writer.uint32(18).fork()).ldelim();
    }
    if (message.apiTokenValue !== "") {
      writer.uint32(26).string(message.apiTokenValue);
    }
    for (const v of message.pemCaChain) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): ApiTokenConfiguration {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseApiTokenConfiguration();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.headerConfiguration = AuthenticationHeaderConfiguration.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.apiTokenValue = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pemCaChain.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<ApiTokenConfiguration>, I>>(base?: I): ApiTokenConfiguration {
    return ApiTokenConfiguration.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<ApiTokenConfiguration>, I>>(object: I): ApiTokenConfiguration {
    const message = createBaseApiTokenConfiguration();
    message.headerConfiguration = (object.headerConfiguration !== undefined && object.headerConfiguration !== null)
      ? AuthenticationHeaderConfiguration.fromPartial(object.headerConfiguration)
      : undefined;
    message.apiTokenValue = object.apiTokenValue ?? "";
    message.pemCaChain = object.pemCaChain?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreateApiTokenConfigurationRequest(): CreateApiTokenConfigurationRequest {
  return { headerConfiguration: undefined, apiTokenValue: "", pemCaChain: [] };
}

export const CreateApiTokenConfigurationRequest = {
  encode(message: CreateApiTokenConfigurationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.headerConfiguration !== undefined) {
      AuthenticationHeaderConfiguration.encode(message.headerConfiguration, writer.uint32(10).fork()).ldelim();
    }
    if (message.apiTokenValue !== "") {
      writer.uint32(18).string(message.apiTokenValue);
    }
    for (const v of message.pemCaChain) {
      writer.uint32(26).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateApiTokenConfigurationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateApiTokenConfigurationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.headerConfiguration = AuthenticationHeaderConfiguration.decode(reader, reader.uint32());
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.apiTokenValue = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.pemCaChain.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateApiTokenConfigurationRequest>, I>>(
    base?: I,
  ): CreateApiTokenConfigurationRequest {
    return CreateApiTokenConfigurationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateApiTokenConfigurationRequest>, I>>(
    object: I,
  ): CreateApiTokenConfigurationRequest {
    const message = createBaseCreateApiTokenConfigurationRequest();
    message.headerConfiguration = (object.headerConfiguration !== undefined && object.headerConfiguration !== null)
      ? AuthenticationHeaderConfiguration.fromPartial(object.headerConfiguration)
      : undefined;
    message.apiTokenValue = object.apiTokenValue ?? "";
    message.pemCaChain = object.pemCaChain?.map((e) => e) || [];
    return message;
  },
};

function createBaseCreateApiTokenConfigurationResponse(): CreateApiTokenConfigurationResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const CreateApiTokenConfigurationResponse = {
  encode(message: CreateApiTokenConfigurationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateApiTokenConfigurationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateApiTokenConfigurationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateApiTokenConfigurationResponse>, I>>(
    base?: I,
  ): CreateApiTokenConfigurationResponse {
    return CreateApiTokenConfigurationResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateApiTokenConfigurationResponse>, I>>(
    object: I,
  ): CreateApiTokenConfigurationResponse {
    const message = createBaseCreateApiTokenConfigurationResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseGetApiTokenConfigurationRequest(): GetApiTokenConfigurationRequest {
  return {};
}

export const GetApiTokenConfigurationRequest = {
  encode(_: GetApiTokenConfigurationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetApiTokenConfigurationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetApiTokenConfigurationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetApiTokenConfigurationRequest>, I>>(base?: I): GetApiTokenConfigurationRequest {
    return GetApiTokenConfigurationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetApiTokenConfigurationRequest>, I>>(_: I): GetApiTokenConfigurationRequest {
    const message = createBaseGetApiTokenConfigurationRequest();
    return message;
  },
};

function createBaseGetApiTokenConfigurationResponse(): GetApiTokenConfigurationResponse {
  return { status: 0, error: undefined, apiTokenConfiguration: undefined };
}

export const GetApiTokenConfigurationResponse = {
  encode(message: GetApiTokenConfigurationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.apiTokenConfiguration !== undefined) {
      ApiTokenConfiguration.encode(message.apiTokenConfiguration, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetApiTokenConfigurationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetApiTokenConfigurationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.apiTokenConfiguration = ApiTokenConfiguration.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetApiTokenConfigurationResponse>, I>>(
    base?: I,
  ): GetApiTokenConfigurationResponse {
    return GetApiTokenConfigurationResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetApiTokenConfigurationResponse>, I>>(
    object: I,
  ): GetApiTokenConfigurationResponse {
    const message = createBaseGetApiTokenConfigurationResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.apiTokenConfiguration =
      (object.apiTokenConfiguration !== undefined && object.apiTokenConfiguration !== null)
        ? ApiTokenConfiguration.fromPartial(object.apiTokenConfiguration)
        : undefined;
    return message;
  },
};

function createBaseUpdateApiTokenConfigurationRequest(): UpdateApiTokenConfigurationRequest {
  return { headerConfiguration: undefined, apiTokenValue: "", pemCaChain: [] };
}

export const UpdateApiTokenConfigurationRequest = {
  encode(message: UpdateApiTokenConfigurationRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.headerConfiguration !== undefined) {
      AuthenticationHeaderConfiguration.encode(message.headerConfiguration, writer.uint32(18).fork()).ldelim();
    }
    if (message.apiTokenValue !== "") {
      writer.uint32(26).string(message.apiTokenValue);
    }
    for (const v of message.pemCaChain) {
      writer.uint32(34).string(v!);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateApiTokenConfigurationRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateApiTokenConfigurationRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 2:
          if (tag !== 18) {
            break;
          }

          message.headerConfiguration = AuthenticationHeaderConfiguration.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.apiTokenValue = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.pemCaChain.push(reader.string());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateApiTokenConfigurationRequest>, I>>(
    base?: I,
  ): UpdateApiTokenConfigurationRequest {
    return UpdateApiTokenConfigurationRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateApiTokenConfigurationRequest>, I>>(
    object: I,
  ): UpdateApiTokenConfigurationRequest {
    const message = createBaseUpdateApiTokenConfigurationRequest();
    message.headerConfiguration = (object.headerConfiguration !== undefined && object.headerConfiguration !== null)
      ? AuthenticationHeaderConfiguration.fromPartial(object.headerConfiguration)
      : undefined;
    message.apiTokenValue = object.apiTokenValue ?? "";
    message.pemCaChain = object.pemCaChain?.map((e) => e) || [];
    return message;
  },
};

function createBaseUpdateApiTokenConfigurationResponse(): UpdateApiTokenConfigurationResponse {
  return { status: 0, error: undefined };
}

export const UpdateApiTokenConfigurationResponse = {
  encode(message: UpdateApiTokenConfigurationResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateApiTokenConfigurationResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateApiTokenConfigurationResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateApiTokenConfigurationResponse>, I>>(
    base?: I,
  ): UpdateApiTokenConfigurationResponse {
    return UpdateApiTokenConfigurationResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateApiTokenConfigurationResponse>, I>>(
    object: I,
  ): UpdateApiTokenConfigurationResponse {
    const message = createBaseUpdateApiTokenConfigurationResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
