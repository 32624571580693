import styles from './SearchAnything.module.css';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import cx from 'classnames';
import { useBoolean } from 'mica-shared-react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

/**
 An expanding Search box that will navigate the user to a search screen passing
 the search query as a query param: 'name'
 */
export const SearchAnything = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const isFocused = useBoolean();

  const navigate = useNavigate();

  const handleBlur = () => {
    setSearchQuery('');
    isFocused.setFalse();
  };

  const handleSearch = () => navigate('/app/search?name=' + searchQuery);

  return (
    <div
      className={cx(styles.container, { [styles.isFocused]: isFocused.value })}
      onClick={isFocused.setTrue}>
      <FaSearch className={'mr-10'} />
      {isFocused.value ? (
        <input
          autoFocus
          className={styles.input}
          onBlur={handleBlur}
          onChange={(e) => setSearchQuery(e.target.value)}
          onKeyDown={({ key }) => key === 'Enter' && handleSearch()}
          value={searchQuery}
        />
      ) : (
        <>Search anything...</>
      )}
    </div>
  );
};

export default SearchAnything;
