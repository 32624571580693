import React from 'react';
import styles from './MtlsCertExpiryInput.module.css';
import { useBoolean } from 'mica-shared-react';
import { FaList } from '@react-icons/all-files/fa/FaList';
import { FaCalendar } from '@react-icons/all-files/fa/FaCalendar';
import { IconButton } from '../../../../../../inputs/buttons/IconButton/IconButton';
import { DatePicker } from '../../../../../../../inputs/DatePicker/DatePicker';
import { Select } from '../../../../../../../inputs';
import dayjs from 'dayjs';

type MtlsCertExpiryInputProps = {
  expiry: Date | number;
  onExpiryChanged: (expiry: Date | number) => void;
};

const selectOptions = [
  {
    label: '1 Day',
    value: '24',
  },
  {
    label: '1 Week',
    value: '168',
  },
  {
    label: '30 Days',
    value: '720',
  },
  {
    label: '1 Year',
    value: '8760',
  },
];

/**
 The input component for setting the expiry date on a new cert
 */
const MtlsCertExpiryInput = ({
  expiry,
  onExpiryChanged,
}: MtlsCertExpiryInputProps) => {
  const showDateInput = useBoolean();

  const handleCalendarDateSelected = (date: Date | null) =>
    date && onExpiryChanged(date);

  const handleSelectOptionSelected = (option: string) =>
    onExpiryChanged(parseInt(option));

  const handleToggleInput = () => {
    onExpiryChanged(
      showDateInput.value ? 8760 : dayjs().add(1, 'year').toDate()
    );
    showDateInput.toggle();
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>Certificate Expiry</div>
      <div className={styles.inputRow}>
        <div className={styles.inputContainer}>
          {showDateInput.value ? (
            <DatePicker
              onChange={handleCalendarDateSelected}
              value={expiry as Date}
            />
          ) : (
            <Select
              onChange={handleSelectOptionSelected}
              options={selectOptions}
              style={{ width: 180 }}
              value={'' + expiry}
            />
          )}
        </div>
        <IconButton
          className={styles.toggle}
          label={'Toggle input mode'}
          onClick={handleToggleInput}>
          {showDateInput.value ? <FaList /> : <FaCalendar />}
        </IconButton>
      </div>
    </div>
  );
};

export default MtlsCertExpiryInput;
