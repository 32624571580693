import { toast } from 'react-toastify';
import { errorAsString } from 'mica-utilities/src/formatters/error/error';
import { logError } from 'mica-ui-kit/src/utils/logging/logging';

const defaultHandleError: (err: unknown) => boolean = () => false;

export const makeQueryConfig = (handleError = defaultHandleError) => {
  const onError = (err: unknown) => {
    if (!handleError(err)) {
      toast.error(errorAsString(err));
      logError(err);
    }
  };

  return {
    defaultOptions: {
      queries: {
        onError,
        retry: false,
        staleTime: 30000,
      },
      mutations: {
        onError,
      },
    },
  };
};

export const basicQueryConfig = makeQueryConfig();
