import { TextInput } from '../../../../inputs/text/TextInput/TextInput';
import { Modal } from '../../../../layout';
import { Button } from '../../../inputs/buttons/Button/Button';
import { EditIconButton } from '../../../inputs/buttons/EditIconButton/EditIconButton';
import { Card } from '../../../layout/Card/Card';
import { Link } from '../../../layout/navigation/Link/Link';
import { EmptyList } from '../../../layout/text/EmptyList/EmptyList';
import { InfoBox } from '../../../layout/text/InfoBox/InfoBox';
import { WarningBox } from '../../../layout/text/WarningBox/WarningBox';
import styles from '../NetworkSettings.module.css';
import { useBoolean } from 'mica-shared-react';
import React, { useEffect, useState } from 'react';

export type CallbackAddressCardProps = {
  callbackAddress: string;
  onUpdateCallbackAddress: (callbackAddress: string) => void;
};

/**
 The Card and edit modal for the Callback Address
 */
export const CallbackAddressCard = ({
  callbackAddress,
  onUpdateCallbackAddress,
}: CallbackAddressCardProps) => {
  const [newCallbackAddress, setNewCallbackAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const showEditModal = useBoolean();

  useEffect(() => {
    setNewCallbackAddress(callbackAddress);
    setLoading(false);
  }, [callbackAddress]);

  const handleUpdateCallbackAddress = async () => {
    await onUpdateCallbackAddress(newCallbackAddress);
    showEditModal.setFalse();
  };

  return (
    <Card title={'Callback Address'}>
      <div className={styles.value}>
        {callbackAddress}
        {!callbackAddress && (
          <EmptyList>
            No callback address set.
            <Link
              className="ml-5"
              onClick={showEditModal.setTrue}
              label={'Set it?'}
            />
          </EmptyList>
        )}
        <EditIconButton className={'ml-5'} onClick={showEditModal.setTrue} />
      </div>
      <InfoBox>
        The callback address is the url at which you've implemented the Mica
        webhooks
      </InfoBox>
      <Modal
        active={showEditModal.value}
        title={'Callback Address'}
        onDismiss={showEditModal.setFalse}>
        <div className={'p-20'} style={{ width: 340 }}>
          <TextInput
            onChange={setNewCallbackAddress}
            value={newCallbackAddress}
            label={'New Callback address'}
          />
          <WarningBox
            className={'my-10'}
            message={
              "Changing the callback address can affect Mica network's ability to communicate with you. Tread carefully..."
            }
          />
          <div className={'w-full flex justify-ctr'}>
            <Button
              className={'button'}
              loading={loading}
              label={'SAVE'}
              onClick={handleUpdateCallbackAddress}
            />
          </div>
        </div>
      </Modal>
    </Card>
  );
};
