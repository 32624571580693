import ChartTile from '../ChartTile/ChartTile';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
};

const discounts = [
  '2 for 1 Mica Shoes',
  '$10 Belt Promo',
  'Buy Chicken, get eggs',
  'Vodka & Eggs Christmas Promo',
  'Grocery buy 1 get 1',
];

/**
 A Component
 */
const TopPerformingCount = () => {
  const datasets = useMemo(
    () => [
      {
        borderColor: '#DC0073',
        backgroundColor: '#DC007350',
        data: discounts.map(() => Math.random() * 10000).sort((a, b) => b - a),
        label: 'value',
      },
    ],
    []
  );

  return (
    <ChartTile title={'Top Performing Discounts (#)'}>
      <Bar
        options={options}
        datasetIdKey="id"
        data={{
          labels: discounts,
          datasets,
        }}
      />
    </ChartTile>
  );
};

export default TopPerformingCount;
