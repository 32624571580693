import { useState } from 'react';
import { useQuery, UseQueryOptions } from 'react-query';

export const useLazyQuery = <RequestType, ResponseType, TError = unknown>(
  key: string,
  clientMethod: (args: Partial<RequestType>) => Promise<ResponseType>,
  options?: Omit<
    UseQueryOptions<ResponseType, TError, ResponseType, [string, RequestType]>,
    'queryKey' | 'queryFn'
  >
) => {
  const [args, setArgs] = useState<Partial<RequestType> | undefined>(undefined);

  const rest = useQuery(
    [key, args] as [string, RequestType],
    async () => clientMethod(args!),
    {
      ...options,
      enabled: !!args,
    }
  );

  return {
    args,
    fetch: setArgs,
    ...rest,
  };
};
