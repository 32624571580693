import {
  CreateProductGroupRequest,
  UpdateProductGroupRequest,
} from 'mica-proto/src/mica/discount/productgroup/v1/product_group';
import { useCreateUpdateForm } from 'mica-shared-react/src/hooks/useCreateUpdateForm';
import { LoadingButton, TextInput } from 'mica-ui-kit/src/components';
import { Card } from 'mica-ui-kit/src/components/consoles/layout/Card/Card';
import { TextListInputAndTable } from 'mica-ui-kit/src/components/layout/forms/TextListInputAndTable/TextListInputAndTable';
import React from 'react';

export const defaultProductGroup: CreateProductGroupRequest = {
  productGroupRef: '',
  name: '',
  productMatchFilters: [],
  productCodes: [],
};

type Input = CreateProductGroupRequest | UpdateProductGroupRequest;

type ProductGroupFormProps<BaseRecord = Input> = {
  productGroup?: BaseRecord;
  loading?: boolean;
  onSave: (input: BaseRecord) => void;
};

const isValid = (displayData: Input) =>
  !!displayData.productGroupRef && displayData.name.length > 3;

/**
 The create/update form for a product group
 */
const ProductGroupForm = <BaseRecord extends Input>({
  productGroup,
  loading,
  onSave,
}: ProductGroupFormProps<BaseRecord>) => {
  const { displayData, updateProperty } = useCreateUpdateForm(
    productGroup || defaultProductGroup
  );
  const handleSave = () => onSave(displayData as BaseRecord);

  return (
    <Card>
      <div>
        <TextInput
          className={'mb-20'}
          onChange={updateProperty('productGroupRef')}
          value={displayData.productGroupRef || ''}
          label={'Internal Ref/ID'}
          required
        />
        <TextInput
          onChange={updateProperty('name')}
          value={displayData.name}
          label={'Name'}
          required
        />
        <div className={'section-header mt-30'}>FILTERS:</div>
        <TextListInputAndTable
          inputLabel={'Add Filter'}
          onChange={updateProperty('productMatchFilters')}
          splitCSV
          values={displayData.productMatchFilters}
        />
        <div className={'section-header mt-30'}>PRODUCT CODES:</div>
        <TextListInputAndTable
          inputLabel={'Add Product Code'}
          onChange={updateProperty('productCodes')}
          splitCSV
          values={displayData.productCodes}
        />
        <LoadingButton
          className={'mt-20'}
          disabled={!isValid(displayData)}
          loading={loading}
          onClick={handleSave}
          title={productGroup ? 'UPDATE' : 'CREATE'}
        />
      </div>
    </Card>
  );
};

export default ProductGroupForm;
