import styles from './InputAccessoryText.module.css';
import React from 'react';

export type InputAccessoryTextProps = {
  required?: boolean;
  error?: string;
  info?: string;
};

/**
 The row of subtext that appears below our inputs
 NOTE!: this row will sit below baseline, to ensure test and inputs in a single row line up.
 You may need to allow some margin between this item and the item below it in the DOM
 */
const InputAccessoryText = ({
  required,
  error,
  info,
}: InputAccessoryTextProps) => (
  <div className={styles.container}>
    {!!error && <span className={styles.error}>!{error}</span>}
    {required && !error && <span className={styles.required}>Required!</span>}
    {info && !error && <span className={styles.info}>{info}</span>}
  </div>
);

export default InputAccessoryText;
