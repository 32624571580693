/* eslint-disable */
import Long from "long";
import _m0 from "protobufjs/minimal";
import { Timestamp } from "../../../../google/protobuf/timestamp";
import { Currency } from "../../../../micashared/common/enums/currency/v1/currency";
import { DiscountType } from "../../../../micashared/common/enums/discounttype/v1/discount_type";
import { Error } from "../../../../micashared/common/v1/error";
import { DiscountCriteria, MonetaryAmount, PercentageAmount } from "../../discount/v1/discount";

export const protobufPackage = "mica.discount.discountdefinition.v1";

export enum DiscountDefinitionStatus {
  DISCOUNT_DEFINITION_STATUS_UNSPECIFIED = 0,
  DISCOUNT_DEFINITION_STATUS_AVAILABLE = 1,
  DISCOUNT_DEFINITION_STATUS_SUSPENDED = 2,
  DISCOUNT_DEFINITION_STATUS_CLOSED = 3,
}

export interface DiscountDefinition {
  discountDefinitionKey: string;
  version: number;
  created: Date | undefined;
  updated: Date | undefined;
  discountDefinitionRef: string;
  discountProviderAccountRef: string;
  /** human readable description for a given discount that can be shown to the end user */
  headline: string;
  /** human readable description for a given discount that can be shown to the end user */
  summary: string;
  receiptDescription: string;
  status: DiscountDefinitionStatus;
  type: DiscountType;
  validFrom: Date | undefined;
  validTo:
    | Date
    | undefined;
  /**
   * the basket must include items that match each of the criteria (i.e. it's an and operation) in order for this
   * discount to apply
   */
  discountCriteria: DiscountCriteria[];
  currency: Currency;
  monetaryAmount?: MonetaryAmount | undefined;
  percentageAmount?:
    | PercentageAmount
    | undefined;
  /** address to the image for this discount definition (note that this image is expected to be square) */
  thumbnailUrl: string;
}

export interface CreateDiscountDefinitionRequest {
  discountDefinitionRef: string;
  discountProviderAccountRef: string;
  /** human readable description for a given discount that can be shown to the end user */
  headline: string;
  /** human readable description for a given discount that can be shown to the end user */
  summary: string;
  receiptDescription: string;
  /** the initial status of the discount definition has to be set and it cannot be unspecified */
  status: DiscountDefinitionStatus;
  type: DiscountType;
  validFrom: Date | undefined;
  validTo:
    | Date
    | undefined;
  /**
   * the basket must include items that match each of the criteria (i.e. it's an and operation) in order for this
   * discount to apply
   */
  discountCriteria: DiscountCriteria[];
  currency: Currency;
  monetaryAmount?: MonetaryAmount | undefined;
  percentageAmount?:
    | PercentageAmount
    | undefined;
  /** address to the image for this discount definition (note that this image is expected to be square) */
  thumbnailUrl: string;
}

export interface CreateDiscountDefinitionResponse {
  status: CreateDiscountDefinitionResponse_Status;
  error: Error | undefined;
  discountDefinitionKey: string;
  version: number;
}

export enum CreateDiscountDefinitionResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
}

export interface GetDiscountDefinitionRequest {
  discountDefinitionRef?: string | undefined;
  discountDefinitionKey?: string | undefined;
}

export interface GetDiscountDefinitionResponse {
  status: GetDiscountDefinitionResponse_Status;
  error: Error | undefined;
  discountDefinition: DiscountDefinition | undefined;
}

export enum GetDiscountDefinitionResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface UpdateDiscountDefinitionRequest {
  discountDefinitionRef?: string | undefined;
  discountDefinitionKey?: string | undefined;
  version: number;
  discountProviderAccountRef: string;
  /** human readable description for a given discount that can be shown to the end user */
  headline: string;
  /** human readable description for a given discount that can be shown to the end user */
  summary: string;
  /** address to the image for this discount definition (note that this image is expected to be square) */
  thumbnailUrl: string;
  receiptDescription: string;
  /** the update status of the discount definition has to be set and it cannot be unspecified */
  status: DiscountDefinitionStatus;
  type: DiscountType;
  validFrom: Date | undefined;
  validTo:
    | Date
    | undefined;
  /**
   * the basket must include items that match each of the criteria (i.e. it's an and operation) in order for this
   * discount to apply
   */
  discountCriteria: DiscountCriteria[];
  currency: Currency;
  monetaryAmount?: MonetaryAmount | undefined;
  percentageAmount?: PercentageAmount | undefined;
}

export interface UpdateDiscountDefinitionResponse {
  status: UpdateDiscountDefinitionResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum UpdateDiscountDefinitionResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface UpdateDiscountDefinitionStatusRequest {
  discountDefinitionRef?: string | undefined;
  discountDefinitionKey?: string | undefined;
  discountDefinitionStatus: DiscountDefinitionStatus;
}

export interface UpdateDiscountDefinitionStatusResponse {
  status: UpdateDiscountDefinitionStatusResponse_Status;
  error: Error | undefined;
  version: number;
}

export enum UpdateDiscountDefinitionStatusResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
  STATUS_INVALID_STATE_TRANSITION = 4,
}

export interface RemoveDiscountDefinitionRequest {
  discountDefinitionRef?: string | undefined;
  discountDefinitionKey?: string | undefined;
}

export interface RemoveDiscountDefinitionResponse {
  status: RemoveDiscountDefinitionResponse_Status;
  error: Error | undefined;
}

export enum RemoveDiscountDefinitionResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

export interface SearchDiscountDefinitionRequest {
  receiptDescription: string;
  status: DiscountDefinitionStatus;
  type: DiscountType;
  validFrom: Date | undefined;
  validTo: Date | undefined;
  currency: Currency;
}

export interface SearchDiscountDefinitionResponse {
  status: SearchDiscountDefinitionResponse_Status;
  error: Error | undefined;
  discountDefinitions: DiscountDefinition[];
}

export enum SearchDiscountDefinitionResponse_Status {
  STATUS_UNSPECIFIED = 0,
  STATUS_SUCCESS = 1,
  STATUS_ERROR = 2,
  STATUS_NOT_FOUND = 3,
}

function createBaseDiscountDefinition(): DiscountDefinition {
  return {
    discountDefinitionKey: "",
    version: 0,
    created: undefined,
    updated: undefined,
    discountDefinitionRef: "",
    discountProviderAccountRef: "",
    headline: "",
    summary: "",
    receiptDescription: "",
    status: 0,
    type: 0,
    validFrom: undefined,
    validTo: undefined,
    discountCriteria: [],
    currency: 0,
    monetaryAmount: undefined,
    percentageAmount: undefined,
    thumbnailUrl: "",
  };
}

export const DiscountDefinition = {
  encode(message: DiscountDefinition, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountDefinitionKey !== "") {
      writer.uint32(10).string(message.discountDefinitionKey);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.created !== undefined) {
      Timestamp.encode(toTimestamp(message.created), writer.uint32(26).fork()).ldelim();
    }
    if (message.updated !== undefined) {
      Timestamp.encode(toTimestamp(message.updated), writer.uint32(34).fork()).ldelim();
    }
    if (message.discountDefinitionRef !== "") {
      writer.uint32(42).string(message.discountDefinitionRef);
    }
    if (message.discountProviderAccountRef !== "") {
      writer.uint32(50).string(message.discountProviderAccountRef);
    }
    if (message.headline !== "") {
      writer.uint32(130).string(message.headline);
    }
    if (message.summary !== "") {
      writer.uint32(138).string(message.summary);
    }
    if (message.receiptDescription !== "") {
      writer.uint32(58).string(message.receiptDescription);
    }
    if (message.status !== 0) {
      writer.uint32(64).int32(message.status);
    }
    if (message.type !== 0) {
      writer.uint32(72).int32(message.type);
    }
    if (message.validFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.validFrom), writer.uint32(82).fork()).ldelim();
    }
    if (message.validTo !== undefined) {
      Timestamp.encode(toTimestamp(message.validTo), writer.uint32(90).fork()).ldelim();
    }
    for (const v of message.discountCriteria) {
      DiscountCriteria.encode(v!, writer.uint32(98).fork()).ldelim();
    }
    if (message.currency !== 0) {
      writer.uint32(104).int32(message.currency);
    }
    if (message.monetaryAmount !== undefined) {
      MonetaryAmount.encode(message.monetaryAmount, writer.uint32(114).fork()).ldelim();
    }
    if (message.percentageAmount !== undefined) {
      PercentageAmount.encode(message.percentageAmount, writer.uint32(122).fork()).ldelim();
    }
    if (message.thumbnailUrl !== "") {
      writer.uint32(146).string(message.thumbnailUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): DiscountDefinition {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseDiscountDefinition();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountDefinitionKey = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.created = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.updated = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.discountDefinitionRef = reader.string();
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.discountProviderAccountRef = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.headline = reader.string();
          continue;
        case 17:
          if (tag !== 138) {
            break;
          }

          message.summary = reader.string();
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.receiptDescription = reader.string();
          continue;
        case 8:
          if (tag !== 64) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.validFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.validTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.discountCriteria.push(DiscountCriteria.decode(reader, reader.uint32()));
          continue;
        case 13:
          if (tag !== 104) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.monetaryAmount = MonetaryAmount.decode(reader, reader.uint32());
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.percentageAmount = PercentageAmount.decode(reader, reader.uint32());
          continue;
        case 18:
          if (tag !== 146) {
            break;
          }

          message.thumbnailUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<DiscountDefinition>, I>>(base?: I): DiscountDefinition {
    return DiscountDefinition.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<DiscountDefinition>, I>>(object: I): DiscountDefinition {
    const message = createBaseDiscountDefinition();
    message.discountDefinitionKey = object.discountDefinitionKey ?? "";
    message.version = object.version ?? 0;
    message.created = object.created ?? undefined;
    message.updated = object.updated ?? undefined;
    message.discountDefinitionRef = object.discountDefinitionRef ?? "";
    message.discountProviderAccountRef = object.discountProviderAccountRef ?? "";
    message.headline = object.headline ?? "";
    message.summary = object.summary ?? "";
    message.receiptDescription = object.receiptDescription ?? "";
    message.status = object.status ?? 0;
    message.type = object.type ?? 0;
    message.validFrom = object.validFrom ?? undefined;
    message.validTo = object.validTo ?? undefined;
    message.discountCriteria = object.discountCriteria?.map((e) => DiscountCriteria.fromPartial(e)) || [];
    message.currency = object.currency ?? 0;
    message.monetaryAmount = (object.monetaryAmount !== undefined && object.monetaryAmount !== null)
      ? MonetaryAmount.fromPartial(object.monetaryAmount)
      : undefined;
    message.percentageAmount = (object.percentageAmount !== undefined && object.percentageAmount !== null)
      ? PercentageAmount.fromPartial(object.percentageAmount)
      : undefined;
    message.thumbnailUrl = object.thumbnailUrl ?? "";
    return message;
  },
};

function createBaseCreateDiscountDefinitionRequest(): CreateDiscountDefinitionRequest {
  return {
    discountDefinitionRef: "",
    discountProviderAccountRef: "",
    headline: "",
    summary: "",
    receiptDescription: "",
    status: 0,
    type: 0,
    validFrom: undefined,
    validTo: undefined,
    discountCriteria: [],
    currency: 0,
    monetaryAmount: undefined,
    percentageAmount: undefined,
    thumbnailUrl: "",
  };
}

export const CreateDiscountDefinitionRequest = {
  encode(message: CreateDiscountDefinitionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountDefinitionRef !== "") {
      writer.uint32(10).string(message.discountDefinitionRef);
    }
    if (message.discountProviderAccountRef !== "") {
      writer.uint32(18).string(message.discountProviderAccountRef);
    }
    if (message.headline !== "") {
      writer.uint32(98).string(message.headline);
    }
    if (message.summary !== "") {
      writer.uint32(106).string(message.summary);
    }
    if (message.receiptDescription !== "") {
      writer.uint32(26).string(message.receiptDescription);
    }
    if (message.status !== 0) {
      writer.uint32(32).int32(message.status);
    }
    if (message.type !== 0) {
      writer.uint32(40).int32(message.type);
    }
    if (message.validFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.validFrom), writer.uint32(50).fork()).ldelim();
    }
    if (message.validTo !== undefined) {
      Timestamp.encode(toTimestamp(message.validTo), writer.uint32(58).fork()).ldelim();
    }
    for (const v of message.discountCriteria) {
      DiscountCriteria.encode(v!, writer.uint32(66).fork()).ldelim();
    }
    if (message.currency !== 0) {
      writer.uint32(72).int32(message.currency);
    }
    if (message.monetaryAmount !== undefined) {
      MonetaryAmount.encode(message.monetaryAmount, writer.uint32(82).fork()).ldelim();
    }
    if (message.percentageAmount !== undefined) {
      PercentageAmount.encode(message.percentageAmount, writer.uint32(90).fork()).ldelim();
    }
    if (message.thumbnailUrl !== "") {
      writer.uint32(114).string(message.thumbnailUrl);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateDiscountDefinitionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateDiscountDefinitionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountDefinitionRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.discountProviderAccountRef = reader.string();
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.headline = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.summary = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.receiptDescription = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 50) {
            break;
          }

          message.validFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.validTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.discountCriteria.push(DiscountCriteria.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 72) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 10:
          if (tag !== 82) {
            break;
          }

          message.monetaryAmount = MonetaryAmount.decode(reader, reader.uint32());
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.percentageAmount = PercentageAmount.decode(reader, reader.uint32());
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.thumbnailUrl = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateDiscountDefinitionRequest>, I>>(base?: I): CreateDiscountDefinitionRequest {
    return CreateDiscountDefinitionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateDiscountDefinitionRequest>, I>>(
    object: I,
  ): CreateDiscountDefinitionRequest {
    const message = createBaseCreateDiscountDefinitionRequest();
    message.discountDefinitionRef = object.discountDefinitionRef ?? "";
    message.discountProviderAccountRef = object.discountProviderAccountRef ?? "";
    message.headline = object.headline ?? "";
    message.summary = object.summary ?? "";
    message.receiptDescription = object.receiptDescription ?? "";
    message.status = object.status ?? 0;
    message.type = object.type ?? 0;
    message.validFrom = object.validFrom ?? undefined;
    message.validTo = object.validTo ?? undefined;
    message.discountCriteria = object.discountCriteria?.map((e) => DiscountCriteria.fromPartial(e)) || [];
    message.currency = object.currency ?? 0;
    message.monetaryAmount = (object.monetaryAmount !== undefined && object.monetaryAmount !== null)
      ? MonetaryAmount.fromPartial(object.monetaryAmount)
      : undefined;
    message.percentageAmount = (object.percentageAmount !== undefined && object.percentageAmount !== null)
      ? PercentageAmount.fromPartial(object.percentageAmount)
      : undefined;
    message.thumbnailUrl = object.thumbnailUrl ?? "";
    return message;
  },
};

function createBaseCreateDiscountDefinitionResponse(): CreateDiscountDefinitionResponse {
  return { status: 0, error: undefined, discountDefinitionKey: "", version: 0 };
}

export const CreateDiscountDefinitionResponse = {
  encode(message: CreateDiscountDefinitionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.discountDefinitionKey !== "") {
      writer.uint32(26).string(message.discountDefinitionKey);
    }
    if (message.version !== 0) {
      writer.uint32(32).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): CreateDiscountDefinitionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseCreateDiscountDefinitionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountDefinitionKey = reader.string();
          continue;
        case 4:
          if (tag !== 32) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<CreateDiscountDefinitionResponse>, I>>(
    base?: I,
  ): CreateDiscountDefinitionResponse {
    return CreateDiscountDefinitionResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<CreateDiscountDefinitionResponse>, I>>(
    object: I,
  ): CreateDiscountDefinitionResponse {
    const message = createBaseCreateDiscountDefinitionResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.discountDefinitionKey = object.discountDefinitionKey ?? "";
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseGetDiscountDefinitionRequest(): GetDiscountDefinitionRequest {
  return { discountDefinitionRef: undefined, discountDefinitionKey: undefined };
}

export const GetDiscountDefinitionRequest = {
  encode(message: GetDiscountDefinitionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountDefinitionRef !== undefined) {
      writer.uint32(10).string(message.discountDefinitionRef);
    }
    if (message.discountDefinitionKey !== undefined) {
      writer.uint32(18).string(message.discountDefinitionKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDiscountDefinitionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDiscountDefinitionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountDefinitionRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.discountDefinitionKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetDiscountDefinitionRequest>, I>>(base?: I): GetDiscountDefinitionRequest {
    return GetDiscountDefinitionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetDiscountDefinitionRequest>, I>>(object: I): GetDiscountDefinitionRequest {
    const message = createBaseGetDiscountDefinitionRequest();
    message.discountDefinitionRef = object.discountDefinitionRef ?? undefined;
    message.discountDefinitionKey = object.discountDefinitionKey ?? undefined;
    return message;
  },
};

function createBaseGetDiscountDefinitionResponse(): GetDiscountDefinitionResponse {
  return { status: 0, error: undefined, discountDefinition: undefined };
}

export const GetDiscountDefinitionResponse = {
  encode(message: GetDiscountDefinitionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.discountDefinition !== undefined) {
      DiscountDefinition.encode(message.discountDefinition, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): GetDiscountDefinitionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseGetDiscountDefinitionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountDefinition = DiscountDefinition.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<GetDiscountDefinitionResponse>, I>>(base?: I): GetDiscountDefinitionResponse {
    return GetDiscountDefinitionResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<GetDiscountDefinitionResponse>, I>>(
    object: I,
  ): GetDiscountDefinitionResponse {
    const message = createBaseGetDiscountDefinitionResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.discountDefinition = (object.discountDefinition !== undefined && object.discountDefinition !== null)
      ? DiscountDefinition.fromPartial(object.discountDefinition)
      : undefined;
    return message;
  },
};

function createBaseUpdateDiscountDefinitionRequest(): UpdateDiscountDefinitionRequest {
  return {
    discountDefinitionRef: undefined,
    discountDefinitionKey: undefined,
    version: 0,
    discountProviderAccountRef: "",
    headline: "",
    summary: "",
    thumbnailUrl: "",
    receiptDescription: "",
    status: 0,
    type: 0,
    validFrom: undefined,
    validTo: undefined,
    discountCriteria: [],
    currency: 0,
    monetaryAmount: undefined,
    percentageAmount: undefined,
  };
}

export const UpdateDiscountDefinitionRequest = {
  encode(message: UpdateDiscountDefinitionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountDefinitionRef !== undefined) {
      writer.uint32(10).string(message.discountDefinitionRef);
    }
    if (message.discountDefinitionKey !== undefined) {
      writer.uint32(106).string(message.discountDefinitionKey);
    }
    if (message.version !== 0) {
      writer.uint32(16).int64(message.version);
    }
    if (message.discountProviderAccountRef !== "") {
      writer.uint32(26).string(message.discountProviderAccountRef);
    }
    if (message.headline !== "") {
      writer.uint32(114).string(message.headline);
    }
    if (message.summary !== "") {
      writer.uint32(122).string(message.summary);
    }
    if (message.thumbnailUrl !== "") {
      writer.uint32(130).string(message.thumbnailUrl);
    }
    if (message.receiptDescription !== "") {
      writer.uint32(34).string(message.receiptDescription);
    }
    if (message.status !== 0) {
      writer.uint32(40).int32(message.status);
    }
    if (message.type !== 0) {
      writer.uint32(48).int32(message.type);
    }
    if (message.validFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.validFrom), writer.uint32(58).fork()).ldelim();
    }
    if (message.validTo !== undefined) {
      Timestamp.encode(toTimestamp(message.validTo), writer.uint32(66).fork()).ldelim();
    }
    for (const v of message.discountCriteria) {
      DiscountCriteria.encode(v!, writer.uint32(74).fork()).ldelim();
    }
    if (message.currency !== 0) {
      writer.uint32(80).int32(message.currency);
    }
    if (message.monetaryAmount !== undefined) {
      MonetaryAmount.encode(message.monetaryAmount, writer.uint32(90).fork()).ldelim();
    }
    if (message.percentageAmount !== undefined) {
      PercentageAmount.encode(message.percentageAmount, writer.uint32(98).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountDefinitionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountDefinitionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountDefinitionRef = reader.string();
          continue;
        case 13:
          if (tag !== 106) {
            break;
          }

          message.discountDefinitionKey = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountProviderAccountRef = reader.string();
          continue;
        case 14:
          if (tag !== 114) {
            break;
          }

          message.headline = reader.string();
          continue;
        case 15:
          if (tag !== 122) {
            break;
          }

          message.summary = reader.string();
          continue;
        case 16:
          if (tag !== 130) {
            break;
          }

          message.thumbnailUrl = reader.string();
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.receiptDescription = reader.string();
          continue;
        case 5:
          if (tag !== 40) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 6:
          if (tag !== 48) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 7:
          if (tag !== 58) {
            break;
          }

          message.validFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 8:
          if (tag !== 66) {
            break;
          }

          message.validTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 9:
          if (tag !== 74) {
            break;
          }

          message.discountCriteria.push(DiscountCriteria.decode(reader, reader.uint32()));
          continue;
        case 10:
          if (tag !== 80) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
        case 11:
          if (tag !== 90) {
            break;
          }

          message.monetaryAmount = MonetaryAmount.decode(reader, reader.uint32());
          continue;
        case 12:
          if (tag !== 98) {
            break;
          }

          message.percentageAmount = PercentageAmount.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountDefinitionRequest>, I>>(base?: I): UpdateDiscountDefinitionRequest {
    return UpdateDiscountDefinitionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDiscountDefinitionRequest>, I>>(
    object: I,
  ): UpdateDiscountDefinitionRequest {
    const message = createBaseUpdateDiscountDefinitionRequest();
    message.discountDefinitionRef = object.discountDefinitionRef ?? undefined;
    message.discountDefinitionKey = object.discountDefinitionKey ?? undefined;
    message.version = object.version ?? 0;
    message.discountProviderAccountRef = object.discountProviderAccountRef ?? "";
    message.headline = object.headline ?? "";
    message.summary = object.summary ?? "";
    message.thumbnailUrl = object.thumbnailUrl ?? "";
    message.receiptDescription = object.receiptDescription ?? "";
    message.status = object.status ?? 0;
    message.type = object.type ?? 0;
    message.validFrom = object.validFrom ?? undefined;
    message.validTo = object.validTo ?? undefined;
    message.discountCriteria = object.discountCriteria?.map((e) => DiscountCriteria.fromPartial(e)) || [];
    message.currency = object.currency ?? 0;
    message.monetaryAmount = (object.monetaryAmount !== undefined && object.monetaryAmount !== null)
      ? MonetaryAmount.fromPartial(object.monetaryAmount)
      : undefined;
    message.percentageAmount = (object.percentageAmount !== undefined && object.percentageAmount !== null)
      ? PercentageAmount.fromPartial(object.percentageAmount)
      : undefined;
    return message;
  },
};

function createBaseUpdateDiscountDefinitionResponse(): UpdateDiscountDefinitionResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const UpdateDiscountDefinitionResponse = {
  encode(message: UpdateDiscountDefinitionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountDefinitionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountDefinitionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountDefinitionResponse>, I>>(
    base?: I,
  ): UpdateDiscountDefinitionResponse {
    return UpdateDiscountDefinitionResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDiscountDefinitionResponse>, I>>(
    object: I,
  ): UpdateDiscountDefinitionResponse {
    const message = createBaseUpdateDiscountDefinitionResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseUpdateDiscountDefinitionStatusRequest(): UpdateDiscountDefinitionStatusRequest {
  return { discountDefinitionRef: undefined, discountDefinitionKey: undefined, discountDefinitionStatus: 0 };
}

export const UpdateDiscountDefinitionStatusRequest = {
  encode(message: UpdateDiscountDefinitionStatusRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountDefinitionRef !== undefined) {
      writer.uint32(10).string(message.discountDefinitionRef);
    }
    if (message.discountDefinitionKey !== undefined) {
      writer.uint32(26).string(message.discountDefinitionKey);
    }
    if (message.discountDefinitionStatus !== 0) {
      writer.uint32(16).int32(message.discountDefinitionStatus);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountDefinitionStatusRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountDefinitionStatusRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountDefinitionRef = reader.string();
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountDefinitionKey = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.discountDefinitionStatus = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountDefinitionStatusRequest>, I>>(
    base?: I,
  ): UpdateDiscountDefinitionStatusRequest {
    return UpdateDiscountDefinitionStatusRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDiscountDefinitionStatusRequest>, I>>(
    object: I,
  ): UpdateDiscountDefinitionStatusRequest {
    const message = createBaseUpdateDiscountDefinitionStatusRequest();
    message.discountDefinitionRef = object.discountDefinitionRef ?? undefined;
    message.discountDefinitionKey = object.discountDefinitionKey ?? undefined;
    message.discountDefinitionStatus = object.discountDefinitionStatus ?? 0;
    return message;
  },
};

function createBaseUpdateDiscountDefinitionStatusResponse(): UpdateDiscountDefinitionStatusResponse {
  return { status: 0, error: undefined, version: 0 };
}

export const UpdateDiscountDefinitionStatusResponse = {
  encode(message: UpdateDiscountDefinitionStatusResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    if (message.version !== 0) {
      writer.uint32(24).int64(message.version);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): UpdateDiscountDefinitionStatusResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseUpdateDiscountDefinitionStatusResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.version = longToNumber(reader.int64() as Long);
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<UpdateDiscountDefinitionStatusResponse>, I>>(
    base?: I,
  ): UpdateDiscountDefinitionStatusResponse {
    return UpdateDiscountDefinitionStatusResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<UpdateDiscountDefinitionStatusResponse>, I>>(
    object: I,
  ): UpdateDiscountDefinitionStatusResponse {
    const message = createBaseUpdateDiscountDefinitionStatusResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.version = object.version ?? 0;
    return message;
  },
};

function createBaseRemoveDiscountDefinitionRequest(): RemoveDiscountDefinitionRequest {
  return { discountDefinitionRef: undefined, discountDefinitionKey: undefined };
}

export const RemoveDiscountDefinitionRequest = {
  encode(message: RemoveDiscountDefinitionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.discountDefinitionRef !== undefined) {
      writer.uint32(10).string(message.discountDefinitionRef);
    }
    if (message.discountDefinitionKey !== undefined) {
      writer.uint32(18).string(message.discountDefinitionKey);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveDiscountDefinitionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveDiscountDefinitionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.discountDefinitionRef = reader.string();
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.discountDefinitionKey = reader.string();
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveDiscountDefinitionRequest>, I>>(base?: I): RemoveDiscountDefinitionRequest {
    return RemoveDiscountDefinitionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RemoveDiscountDefinitionRequest>, I>>(
    object: I,
  ): RemoveDiscountDefinitionRequest {
    const message = createBaseRemoveDiscountDefinitionRequest();
    message.discountDefinitionRef = object.discountDefinitionRef ?? undefined;
    message.discountDefinitionKey = object.discountDefinitionKey ?? undefined;
    return message;
  },
};

function createBaseRemoveDiscountDefinitionResponse(): RemoveDiscountDefinitionResponse {
  return { status: 0, error: undefined };
}

export const RemoveDiscountDefinitionResponse = {
  encode(message: RemoveDiscountDefinitionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): RemoveDiscountDefinitionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseRemoveDiscountDefinitionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<RemoveDiscountDefinitionResponse>, I>>(
    base?: I,
  ): RemoveDiscountDefinitionResponse {
    return RemoveDiscountDefinitionResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<RemoveDiscountDefinitionResponse>, I>>(
    object: I,
  ): RemoveDiscountDefinitionResponse {
    const message = createBaseRemoveDiscountDefinitionResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    return message;
  },
};

function createBaseSearchDiscountDefinitionRequest(): SearchDiscountDefinitionRequest {
  return { receiptDescription: "", status: 0, type: 0, validFrom: undefined, validTo: undefined, currency: 0 };
}

export const SearchDiscountDefinitionRequest = {
  encode(message: SearchDiscountDefinitionRequest, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.receiptDescription !== "") {
      writer.uint32(10).string(message.receiptDescription);
    }
    if (message.status !== 0) {
      writer.uint32(16).int32(message.status);
    }
    if (message.type !== 0) {
      writer.uint32(24).int32(message.type);
    }
    if (message.validFrom !== undefined) {
      Timestamp.encode(toTimestamp(message.validFrom), writer.uint32(34).fork()).ldelim();
    }
    if (message.validTo !== undefined) {
      Timestamp.encode(toTimestamp(message.validTo), writer.uint32(42).fork()).ldelim();
    }
    if (message.currency !== 0) {
      writer.uint32(56).int32(message.currency);
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchDiscountDefinitionRequest {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchDiscountDefinitionRequest();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 10) {
            break;
          }

          message.receiptDescription = reader.string();
          continue;
        case 2:
          if (tag !== 16) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 3:
          if (tag !== 24) {
            break;
          }

          message.type = reader.int32() as any;
          continue;
        case 4:
          if (tag !== 34) {
            break;
          }

          message.validFrom = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 5:
          if (tag !== 42) {
            break;
          }

          message.validTo = fromTimestamp(Timestamp.decode(reader, reader.uint32()));
          continue;
        case 7:
          if (tag !== 56) {
            break;
          }

          message.currency = reader.int32() as any;
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchDiscountDefinitionRequest>, I>>(base?: I): SearchDiscountDefinitionRequest {
    return SearchDiscountDefinitionRequest.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchDiscountDefinitionRequest>, I>>(
    object: I,
  ): SearchDiscountDefinitionRequest {
    const message = createBaseSearchDiscountDefinitionRequest();
    message.receiptDescription = object.receiptDescription ?? "";
    message.status = object.status ?? 0;
    message.type = object.type ?? 0;
    message.validFrom = object.validFrom ?? undefined;
    message.validTo = object.validTo ?? undefined;
    message.currency = object.currency ?? 0;
    return message;
  },
};

function createBaseSearchDiscountDefinitionResponse(): SearchDiscountDefinitionResponse {
  return { status: 0, error: undefined, discountDefinitions: [] };
}

export const SearchDiscountDefinitionResponse = {
  encode(message: SearchDiscountDefinitionResponse, writer: _m0.Writer = _m0.Writer.create()): _m0.Writer {
    if (message.status !== 0) {
      writer.uint32(8).int32(message.status);
    }
    if (message.error !== undefined) {
      Error.encode(message.error, writer.uint32(18).fork()).ldelim();
    }
    for (const v of message.discountDefinitions) {
      DiscountDefinition.encode(v!, writer.uint32(26).fork()).ldelim();
    }
    return writer;
  },

  decode(input: _m0.Reader | Uint8Array, length?: number): SearchDiscountDefinitionResponse {
    const reader = input instanceof _m0.Reader ? input : _m0.Reader.create(input);
    let end = length === undefined ? reader.len : reader.pos + length;
    const message = createBaseSearchDiscountDefinitionResponse();
    while (reader.pos < end) {
      const tag = reader.uint32();
      switch (tag >>> 3) {
        case 1:
          if (tag !== 8) {
            break;
          }

          message.status = reader.int32() as any;
          continue;
        case 2:
          if (tag !== 18) {
            break;
          }

          message.error = Error.decode(reader, reader.uint32());
          continue;
        case 3:
          if (tag !== 26) {
            break;
          }

          message.discountDefinitions.push(DiscountDefinition.decode(reader, reader.uint32()));
          continue;
      }
      if ((tag & 7) === 4 || tag === 0) {
        break;
      }
      reader.skipType(tag & 7);
    }
    return message;
  },

  create<I extends Exact<DeepPartial<SearchDiscountDefinitionResponse>, I>>(
    base?: I,
  ): SearchDiscountDefinitionResponse {
    return SearchDiscountDefinitionResponse.fromPartial(base ?? {});
  },

  fromPartial<I extends Exact<DeepPartial<SearchDiscountDefinitionResponse>, I>>(
    object: I,
  ): SearchDiscountDefinitionResponse {
    const message = createBaseSearchDiscountDefinitionResponse();
    message.status = object.status ?? 0;
    message.error = (object.error !== undefined && object.error !== null) ? Error.fromPartial(object.error) : undefined;
    message.discountDefinitions = object.discountDefinitions?.map((e) => DiscountDefinition.fromPartial(e)) || [];
    return message;
  },
};

declare var self: any | undefined;
declare var window: any | undefined;
declare var global: any | undefined;
var tsProtoGlobalThis: any = (() => {
  if (typeof globalThis !== "undefined") {
    return globalThis;
  }
  if (typeof self !== "undefined") {
    return self;
  }
  if (typeof window !== "undefined") {
    return window;
  }
  if (typeof global !== "undefined") {
    return global;
  }
  throw "Unable to locate global object";
})();

type Builtin = Date | Function | Uint8Array | string | number | boolean | undefined;

export type DeepPartial<T> = T extends Builtin ? T
  : T extends Array<infer U> ? Array<DeepPartial<U>> : T extends ReadonlyArray<infer U> ? ReadonlyArray<DeepPartial<U>>
  : T extends {} ? { [K in keyof T]?: DeepPartial<T[K]> }
  : Partial<T>;

type KeysOfUnion<T> = T extends T ? keyof T : never;
export type Exact<P, I extends P> = P extends Builtin ? P
  : P & { [K in keyof P]: Exact<P[K], I[K]> } & { [K in Exclude<keyof I, KeysOfUnion<P>>]: never };

function toTimestamp(date: Date): Timestamp {
  const seconds = date.getTime() / 1_000;
  const nanos = (date.getTime() % 1_000) * 1_000_000;
  return { seconds, nanos };
}

function fromTimestamp(t: Timestamp): Date {
  let millis = t.seconds * 1_000;
  millis += t.nanos / 1_000_000;
  return new Date(millis);
}

function longToNumber(long: Long): number {
  if (long.gt(Number.MAX_SAFE_INTEGER)) {
    throw new tsProtoGlobalThis.Error("Value is larger than Number.MAX_SAFE_INTEGER");
  }
  return long.toNumber();
}

if (_m0.util.Long !== Long) {
  _m0.util.Long = Long as any;
  _m0.configure();
}
