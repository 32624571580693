import { Button } from '../Button/Button';
import styles from './ButtonGroup.module.scss';
import cx from 'classnames';
import * as React from 'react';

interface Props<T> {
  className?: string;
  options: T[];
  selectedOption: T;
  onSelectionChange: (newOption: T) => void;
}

export const ButtonGroup = <T extends string>({
  className,
  options,
  onSelectionChange,
  selectedOption,
}: Props<T>) => {
  return (
    <div className={cx(styles.container, className)}>
      {options.map((option) => (
        <Button
          className={cx(styles.optionButton, {
            [styles.selectedButton]: option === selectedOption,
          })}
          key={option}
          onClick={() => onSelectionChange(option)}>
          {option}
        </Button>
      ))}
    </div>
  );
};
