import { AddIconButton } from '../../../inputs/buttons/AddIconButton/AddIconButton';
import { Card } from '../../../layout/Card/Card';
import { Link } from '../../../layout/navigation/Link/Link';
import { EmptyList } from '../../../layout/text/EmptyList/EmptyList';
import styles from '../NetworkSettings.module.css';
import ApiTokenConfigForm from './ApiTokenConfigForm/ApiTokenConfigForm';
import ClientAuthenticationTypeSelector from './ClientAuthenticationTypeSelector/ClientAuthenticationTypeSelector';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';
import { ApiTokenConfiguration } from 'mica-proto/src/micashared/common/v1/authentication_token';
import {
  ClientAuthenticationType,
  ExternalClientSettings,
} from 'mica-proto/src/micashared/common/v1/external_client';
import { ClientMTLSCertificate } from 'mica-proto/src/micashared/common/v1/mtls_certificate';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CERT_ROUTE = '/app/settings/certs/client/';
const NEW_CERT_ROUTE = CERT_ROUTE + 'new';

export type ClientAuthenticationCardProps = {
  apiTokenConfiguration?: ApiTokenConfiguration;
  clientMtlsCertificates?: ClientMTLSCertificate[];
  onSaveApiTokenConfig?: (config: ApiTokenConfiguration) => void;
  saveApiTokenConfigLoading?: boolean;
  settings?: ExternalClientSettings;
};

/**
 The 'Client Certicates' segment of our Network Config
 */
export const ClientAuthenticationCard = ({
  apiTokenConfiguration,
  clientMtlsCertificates,
  onSaveApiTokenConfig,
  saveApiTokenConfigLoading,
  settings,
}: ClientAuthenticationCardProps) => {
  const [
    selectedClientAuthenticationType,
    setSelectedClientAuthenticationType,
  ] = useState(
    settings?.clientAuthenticationSettings?.currentAuthenticationType
  );

  useEffect(() => {
    selectedClientAuthenticationType ===
      ClientAuthenticationType.CLIENT_AUTHENTICATION_TYPE_UNSPECIFIED &&
      setSelectedClientAuthenticationType(
        settings?.clientAuthenticationSettings?.currentAuthenticationType
      );
  }, [settings]);

  const navigate = useNavigate();

  const isCert =
    !selectedClientAuthenticationType ||
    [
      ClientAuthenticationType.CLIENT_AUTHENTICATION_TYPE_UNSPECIFIED,
      ClientAuthenticationType.CLIENT_AUTHENTICATION_TYPE_CLIENT_CERTIFICATE,
    ].includes(selectedClientAuthenticationType);

  return (
    <Card
      title={'Client Authentication'}
      titleAccessories={
        <AddIconButton
          className={styles.addButton}
          onClick={() => navigate(NEW_CERT_ROUTE)}
        />
      }>
      <ClientAuthenticationTypeSelector
        selectedAuthenticationType={selectedClientAuthenticationType}
        onAuthenticationTypeChanged={setSelectedClientAuthenticationType}
        suportedClientAuthenticationTypes={
          settings?.clientAuthenticationSettings
            ?.suportedClientAuthenticationTypes || []
        }
      />
      {isCert &&
        clientMtlsCertificates &&
        clientMtlsCertificates.length > 0 && (
          <table className={'table'}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Enabled</th>
              </tr>
            </thead>
            <tbody>
              {clientMtlsCertificates.map((cert) => (
                <tr
                  className={'pointer'}
                  key={cert.certificateRefKey}
                  onClick={() => navigate(CERT_ROUTE + cert.certificateRefKey)}>
                  <td>{cert.displayName}</td>
                  <td>{cert.enabled ? <FaCheck /> : <FaTimes />}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      {isCert &&
        clientMtlsCertificates &&
        clientMtlsCertificates.length === 0 && (
          <EmptyList className={'mt-10'}>
            No certificates configured.
            <Link
              className="ml-3"
              onClick={() => navigate(NEW_CERT_ROUTE)}
              label={'Add One?'}
            />
          </EmptyList>
        )}
      {!isCert && (
        <ApiTokenConfigForm
          config={apiTokenConfiguration}
          loading={saveApiTokenConfigLoading}
          onSaveChanges={onSaveApiTokenConfig}
        />
      )}
    </Card>
  );
};
