import { useMemo, useState } from 'react';

import { assocPath, mergeDeepWith } from 'ramda';

export const useCreateUpdateForm = <Entity extends Record<string, any>>(
  baseRecord: Entity
) => {
  const [updates, setUpdates] = useState<Partial<Entity>>({});

  const displayData = useMemo(
    () => mergeDeepWith((oldArray, newArray) => newArray, baseRecord, updates),
    [baseRecord, updates]
  );

  const updatePath = (path: string[]) => (value: any) =>
    setUpdates((u) => assocPath(path, value, u));

  const updateProperty = (property: keyof Entity) => (value: any) =>
    setUpdates((u) => ({
      ...u,
      [property]: value,
    }));

  const clearUpdates = () => setUpdates({});

  const hasUpdates = Object.keys(updates).length > 0;

  return {
    clearUpdates,
    displayData,
    hasUpdates,
    setUpdates,
    updates,
    updatePath,
    updateProperty,
  };
};
