import { join, match, pipe, startsWith, when } from 'ramda';

export const asE164Phone = (phone: string) =>
  pipe(
    match(/(\d)/g),
    join(''),
    when<string, string>(
      (phone) => !['+', '1'].includes(phone.charAt(0)),
      (phone) => '1' + phone
    ),
    when(startsWith('1'), (phone) => '+' + phone)
  )(phone);
