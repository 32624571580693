import styles from './InputOverride.module.css';
import cx from 'classnames';
import { useBoolean } from 'mica-shared-react/src/hooks/useBoolean';
import { LabelDetail } from 'mica-ui-kit/src/components';
import React, { CSSProperties, PropsWithChildren } from 'react';

type InputOverrideProps = {
  className?: string;
  isOveridden?: boolean;
  onSetToDefault?: () => void;
  label: string;
  style?: CSSProperties;
  value: string;
};

/**
 A Component that displays either an input, or the default value
 */
const InputOverride = ({
  children,
  className,
  isOveridden,
  label,
  onSetToDefault,
  style,
  value,
}: PropsWithChildren<InputOverrideProps>) => {
  const showInput = useBoolean(isOveridden);

  const toggle = () => {
    showInput.value && onSetToDefault?.();
    showInput.toggle();
  };

  return (
    <div className={cx(styles.container, className)} style={style}>
      {showInput.value ? (
        <>{children}</>
      ) : (
        <LabelDetail label={label} value={value} />
      )}
      <button className={styles.button} onClick={toggle}>
        {showInput.value ? 'USE DEF' : 'OVERRIDE'}
      </button>
    </div>
  );
};

export default InputOverride;
