import {
  StyleProps,
  StyledElement,
} from '../../layout/StyledElement/StyledElement';
import Checkbox from '../Checkbox/Checkbox';
import styles from './RadioList.module.css';
import React from 'react';

type Option = {
  label: string;
  value: any;
};

type RadioListProps = StyleProps & {
  label: string;
  onChange: (newValue: any) => void;
  options: Option[];
  value: any;
};

/**
 A list of radio buttons. Like checkboxes, but will only allow a single value to be selected
 */
const RadioList = ({
  label,
  onChange,
  options,
  value,
  ...style
}: RadioListProps) => {
  return (
    <StyledElement {...style}>
      <div className={styles.label}>{label}</div>
      <div className={styles.options}>
        {options.map((option) => (
          <Checkbox
            isRadio
            key={option.label}
            checked={value === option.value}
            className={styles.radio}
            label={option.label}
            onChange={() => onChange(option.value)}
          />
        ))}
      </div>
    </StyledElement>
  );
};

export default RadioList;
