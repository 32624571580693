import { useLazyQuery } from 'mica-shared-react/src/hooks/useLazyQuery/useLazyQuery';
import { useDiscountClient } from '../../useDiscountClient/useDiscountClient';

export const useSearchProductGroup = () => {
  const client = useDiscountClient();

  const { fetch, ...rest } = useLazyQuery(
    'searchProductGroup',
    client.searchProductGroup
  );

  return {
    searchProductGroup: fetch,
    productGroups: rest.data?.productGroups ?? [],
    ...rest,
  };
};
