import { MaterialUiStyleLabel } from '../../layout/MaterialUiStyleLabel/MaterialUiStyleLabel';
import InputAccessoryText, {
  InputAccessoryTextProps,
} from '../InputAccessoryText/InputAccessoryText';
import styles from './DatePicker.module.css';
import cx from 'classnames';
import React, { CSSProperties, forwardRef, useState } from 'react';
import RawDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaTimes } from '@react-icons/all-files/fa/FaTimes';

export type DatePickerProps = Omit<ReactDatePickerProps, 'value'> &
  InputAccessoryTextProps & {
    className?: string;
    displayTime?: boolean;
    inputClassName?: string;
    label?: string;
    labelClassName?: string;
    style?: CSSProperties;
    value?: Date;
  };

/**
 Our standard material-design looking Date Picker component
 */
export const DatePicker = ({
  displayTime,
  dateFormat = displayTime ? 'MMMM d yyyy @h:mm aa' : 'MMMM d yyyy',
  isClearable,
  value,
  ...props
}: DatePickerProps) => (
  <RawDatePicker
    {...props}
    dateFormat={dateFormat}
    showTimeSelect={displayTime}
    selected={value}
    customInput={
      <DatePickerCustomInput
        // @ts-ignore:
        onClear={() => props.onChange('')}
        isClearable={isClearable}
        {...props}
      />
    }
  />
);

const DatePickerCustomInput = forwardRef(
  (
    {
      className,
      inputClassName,
      isClearable,
      label,
      labelClassName,
      onChange,
      onClear,
      onClick,
      value,
      error,
      info,
      required,
      style,
      ...rest
    }: any,
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleBlur = (e: any) => {
      setIsFocused(false);
      rest.onBlur?.(e);
    };

    const handleChange = (e: any) => onChange(e.target.value);

    const handleFocus = (e: any) => {
      setIsFocused(true);
      onClick();
      rest.onFocus?.(e);
    };

    const handleClear = () => onClear();

    return (
      <div className={cx(styles.container, className)} style={style}>
        <input
          className={cx(
            styles.input,
            { [styles.isFocused]: isFocused },
            inputClassName
          )}
          {...rest}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          ref={ref}
          value={value}
        />
        {label && (
          <MaterialUiStyleLabel
            isFocused={isFocused}
            isReduced={isFocused || !!value}
            className={labelClassName}
            value={label}
          />
        )}
        {isClearable && value && (
          <FaTimes onClick={handleClear} className={styles.clearIcon} />
        )}
        <InputAccessoryText
          error={error}
          required={!value && required}
          info={info}
        />
      </div>
    );
  }
);
