import DollarsYtd from './DollarsYtd/DollarsYtd';
import styles from './Home.module.css';
import OpenDiscounts from './OpenDiscounts/OpenDiscounts';
import TopPerformingCount from './TopPerformingCount/TopPerformingCount';
import TopPerformingDollars from './TopPerformingDollar/TopPerformingDollar';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import React from 'react';

/**
 Our HomeScreen (main index route)
 */
const Home = () => {
  return (
    <Page title={'Dashboard'}>
      <div className={styles.chartContainer}>
        <DollarsYtd />
        <OpenDiscounts />
        <TopPerformingDollars />
        <TopPerformingCount />
      </div>
    </Page>
  );
};

export default Home;
