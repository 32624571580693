import styles from './NavigationHeader.module.css';
import { FaUser } from '@react-icons/all-files/fa/FaUser';
import { decodeJwt } from 'mica-shared-react/src/auth/decodeJwt/decodeJwt';
import { useBooleanWithClickListener } from 'mica-shared-react/src/hooks/useBooleanWithClickListener';
import React, { PropsWithChildren } from 'react';

type NavigationHeaderProps = {
  onLogout?: () => void;
  username?: string;
  onProfileLinkClicked?: () => void;
};

const OKTA_STORAGE_KEY = 'okta-token-storage';

const defaultOnLogout = () => {
  localStorage.removeItem(OKTA_STORAGE_KEY);
  window.location.reload();
};

const defaultUsername = () => {
  const jwt = localStorage.getItem(OKTA_STORAGE_KEY);

  if (!jwt) {
    return '';
  }

  return jwt
    ? decodeJwt(JSON.parse(jwt).accessToken?.accessToken).sub ?? ''
    : '';
};
/**
 The header component for our Navigation Container
 */
export const NavigationHeader = ({
  children,
  onLogout = defaultOnLogout,
  username = defaultUsername(),
  onProfileLinkClicked,
}: PropsWithChildren<NavigationHeaderProps>) => {
  const showUserMenu = useBooleanWithClickListener();

  return (
    <div className={styles.container}>
      <div className={styles.content}>{children}</div>
      <button className={styles.avatarButton} onClick={showUserMenu.setTrue}>
        <span className={styles.userName}>{username}</span>
        <FaUser className={styles.avatar} />
      </button>
      {showUserMenu.value && (
        <div className={styles.userMenu}>
          {onProfileLinkClicked && (
            <div className={styles.userMenuItem} onClick={onProfileLinkClicked}>
              Profile
            </div>
          )}
          <div className={styles.userMenuItem} onClick={onLogout}>
            Logout
          </div>
        </div>
      )}
    </div>
  );
};
