import styles from './SearchDiscounts.module.css';
import { useSearchDiscount } from 'discount-client-react/src/client/discount/useSearchDiscount/useSearchDiscount';
import { TextInput } from 'mica-ui-kit/src/components';
import { AddIconButton } from 'mica-ui-kit/src/components/consoles/inputs/buttons/AddIconButton/AddIconButton';
import { Card } from 'mica-ui-kit/src/components/consoles/layout/Card/Card';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'mica-ui-kit/src/components/consoles/inputs/buttons/Button/Button';

/**
 Our screen for searching for DiscountProviderAccounts
 */
const SearchDiscounts = () => {
  const [discountDefinitionRef, setDiscountDefinitionRef] = useState('');

  const { discounts, isLoading, searchDiscount } = useSearchDiscount();

  const navigate = useNavigate();

  const handleSearch = () =>
    searchDiscount({
      discountDefinitionRef,
    });

  return (
    <Page title={'Discounts'}>
      <Card
        title={'Search'}
        titleAccessories={
          <AddIconButton
            style={{ position: 'absolute', right: 20 }}
            onClick={() => navigate('create')}
          />
        }>
        <div className={styles.searchInputs}>
          <TextInput
            onChange={setDiscountDefinitionRef}
            value={discountDefinitionRef}
            label={'discountDefinitionRef'}
            style={{ width: 280 }}
          />
          <Button
            className={'mt-20'}
            label={'Search'}
            loading={isLoading}
            onClick={handleSearch}
          />
        </div>

        <div className={styles.results}>
          <table className={'table'} style={{ opacity: isLoading ? 0.6 : 1 }}>
            <thead>
              <tr>
                <th>User Ref</th>
                <th>Type</th>
                <th>Receipt Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {discounts.map((discount) => (
                <tr
                  className={'pointer'}
                  key={discount.discountKey}
                  onClick={() =>
                    navigate(`/app/discounts/${discount.discountRef}`)
                  }>
                  <td>{discount.userRef}</td>
                  <td>{discount.type}</td>
                  <td>{discount.receiptDescription}</td>
                  <td>{discount.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </Page>
  );
};

export default SearchDiscounts;
