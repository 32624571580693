import { ButtonGroup } from '../../inputs/buttons/ButtonGroup/ButtonGroup';
import { Page } from '../../layout/Page/Page';
import { CallbackAddressCard } from './CallbackAddressCard/CallbackAddressCard';
import {
  ClientAuthenticationCard,
  ClientAuthenticationCardProps,
} from './ClientAuthenticationCard/ClientAuthenticationCard';
import {
  MtlsCertificatesCard,
  MtlsCertificatesCardProps,
} from './MtlsCertificatesCard/MtlsCertificatesCard';
import { ExternalClientSettings } from 'mica-proto/src/micashared/common/v1/external_client';
import React, { useState } from 'react';

type NetworkSettingsProps = MtlsCertificatesCardProps &
  ClientAuthenticationCardProps;

type NetworkSettingsWithCallbackAddressProps = NetworkSettingsProps & {
  onUpdateCallbackAddress: (callbackAddress: string) => void;
  settings: ExternalClientSettings;
};

const isWithCallbackProps = (
  props: NetworkSettingsProps | NetworkSettingsWithCallbackAddressProps
): props is NetworkSettingsWithCallbackAddressProps =>
  !!(props as NetworkSettingsWithCallbackAddressProps).onUpdateCallbackAddress;

/**
 Our Network Configuration screen - where admin users can change the callbackAddress and certs
 NOTE: This component is opinionated about where it navigates to to view/edit certs,
 assuming these routes are consistent across our apps
 */
export const NetworkSettings = (
  props: NetworkSettingsProps | NetworkSettingsWithCallbackAddressProps
) => {
  const [viewMode, setViewMode] = useState('FROM MICA');

  return (
    <Page
      title={'Network Configuration'}
      titleAccessory={
        <ButtonGroup
          options={['FROM MICA', 'INTO MICA']}
          selectedOption={viewMode}
          style={{ marginLeft: 'auto' }}
          onSelectionChange={setViewMode}
        />
      }>
      {viewMode === 'FROM MICA' && isWithCallbackProps(props) ? (
        <>
          <CallbackAddressCard
            {...props}
            callbackAddress={props.settings?.callbackAddress || ''}
          />
          <ClientAuthenticationCard {...props} />
        </>
      ) : (
        <MtlsCertificatesCard {...props} />
      )}
    </Page>
  );
};
