import cx from 'classnames';
import React, {
  CSSProperties,
  ForwardedRef,
  HTMLProps,
  PropsWithChildren,
} from 'react';

export type StyleProps = {
  className?: string;
  style?: CSSProperties;
};

type StyledElementProps = StyleProps &
  HTMLProps<HTMLDivElement> & {
    constantClassName?: string;
    Tag?: 'div' | 'span';
  };

/**
 A div intended to wrap any of our components that want to allow styling by the parent
 - exports StyleProps to extend and takes care of the style overrides
 */
export const StyledElement = React.forwardRef(
  (
    {
      children,
      className,
      constantClassName,
      style,
      Tag = 'div',
      ...rest
    }: PropsWithChildren<StyledElementProps>,
    ref: ForwardedRef<HTMLDivElement>
  ) => (
    <Tag
      className={cx(constantClassName, className)}
      style={style}
      {...rest}
      ref={ref}>
      {children}
    </Tag>
  )
);
