import micaLogoAbbrev from '../../../../../assets/images/micaAbbreviatedLogo.png';
import micaLogo from '../../../../../assets/images/micaLogoBlue.png';
import styles from './NavigationMenu.module.css';
import {
  MenuItem,
  NavigationMenuItem,
} from './NavigationMenuItem/NavigationMenuItem';
import { FaBars } from '@react-icons/all-files/fa/FaBars';
import { FaCaretLeft } from '@react-icons/all-files/fa/FaCaretLeft';
import cx from 'classnames';
import { useBoolean } from 'mica-shared-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

type NavigationMenuProps = {
  items: MenuItem[];
};

/**
 Our Navigation Menu:
 - desktop: icons only or expanded with labels. Left side
 - mobile (<600px wide): swipe in from left with burger menu button at top left
 */
export const NavigationMenu = ({ items }: NavigationMenuProps) => {
  const isExpanded = useBoolean(window.innerWidth < 600);
  const { pathname } = useLocation();

  useEffect(() => {
    window.innerWidth < 600 && isExpanded.setFalse();
  }, [pathname]);

  return (
    <>
      {isExpanded.value && (
        <div className={styles.backdrop} onClick={isExpanded.setFalse} />
      )}
      <div
        className={cx(styles.container, {
          [styles.isExpanded]: isExpanded.value,
        })}>
        <div
          className={cx(styles.header, {
            [styles.isExpanded]: isExpanded.value,
          })}>
          <img
            src={micaLogo}
            className={cx(styles.logo, {
              [styles.offScreen]: !isExpanded.value,
            })}
          />
          <img
            src={micaLogoAbbrev}
            className={cx(styles.logo, {
              [styles.offScreen]: isExpanded.value,
            })}
          />
        </div>
        <div className={styles.content}>
          {items.map((item) => (
            <NavigationMenuItem
              key={item.title}
              item={item}
              isFullWidth={isExpanded.value}
            />
          ))}
          <button className={styles.expandButton} onClick={isExpanded.toggle}>
            <FaCaretLeft
              className={cx(styles.expandCaret, {
                [styles.isExpanded]: isExpanded.value,
              })}
            />
          </button>
        </div>
        <ReactTooltip />
      </div>
      <button className={styles.mobileMenuButton} onClick={isExpanded.toggle}>
        <FaBars size={20} />
      </button>
    </>
  );
};
