import { useOktaTenant } from '../../../../hooks/auth/okta/useOktaTenant';
import { Button } from '../../../inputs';
import { LoadingOverlay } from '../../../loading';
import OktaTenantRequired from '../OktaTenantRequired/OktaTenantRequired';
import styles from './OktaWrapper.module.css';
import { identityUrl } from './utils';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { RestoreOriginalUriFunction } from '@okta/okta-react/bundles/types/OktaContext';
import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const fetchCreds = (goAppLocalPort: number, tenant?: string) => async () => {
  if (import.meta.env.VITE_IDENTITY_CREDS) {
    return JSON.parse(import.meta.env.VITE_IDENTITY_CREDS);
  }

  const data = await fetch(
    window.location.protocol +
      '//' +
      identityUrl(window.location.host, goAppLocalPort, tenant)
  );

  return await data.json();
};

type OktaWrapperProps = {
  goAppLocalPort: number;
  requiresTenant?: boolean;
};

/**
 A wrapper to handle our fetching the creds required for this okta instance, before presenting login
 screen/logged-in ui
 */
export const OktaWrapper = ({
  children,
  goAppLocalPort,
  requiresTenant,
}: PropsWithChildren<OktaWrapperProps>) => {
  const [showTenantRequired, setShowTenantRequired] = useState(false);

  const [tenant] = useOktaTenant();

  const navigate = useNavigate();

  const restoreOriginalUri: RestoreOriginalUriFunction = useCallback(
    async (_oktaAuth, originalUri) => {
      navigate(toRelativeUrl(originalUri || '/', window.location.origin));
    },
    []
  );

  const { data, isError, isLoading } = useQuery(
    ['identity', tenant || 'untenanted'],
    fetchCreds(goAppLocalPort, requiresTenant ? tenant : undefined),
    {
      enabled: !requiresTenant || !!tenant,
      refetchOnWindowFocus: false,
      retry: true,
    }
  );

  const oktaAuth = useMemo(() => {
    if (!data) {
      return null;
    }
    const oktaCred = data.identity_providers[0];

    return oktaCred
      ? new OktaAuth({
          issuer: oktaCred.base_url,
          clientId: oktaCred.client_id,
          redirectUri: window.origin + '/app/auth/login/callback',
        })
      : null;
  }, [data]);

  if (showTenantRequired || (requiresTenant && !tenant)) {
    return (
      <OktaTenantRequired
        onTenantProvided={() => setShowTenantRequired(false)}
      />
    );
  }

  if (isError || (data && !oktaAuth)) {
    return (
      <div className={styles.container}>
        <div className={styles.text}>
          Error retrieving Okta credentials.
          <br />
          Please confirm your tenant is correct or reload the page
        </div>
        <Button
          className={styles.button}
          onClick={() => setShowTenantRequired(true)}
          title={'Change Tenant'}
        />
      </div>
    );
  }

  if (isLoading || !oktaAuth) {
    return <LoadingOverlay />;
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};
