import SearchProductGroup from '../../../products/SearchProductGroup/SearchProductGroup';
import styles from './DiscountCriteriaForm.module.css';
import { FaCaretLeft } from '@react-icons/all-files/fa/FaCaretLeft';
import { FaSearch } from '@react-icons/all-files/fa/FaSearch';
import { Unit } from 'mica-proto/src/micashared/common/enums/unit/v1/unit';
import { DiscountCriteria } from 'mica-proto/src/mica/discount/discount/v1/discount';
import { ProductGroup } from 'mica-proto/src/mica/discount/productgroup/v1/product_group';
import { useCreateUpdateForm } from 'mica-shared-react/src/hooks/useCreateUpdateForm';
import { Carousel, Switch } from 'mica-ui-kit/src/components';
import { CreateUpdateFormContainer } from 'mica-ui-kit/src/components/layout/forms/CreateUpdateFormContainer/CreateUpdateFormContainer';
import MonetaryAmountForm from 'mica-ui-kit/src/components/layout/forms/MonetaryAmountForm/MonetaryAmountForm';
import QuantityForm from 'mica-ui-kit/src/components/layout/forms/QuantityForm/QuantityForm';
import { TextListInputAndTable } from 'mica-ui-kit/src/components/layout/forms/TextListInputAndTable/TextListInputAndTable';
import { append, without } from 'ramda';
import React, { useState } from 'react';

const defaultCriteria = {
  quantity: {
    unit: Unit.UNIT_ITEM,
    quantity: '1',
  },
  discountAmountApplicable: false,
  monetaryAmount: {
    amount: '',
    taxAmount: '',
  },
  productGroupRefs: [],
  productMatchFilters: [],
  productCodes: [],
};

type DiscountCriteriaFormProps = {
  criteria?: Partial<DiscountCriteria>;
  onSave: (criteria: DiscountCriteria) => void;
};

/**
 A Component
 */
const DiscountCriteriaForm = ({
  criteria,
  onSave,
}: DiscountCriteriaFormProps) => {
  const [carouselIndex, setCarouselIndex] = useState(0);

  const { displayData, updateProperty } = useCreateUpdateForm(
    criteria || defaultCriteria
  );

  const handleSave = () => {
    onSave(displayData as DiscountCriteria);
  };

  const handleToggleProductGroup = (pg: ProductGroup) =>
    updateProperty('productGroupRefs')(
      (displayData.productGroupRefs || []).includes(pg.productGroupRef)
        ? without([pg.productGroupRef], displayData.productGroupRefs!)
        : append(pg.productGroupRef, displayData.productGroupRefs || [])
    );

  return (
    <div style={{ width: 350 }}>
      <Carousel hideIndicatorDots selectedIndex={carouselIndex}>
        <CreateUpdateFormContainer
          buttonDisabled={false}
          onButtonClicked={handleSave}>
          <div>
            <div className={styles.sectionTitle}>Quantity:</div>
            <QuantityForm
              quantity={displayData.quantity || {}}
              onChange={updateProperty('quantity')}
            />
            <div className={styles.spacer} />
            <div className={styles.sectionTitle}>Monetary Amount:</div>
            <MonetaryAmountForm
              amount={displayData.monetaryAmount || {}}
              onChange={updateProperty('monetaryAmount')}
            />
            <div className={styles.spacer} />
            <div className={styles.sectionTitle}>
              Discount Amount Applicable?:
            </div>
            <Switch
              on={!!displayData.discountAmountApplicable}
              onClick={() =>
                updateProperty('discountAmountApplicable')(
                  !displayData.discountAmountApplicable
                )
              }
            />
            <div className={styles.spacer} />
            <div
              className={'flex align-ctr justify-btw'}
              style={{ width: 300 }}>
              <div
                className={styles.sectionTitle}
                onClick={() => setCarouselIndex(1)}>
                Product Groups (ref):
              </div>
              <div>
                <FaSearch
                  className={styles.search}
                  onClick={() => setCarouselIndex(1)}
                />
              </div>
            </div>
            <TextListInputAndTable
              inputLabel={'Add Product Group Ref'}
              onChange={updateProperty('productGroupRefs')}
              splitCSV
              values={displayData.productGroupRefs || []}
            />
            <div className={styles.spacer} />
            <div className={styles.sectionTitle}>Product Filters:</div>
            <TextListInputAndTable
              inputLabel={'Add Filter'}
              onChange={updateProperty('productMatchFilters')}
              splitCSV
              values={displayData.productMatchFilters || []}
            />
            <div className={styles.spacer} />
            <div className={styles.sectionTitle}>Product Codes:</div>
            <TextListInputAndTable
              inputLabel={'Add Product Code'}
              onChange={updateProperty('productCodes')}
              splitCSV
              values={displayData.productCodes || []}
            />
          </div>
        </CreateUpdateFormContainer>
        <div style={{ width: 350, padding: 10 }}>
          <div
            className={'flex align-ctr pb-5 mb-10 pointer'}
            onClick={() => setCarouselIndex(0)}>
            <FaCaretLeft />
            Criteria
          </div>
          <div className={styles.sectionTitle}>Add Product Groups:</div>
          <SearchProductGroup
            onProductGroupSelected={handleToggleProductGroup}
            selectedRefs={displayData.productGroupRefs}
            multiSelect
          />
        </div>
      </Carousel>
    </div>
  );
};

export default DiscountCriteriaForm;
