import { useLazyQuery } from 'mica-shared-react/src/hooks/useLazyQuery/useLazyQuery';
import { useDiscountClient } from '../../useDiscountClient/useDiscountClient';

/**
 * A hook for searching for a discount
 */
export const useSearchDiscount = () => {
  const client = useDiscountClient();

  const { fetch, ...rest } = useLazyQuery(
    'searchDiscount',
    client.searchDiscount
  );

  return {
    searchDiscount: fetch,
    discounts: rest.data?.discounts ?? [],
    ...rest,
  };
};
