import { Error } from 'mica-proto/src/micashared/common/v1/error';
import { useEffect } from 'react';
import { toast } from 'react-toastify';

export const useToastError = (message?: { error?: string | Error }) => {
  useEffect(() => {
    if (message?.error) {
      const errorMessage =
        typeof message.error === 'string'
          ? message.error
          : message.error.message;
      toast.error(errorMessage);
    }
  }, [message?.error]);
};
