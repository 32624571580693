export const metadataForOktaAuth = () => {
  const auth = localStorage.getItem('okta-token-storage');

  return auth
    ? {
        Authorization: `Bearer ${
          JSON.parse(auth)?.accessToken?.accessToken || ''
        }`,
      }
    : {};
};
