import IconMessageBox, {
  IconMessageBoxProps,
} from '../IconMessageBox/IconMessageBox';
import { FaMinusCircle } from '@react-icons/all-files/fa/FaMinusCircle';
import React, { PropsWithChildren } from 'react';

/**
 A Component to indicate a table/list/search has no results or something expected is missing
 */
export const EmptyList = (
  props: PropsWithChildren<Omit<IconMessageBoxProps, 'icon'>>
) => <IconMessageBox icon={<FaMinusCircle size={12} />} {...props} />;
