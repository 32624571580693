import { LoadingButton } from '../../../inputs';
import styles from './CreateUpdateFormContainer.module.css';
import React, { PropsWithChildren } from 'react';

type CreateUpdateFormContainerProps = {
  buttonDisabled?: boolean;
  buttonTitle?: string;
  loading?: boolean;
  onButtonClicked: () => void;
};

/**
 A layout component to render our forms in a consistent manner (typically within a modal) -
 padding around the inputs in a column, and a 'save' button at the bottom
 */
export const CreateUpdateFormContainer = ({
  buttonDisabled,
  buttonTitle = 'SAVE',
  children,
  loading,
  onButtonClicked,
}: PropsWithChildren<CreateUpdateFormContainerProps>) => (
  <div className={styles.container}>
    {children}
    <div className={styles.buttonContainer}>
      <LoadingButton
        className={styles.button}
        disabled={buttonDisabled}
        loading={loading}
        onClick={onButtonClicked}>
        {buttonTitle}
      </LoadingButton>
    </div>
  </div>
);
