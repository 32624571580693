import { useSearchExternalClientMTLSCertificate } from 'discount-client-react/src/client/certs/useSearchExternalClientMTLSCertificate/useSearchExternalClientMTLSCertificate';
import { useUpdateExternalClientMTLSCertificate } from 'discount-client-react/src/client/certs/useUpdateExternalClientMTLSCertificate/useUpdateExternalClientMTLSCertificate';
import { LoadingOverlay } from 'mica-ui-kit/src/components';
import ClientMtlsCert from 'mica-ui-kit/src/components/consoles/screens/settings/network/certs/ClientMtlsCert/ClientMtlsCert';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

/**
 Our screen to display/update a client MTLS cert
 */
const ExternalClientMTLSCertificate = () => {
  const certificateRefKey = useParams().certificateRefKey!;

  const { data } = useSearchExternalClientMTLSCertificate();

  const { updateExternalClientMTLSCertificate, isLoading } =
    useUpdateExternalClientMTLSCertificate();

  const clientMtlsCertificate = useMemo(
    () =>
      data?.certificates?.find(
        (cert: any) => cert.certificateRefKey === certificateRefKey
      ),
    [certificateRefKey, data]
  );

  return clientMtlsCertificate ? (
    <ClientMtlsCert
      clientMtlsCertificate={clientMtlsCertificate}
      loading={isLoading}
      onUpdateCertificate={updateExternalClientMTLSCertificate}
    />
  ) : (
    <LoadingOverlay />
  );
};

export default ExternalClientMTLSCertificate;
