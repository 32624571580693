import { useGetUserKey } from 'discount-client-react/src/client/user/useGetUserKey/useGetUserKey';
import { useCreateUpdateForm } from 'mica-shared-react/src/hooks/useCreateUpdateForm';
import { TextInput } from 'mica-ui-kit/src/components/inputs/text/TextInput/TextInput';
import { Modal } from 'mica-ui-kit/src/components/layout/Modal/Modal';
import { CreateUpdateFormContainer } from 'mica-ui-kit/src/components/layout/forms/CreateUpdateFormContainer/CreateUpdateFormContainer';
import { asE164Phone } from 'mica-utilities/src/formatters/phone/phone';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';

const inputs = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

type UserKeyLookupModalProps = {
  active: boolean;
  onDismiss: () => void;
  onUserFound: (userKey: string) => void;
};

/**
 A modal to find a user's UserKey based on their phone number
 */
const UserKeyLookupModal = ({
  active,
  onDismiss,
  onUserFound,
}: UserKeyLookupModalProps) => {
  const { updateProperty, displayData, clearUpdates } =
    useCreateUpdateForm(inputs);

  const { data, isLoading, refetch, remove } = useGetUserKey(
    {
      userDemographic: {
        ...displayData,
        phone: asE164Phone(displayData.phone),
      },
    },
    {
      enabled: false,
    }
  );

  const handleDismiss = () => {
    clearUpdates();
    remove();
    onDismiss();
  };

  useEffect(() => {
    if (data) {
      if (data.userKey) {
        onUserFound(data.userKey);
        handleDismiss();
      } else {
        toast.info('No matches found');
      }
    }
  }, [data]);

  return (
    <Modal active={active} onDismiss={handleDismiss} title={'Find User Key'}>
      <CreateUpdateFormContainer
        onButtonClicked={refetch}
        loading={isLoading}
        buttonTitle={'LOOKUP'}>
        <i className={'py-10'}>
          Look up a User Key by providing the user's phone number
        </i>
        <div className={'flex col align-ctr'}>
          <TextInput
            autoFocus
            style={{ width: 250 }}
            onChange={updateProperty('phone')}
            onEnterPressed={refetch}
            value={displayData.phone}
            label={'Phone'}
          />
        </div>
      </CreateUpdateFormContainer>
    </Modal>
  );
};

export default UserKeyLookupModal;
