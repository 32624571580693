import dayjs from 'dayjs';
import { IntervalType } from 'mica-proto/src/mica/partner/transaction/v1/transaction';
import { useMemo } from 'react';

export enum TimeInterval {
  hour = 'hours',
  day = 'days',
  week = 'weeks',
  month = 'months',
}

export interface TimeFrame {
  last: [number, TimeInterval];
}

const IntervalMap = {
  ['hours']: IntervalType.INTERVAL_TYPE_HOUR,
  ['days']: IntervalType.INTERVAL_TYPE_DAY,
  ['weeks']: IntervalType.INTERVAL_TYPE_MONTH,
  ['months']: IntervalType.INTERVAL_TYPE_YEAR,
};

export const useTransactionSearchInputs = <
  AdditionalParams extends Record<string, any>
>(
  timeframe: TimeFrame,
  additionalParams: AdditionalParams
) => {
  return useMemo(() => {
    const [intervalCount, interval] = timeframe.last;

    const dateFrom = dayjs().subtract(intervalCount, interval).toDate();

    const intervalType = IntervalMap[interval];

    return {
      dateFrom,
      intervalType,
      ...additionalParams,
    };
  }, [timeframe, additionalParams]);
};
