import styles from './MaterialUiStyleLabel.module.css';
import cx from 'classnames';
import React from 'react';

type MaterialUiStyleLabelProps = {
  isFocused?: boolean;
  isReduced?: boolean;
  className?: string;
  value: string;
};

/**
 The reducing label used in our inputs and LabelDetail component
 */
export const MaterialUiStyleLabel = ({
  isFocused,
  isReduced,
  className,
  value,
}: MaterialUiStyleLabelProps) => (
  <span
    className={cx(styles.label, className, {
      [styles.isFocused]: isFocused,
      [styles.isReduced]: isReduced,
    })}>
    {value}
  </span>
);
