import { MaterialUiStyleLabel } from '../../../layout/MaterialUiStyleLabel/MaterialUiStyleLabel';
import InputAccessoryText, {
  InputAccessoryTextProps,
} from '../../InputAccessoryText/InputAccessoryText';
import styles from './TextInput.module.css';
import cx from 'classnames';
import React, { PropsWithChildren, createRef, useState } from 'react';

type TextInputProps = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'onChange'
> &
  InputAccessoryTextProps & {
    accessory?: JSX.Element;
    className?: string;
    inputClassName?: string;
    label?: string;
    labelClassName?: string;
    onChange: (val: string) => void;
    onEnterPressed?: () => void;
    value: string;
  };

/**
 Our standard material-design looking Text Input component
 */
export const TextInput = ({
  accessory,
  children,
  className,
  error,
  info,
  inputClassName,
  label,
  labelClassName,
  onChange,
  onEnterPressed,
  placeholder,
  required,
  ...rest
}: PropsWithChildren<TextInputProps>) => {
  const [isFocused, setIsFocused] = useState(false);

  const inputRef = createRef<HTMLInputElement>();

  const handleBlur = (e: any) => {
    setIsFocused(false);
    rest.onBlur?.(e);
  };

  const handleChange = (e: any) => onChange(e.target.value);

  const handleFocus = (e: any) => {
    setIsFocused(true);
    rest.onFocus?.(e);
  };

  const handleKeyPress = ({ key }: { key: string }) =>
    key === 'Enter' && onEnterPressed?.();

  return (
    <div className={cx(styles.container, className)}>
      <input
        className={cx(
          styles.input,
          { [styles.isFocused]: isFocused },
          inputClassName
        )}
        {...rest}
        aria-label={label}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyPress={handleKeyPress}
        placeholder={isFocused ? placeholder : ''}
        ref={inputRef}
      />
      <div className={styles.childContainer}>{children}</div>
      {label && (
        <MaterialUiStyleLabel
          isFocused={isFocused}
          isReduced={isFocused || !!rest.value}
          className={labelClassName}
          value={label}
        />
      )}
      <InputAccessoryText
        error={error}
        required={!rest.value && required}
        info={info}
      />
      {accessory && (
        <span className={styles.accessoryContainer}>{accessory}</span>
      )}
    </div>
  );
};
