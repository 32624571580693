import SearchDiscountProviderAccount from '../../../shared/accounts/SearchDiscountProviderAccount/SearchDiscountProviderAccount';
import { Page } from 'mica-ui-kit/src/components/consoles/layout/Page/Page';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 Our screen for searching for DiscountProviderAccounts
 */
const SearchAccounts = () => {
  const navigate = useNavigate();

  return (
    <Page title={'Accounts'}>
      <SearchDiscountProviderAccount
        onAccountSelected={(account) =>
          navigate(`/app/accounts/${account.discountProviderAccountRef}`)
        }
      />
    </Page>
  );
};

export default SearchAccounts;
